import * as React from 'react';
import {Button, Dimmer, Grid, GridColumn, Icon, Loader, Segment} from "semantic-ui-react";
import moment from "moment";
import ChartService from "../../../../services/chart-service";
import {Preference} from "../../../../models/models";
import {chartConfigValueToPcValue, getChartComponent} from "../../../../services/static-service";
import {info} from "../../../../services/route-service";
import {NavLink} from "react-router-dom";
import ReactGA from "react-ga4";
import Tableau from "../../../Common/Tableau";
import {getPreferenceReliability} from "../../../../services/preference-service";

interface PageProps {
    history: any
    chartService: ChartService
    chartPreferences: Preference[]
    darkTheme: boolean
    userCookie: any
}

interface PageState {
    chart1Loading: boolean
    chart2Loading: boolean
    chart3Loading: boolean
    chart4Loading: boolean
    chart5Loading: boolean
    lastModified: Date
}

export default class PersonalizedHome extends React.Component<PageProps, PageState> {
    constructor(props: PageProps, state: PageState) {
        super(props, state);

        this.state = {
            chart1Loading: true,
            chart2Loading: true,
            chart3Loading: true,
            chart4Loading: true,
            chart5Loading: true,
            lastModified: new Date(0),
        }
    }

    componentDidMount() {
        ReactGA.event({category: "user_page_view", action: "View Personalized User Homepage"});
        getPreferenceReliability('')
    }


    updateLoadingStatus = (loading: boolean, chartName: string) => {
        let convertedName = chartConfigValueToPcValue(chartName);
        let position: number = this.props.chartPreferences.findIndex(chart => chart.value === convertedName);
        switch (position) {
            case 0:
                this.setState({chart1Loading: loading})
                break;
            case 1:
                this.setState({chart2Loading: loading})
                break;
            case 2:
                this.setState({chart3Loading: loading})
                break;
            case 3:
                this.setState({chart4Loading: loading})
                break;
            case 4:
                this.setState({chart5Loading: loading})
                break;
        }
    }

    updateLastModified = (lastModified: Date) => {
        if (lastModified > this.state.lastModified)
            this.setState({lastModified: lastModified})
    }

    getChartLoadingState = (index: number) => {
        switch (index) {
            case 0:
                return this.state.chart1Loading
            case 1:
                return this.state.chart2Loading
            case 2:
                return this.state.chart3Loading
            case 3:
                return this.state.chart4Loading
            case 5:
                return this.state.chart5Loading
            default:
                 return this.state.chart1Loading
        }
    }

    getChartsFromPreferences = () => {
        let gridRows: any[] = [];
        let gridColumnRow1: any[] = [];
        let totalCharts = this.props.chartPreferences.length

        //generating columns for first row
        for (let i = 0; (i < totalCharts && i < 2); i++ )
            gridColumnRow1.push(this.getChartColumnsByPreference(this.props.chartPreferences[i], i))

        gridRows.push(
            <Grid.Row key={1} className={totalCharts > 2 ? 'db-chart-row' : '.db-chart-single-row'} columns={this.props.chartPreferences.length < 2? 1 : 2}>
                {gridColumnRow1}
            </Grid.Row>
        )

        //generating columns for second row IF needed
        if (this.props.chartPreferences.length > 2)
        {
            let gridColumnRow2: any[] = [];

            for (let i = 2; (i < totalCharts && i < 5); i++ )
                gridColumnRow2.push(this.getChartColumnsByPreference(this.props.chartPreferences[i], i))

            gridRows.push(
                <Grid.Row key={2} className={'db-chart-row'} columns={this.props.chartPreferences.length < 4 ? 1 : (this.props.chartPreferences.length < 5 ? 2: 3) }>
                    {gridColumnRow2}
                </Grid.Row>
            )
        }
        return gridRows;
    }

    getComponentByPageType = (preference: Preference, index: number) => {
        if (preference.page?.pageType.name === 'tableau')
            return (
                <Segment className={'chart-segment tableau'}>
                    <Dimmer active={this.getChartLoadingState(index)}
                            className={"tableau-dimmer"}
                            inverted={!this.props.darkTheme}>
                        <Loader/>
                    </Dimmer>
                    <Tableau
                        loading={this.getChartLoadingState(index)}
                        updateLoading={this.updateLoadingStatus}
                        userCookie={this.props.userCookie}
                        position={index+1}
                        total={this.props.chartPreferences.length}
                        viewName={preference.value}
                        tableauSrc={preference.tableauURL ? preference.tableauURL : ''}/>
                </Segment>
            )
       else
           return (
               <Segment className={'chart-segment'}>
                   <Dimmer active={this.getChartLoadingState(index)} inverted={!this.props.darkTheme}>
                       <Loader/>
                   </Dimmer>
                   {getChartComponent(
                       preference.value,
                       this.updateLastModified,
                       this.props.chartService,
                       this.getChartLoadingState(index),
                       this.props.darkTheme,
                       this.updateLoadingStatus,
                       false)}
               </Segment>
           )
    }

    getChartColumnsByPreference = (preference: Preference, index: number) =>  {
        return (
            <Grid.Column id={'chart-grid-col'} className={this.props.darkTheme? 'chart-column dark' : '.chart-column'} key={index}>
                <Segment.Group className={'chart-sg'} >
                    <Segment
                        content={this.props.chartPreferences[index].page?.title}
                        className={'chart-title-sg'}/>
                    {this.getComponentByPageType(preference, index)}
                    <NavLink to={'pages/'+this.props.chartPreferences[index].page?.slug}>
                        <Button
                            className={'narrow-btn graph-btn'}
                            attached='bottom'
                            color={'grey'}>
                            SEE MORE
                            <Icon className={'see-more-arrow'} name={'arrow right'}/>
                        </Button>
                    </NavLink>
                </Segment.Group>
            </Grid.Column>
        )
}

    render(){
        return(
            <Grid id={'im-graph-grid'}>
                <Grid.Row
                    id={'db-title-row'}
                    columns={2}>
                    <Grid.Column verticalAlign={'middle'}>
                       <span className={this.props.darkTheme? 'dark' : ''} id={'dashboard-title'}>
                           Welcome to your SPP Portal
                       </span>
                    </Grid.Column>
                    <GridColumn verticalAlign={'middle'} textAlign={'right'}>
                        <NavLink to={info.dashboardSettings.path}>
                            <Button color={'grey'}>
                                EDIT SETTINGS
                            </Button>
                        </NavLink>
                    </GridColumn>
                </Grid.Row>
                {this.getChartsFromPreferences()}
                <Grid.Row columns={2} style={{paddingTop: 0}} >
                    <Grid.Column />
                    <GridColumn
                        verticalAlign={'middle'}
                        textAlign={'right'}
                        id={'dark-last-modified-column'}>
                        LAST UPDATED {moment(this.state.lastModified).format('YYYY-MM-DD HH:mm:ss')} CT
                    </GridColumn>
                </Grid.Row>
            </Grid>
        )
    }
}
