import * as React from 'react';
import {MenuOption, Page, PageConfig, PortalProperties, Principal} from "../../../models/models";

import {emptyPage, emptyPageConfig, pageTypeKey} from "../../../util/constants";
import MenuRail from "../../Common/MenuRail";
import StaticPage from "./Static";
import {Dimmer, Grid, Loader} from "semantic-ui-react";
import PageConfigService from "../../../services/page-config-service";
import FileBrowserPage from "./FileBrowser";
import EmbeddedPage from "./Embedded";
import {getBackgroundImage} from "../../../util/functions";
import Favorite from "../../Common/Favorite";
import {ToastComponent} from "../../Common/ToastComponent";
import TableauPage from "./Tableau";

interface PageProps {
    match: any
    userCookie: any
    userPrincipal: Principal;
    history: any
    selectedTopMenuOption: string
    setSelectedTopMenuOption: (selectedTopMenuOption: string) => void;
    portalProperties: PortalProperties;
    railDisplay: string
    setRailDisplay: (railDisplay: string) => void
    setMenuOptions: (menuOptions: MenuOption[]) => void
    menuOptions: MenuOption[]
    darkTheme: boolean
}

interface PageState {
    pageConfig: PageConfig
    descriptionConfig: PageConfig
    embeddedTitleConfig: PageConfig
    loading: boolean
    tableauViewName: string
}

export default class PublicPages extends React.Component<PageProps, PageState> {

    private pageConfigClient: PageConfigService;
    constructor(props: PageProps, state: PageState) {
        super(props, state);

        this.pageConfigClient = new PageConfigService(this.props.userCookie)

        this.state = {
            pageConfig: emptyPageConfig,
            descriptionConfig: emptyPageConfig,
            embeddedTitleConfig: emptyPageConfig,
            loading: false,
            tableauViewName: ''
        }
    }

    componentDidMount() {
        this.getPageFromSlug()
    }

    componentDidUpdate(prevProps: Readonly<PageProps>, prevState: Readonly<PageState>, snapshot?: any) {
        if (this.props.match.params.pageSlug !== prevProps.match.params.pageSlug)
            this.getPageFromSlug()
    }

    getPageFromSlug = () => {
        this.setState({loading: true})
        this.pageConfigClient.getPageConfigByPage(this.props.match.params.pageSlug).then(response => {
            if (response.length < 1)
                this.props.history.push('/page-not-found')

            this.setState({loading: false})

            response.forEach(pc => {
                if (pc.page?.pageType.name === 'tableau' && pc.pcKey === pageTypeKey.TABLEAU_VIEW_NAME)
                    this.setState({tableauViewName: pc.pcValue})
                else
                    switch (pc.pcKey) {
                        case pageTypeKey.FB_DESCRIPTION:
                            this.setState({descriptionConfig: pc})
                            break;
                        case pageTypeKey.EMBEDDED_TITLE:
                            this.setState({embeddedTitleConfig: pc})
                            break;
                        case pageTypeKey.ALLOW_DASHBOARD:
                            break;
                        case pageTypeKey.TABLEAU_CATEGORY:
                            break;
                        default:
                            this.setState({pageConfig: pc})
                            break
                    }
            })
        })
    }

    getChildComponentByType = () => {
        let page: Page = this.state.pageConfig.page !== undefined? this.state.pageConfig.page : emptyPage
        switch (page.pageType.name) {
            case 'static':
                return <StaticPage userCookie={this.props.userCookie}
                                   darkTheme={this.props.darkTheme}
                                   portalProperties={this.props.portalProperties}
                                   pageConfig={this.state.pageConfig}
                                   userPrincipal={this.props.userPrincipal}/>
            case 'file-browser':
                return <FileBrowserPage pageConfig={this.state.pageConfig}
                                        darkTheme={this.props.darkTheme}
                                        portalProperties={this.props.portalProperties}
                                        userCookie={this.props.userCookie}
                                        descriptionConfig={this.state.descriptionConfig}/>
            case 'embedded':
                return <EmbeddedPage pageConfig={this.state.pageConfig}
                                     darkTheme={this.props.darkTheme}
                                     portalProperties={this.props.portalProperties}
                                     embeddedTitleConfig={this.state.embeddedTitleConfig}
                                     userCookie={this.props.userCookie}
                                     userPrincipal={this.props.userPrincipal}
                                     setMenuOptions={this.props.setMenuOptions}/>
            case 'tableau':
                return <TableauPage userCookie={this.props.userCookie}
                                    darkTheme={this.props.darkTheme}
                                    tableauViewName={this.state.tableauViewName}
                                    portalProperties={this.props.portalProperties}
                                    pageConfig={this.state.pageConfig}
                                    userPrincipal={this.props.userPrincipal}/>
            default:
                return <Grid style={{height: '100%'}}/>
        }
    }


    getMinHeight = () => {
        if (this.state.pageConfig.page?.pageType.name === 'static' )
        {
            if (this.state.pageConfig.pcValue === 'ace-chart')
                return 965
            else
                return 720
        }

        else return 'min-height: calc(100vh - (130px + 28px))'
    }

    getStyle = () => {
        let pageType = this.state.pageConfig.page? this.state.pageConfig.page.pageType.name : ''

        if (pageType === 'embedded')
            return {backgroundColor: this.props.darkTheme? '#4b5458' : '#f1f1f1', minHeight: this.getMinHeight()}
        else
            return {backgroundImage: this.getBackground(pageType), minHeight: this.getMinHeight()}
    }

    getBackground = (pageType: string) => {
        if (!this.props.userPrincipal.unauthenticatedUser)
            return getBackgroundImage(this.props.darkTheme)

        if (pageType === 'static')
        {
            if (this.state.pageConfig.page?.slug.includes('weis'))
                return getBackgroundImage(false)
            else
                return getBackgroundImage(true)
        }

        else return getBackgroundImage(false)
    }

    getClassname = () => {
        if (this.state.pageConfig.page?.pageType.name === 'static')
        {
            if (this.state.pageConfig.page?.slug.includes('weis'))
                return 'background-image-div light-image'
            else
                return 'background-image-div dark-image'
        }
        else
            return 'background-image-tou'
    }

    isEmbeddedNoTitle = () => {
        return this.state.embeddedTitleConfig.pcKey === pageTypeKey.EMBEDDED_TITLE && this.state.embeddedTitleConfig.pcValue === "false";
    }

    getFavorite = () =>{
        if (this.isEmbeddedNoTitle())
            return "";

        return <Favorite favorite={this.state.pageConfig.favorite ?? false}
                         pageType={this.state.pageConfig.pcKey}
                         userCookie={this.props.userCookie}
                         userPrincipal={this.props.userPrincipal}
                         pageId={this.state.pageConfig.page?.id ?? -1}
                         pageGroupChild={false}
                         darkTheme={this.props.darkTheme}
                         loading={this.state.loading}
                         setMenuOptions={this.props.setMenuOptions}
        />;
    }

    render(){
        return(
            <div className={this.getClassname()} style={this.getStyle()}>
                <Dimmer inverted={!this.props.darkTheme} active={this.state.loading}>
                    <Loader/>
                </Dimmer>
                <MenuRail selectedTopMenuItem={this.props.selectedTopMenuOption}
                          railDisplay={this.props.railDisplay}
                          portalProperties={this.props.portalProperties}
                          userPrincipal={this.props.userPrincipal}
                          setRailDisplay={this.props.setRailDisplay}
                          menuOptions={this.props.menuOptions}
                          setSelectedTopMenuOption={this.props.setSelectedTopMenuOption}
                          history={this.props.history}/>
                <ToastComponent/>
                {this.getFavorite()}
                {this.getChildComponentByType()}
            </div>
        )
    }
}
