import axios from 'axios';
import {Dispatch} from "redux";
import {
    resolvedGetAppInfo,
    resolvedGetDarkTheme, resolvedGetSessionAnnouncements,
    resolvedGetTopMenuOption,
    resolvedGetUserPrincipal,
    resolvedSetCookie,
    resolvedSetMenuOptions,
    resolvedSetPortalProperties,
    resolvedSetRailDisplay,
    resolvedTermsAccepted
} from "../store/actions";
import {AppInfo, MenuOption, PortalProperties, Principal, SessionAnnouncement} from "../models/models";
import {emptyAppInfo} from "../util/constants";


export const setUserCookie = (userCookie: string, dispatch: Dispatch) => {
    dispatch(resolvedSetCookie(userCookie));
};

export const setSelectedTopMenuOption = (selectedTopMenuOption: string, dispatch: Dispatch) => {
    dispatch(resolvedGetTopMenuOption(selectedTopMenuOption));
};

export const setRailDisplay = (railDisplay: string, dispatch: Dispatch) => {
    dispatch(resolvedSetRailDisplay(railDisplay));
};

export const setMenuOptions = (menuOptions: MenuOption[], dispatch: Dispatch) => {
    dispatch(resolvedSetMenuOptions(menuOptions));
};

export const getUserPrincipal = (xsrfToken: string, dispatch: Dispatch) => {
    const url = `/api/principal`;
    let AxiosRequestConfig = {headers: {
            'X-SPP-UI-Token': xsrfToken,
            'Accept': 'application/json',
            'Content-Type': 'application/json',}};
    return new Promise<Record<string, any>>((resolve, reject) => {
        axios.get(url, AxiosRequestConfig )
            .then(({data}: {data: any} ) => {
                if (data.hasOwnProperty("principal")) {
                    resolve(data["principal"]);
                    dispatch(resolvedGetUserPrincipal(data["principal"]));
                }

                if (data.hasOwnProperty("dark-theme")) {
                    resolve(data["dark-theme"]);
                    dispatch(resolvedGetDarkTheme(data["dark-theme"]));
                }

                if (data.hasOwnProperty("terms-accepted")) {
                    resolve(data["terms-accepted"]);
                    dispatch(resolvedTermsAccepted(data["terms-accepted"]));
                }

            })
            .catch(() => {
                let errorPrincipal: Principal = {
                    userLevel: '',
                    firstName: '',
                    lastName: '',
                    userId: '',
                    username: '',
                    email: '',
                    unauthenticatedUser: true,
                    displayName: ''};
                resolve(errorPrincipal);
                dispatch(resolvedGetDarkTheme(false));
                dispatch(resolvedTermsAccepted(true));
                dispatch(resolvedGetUserPrincipal(errorPrincipal))});
    })
};

export const getPortalProperties = (xsrfToken: string, dispatch: Dispatch) => {
    const url = `/api/app-info`;
    let AxiosRequestConfig = {headers: {
            'X-SPP-UI-Token': xsrfToken,
            'Accept': 'application/json',
            'Content-Type': 'application/json',}};
    return new Promise<PortalProperties>((resolve, reject) => {
        axios.get(url, AxiosRequestConfig )
            .then(({ status, data} ) => {
                if (status === 200) {
                    resolve(data);
                    dispatch(resolvedSetPortalProperties(data));
                } else {
                    reject(new Error('error fetching data.'));
                }
            })
            .catch(() => {
                let errorPortalProperties: PortalProperties = {
                    environmentClass: '',
                    environmentName: '',
                    environmentShortName: '',
                    hrefSppOrg: '',
                    hrefOasis: '',
                    hrefOps1: '',
                    hrefImpactStudies: '',
                    hrefRms: '',
                    hrefNameSppOrg: '',
                    hrefNameOasis: '',
                    hrefNameOps1: '',
                    hrefNameImpactStudies: '',
                    hrefNameRms: '',
                    uaaHost: '',
                    portalHost: '',
                    hrefCrow: '',
                    hrefNameCrow: '',
                    hrefOatiWebTrans: '',
                    hrefNameOatiWebTrans: '',
                    apiVersion: '',
                    googleAnalytics: '',
                    termsOfUsePolicyLink: "https://spp.org/Documents/22819/SPP_External_Systems_Acceptable_Use_Policy Final Approved.pdf",
                    maxMenuDepth: 0};
                resolve(errorPortalProperties);
                dispatch(resolvedSetPortalProperties(errorPortalProperties))});
    })
};

export const getAppInfo = (xsrfToken: string, dispatch: Dispatch) => {
    const url = `/uaa/application/info`;
    let AxiosRequestConfig = {headers: {
            'X-SPP-UI-Token': xsrfToken,
            'Accept': 'application/json',
            'Content-Type': 'application/json',}};
    return new Promise<AppInfo>((resolve, reject) => {
        axios.get(url, AxiosRequestConfig )
            .then(({ status, data} ) => {
                if (status === 200) {
                    resolve(data);
                    dispatch(resolvedGetAppInfo(data));
                } else {
                    reject(new Error('error fetching data.'));
                }
            })
            .catch(() => {
                resolve(emptyAppInfo);
                dispatch(resolvedGetAppInfo(emptyAppInfo))});
    })
}

export const getSessionAnnouncements = (xsrfToken: string, dispatch: Dispatch): Promise<SessionAnnouncement[]> => {
    const url = `/api/announcement/session`;
    let axiosRequestConfig = {headers: {
            'X-SPP-UI-Token': xsrfToken,
            'Accept': 'application/json',
            'Content-Type': 'application/json',}};
    return new Promise<SessionAnnouncement[]>(((resolve) => {
        axios.get(url, axiosRequestConfig)
            .then(({data}) => {
                resolve(data)
                dispatch(resolvedGetSessionAnnouncements(data));
            })
            .catch(() => {
                resolve([])
                dispatch(resolvedGetSessionAnnouncements([]));
            })
    }));
};