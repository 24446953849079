import * as React from 'react';
import './index.css'
import {Page, PageConfig, PortalProperties, Principal} from "../../../../models/models";
import {
    Button,
    Segment,
    Divider,
    Dimmer,
    Loader,
    Grid,
    GridColumn,
    GridRow,
    Icon,
} from "semantic-ui-react";
import moment from "moment/moment";
import parse from 'html-react-parser';
import {emptyPage} from "../../../../util/constants";
import {getPageBreadcrumb} from "../../../../util/functions";
import {ToastComponent} from "../../../Common/ToastComponent";
import ReactGA from "react-ga4";
import Tableau from "../../../Common/Tableau";


interface PageProps {
    pageConfig: PageConfig
    userCookie: any
    darkTheme: boolean
    portalProperties: PortalProperties;
    userPrincipal: Principal
    tableauViewName: string
}

interface PageState {
    chartLoading: boolean
    lastModified: Date
    page: Page
    summaryHeight: number
}

export default class TableauPage extends React.Component<PageProps, PageState> {
    ref: any
    constructor(props: PageProps, state: PageState) {
        super(props, state);

        this.ref = React.createRef()

        this.state = {
            chartLoading: true,
            lastModified: new Date(),
            page: emptyPage,
            summaryHeight: 40,
        }
    }

    componentDidMount() {
        if (this.props.pageConfig.page !== undefined) {
            ReactGA.event({
                category: "user_page_view",
                action: "View " + this.props.pageConfig.page.title + " Tableau Page"
            });
            this.setState({page: this.props.pageConfig.page})
        }
    }

    componentDidUpdate(prevProps: Readonly<PageProps>, prevState: Readonly<PageState>, snapshot?: any) {
        if (this.state.page.summary !== prevState.page.summary)
            this.setState({summaryHeight: this.ref.current.clientHeight+14})
    }

    updateLastModified = (lastModified: Date) => {
        if (lastModified > this.state.lastModified)
            this.setState({lastModified: lastModified})
    }

    getPageSummary = () => {
        if (this.state.page.summary === '' || this.state.page.summary === undefined)
            return false
        else
            return (
                <div ref={this.ref}>
                    {parse(this.state.page.summary)}
                    <Divider/>
                </div>)
    }

    handleRefreshButtonClick = () => {
        if (!this.state.chartLoading)
            this.setState({chartLoading: true})
    }

    updateLoading = (loading: boolean) => {
        this.setState({chartLoading: loading})
    }

    render(){
        return(
            <Grid>
                <ToastComponent/>
                <GridRow stretched={true}>
                    <Grid.Column id={'static-chart-column'} className={this.props.darkTheme? 'dark' : ''}>
                        <div id={'breadcrumb'}>
                            {getPageBreadcrumb(
                                this.state.page.breadcrumb? this.state.page.breadcrumb : [],
                                this.props.portalProperties.uaaHost)}
                        </div>
                        <Segment.Group>
                            <Segment
                                content={this.state.page.title +' for '+ moment(this.state.lastModified).format('YYYY-MM-DD HH:mm:ss')+ ' (Central Time)'}
                                className={'chart-title-sg'}/>
                            <Segment id={'tableau-body-sg'}>
                                <GridColumn>
                                    <GridRow>
                                        {this.getPageSummary()}
                                        <Dimmer
                                            active={this.state.chartLoading}
                                            className={"tableau-dimmer"}
                                            inverted={!this.props.darkTheme}>
                                            <Loader/>
                                        </Dimmer>
                                    </GridRow>
                                    <GridRow id={'tableau-row'}>
                                        <Tableau
                                            viewName={this.props.tableauViewName}
                                            tableauSrc={this.props.pageConfig.pcValue}
                                            loading={this.state.chartLoading}
                                            updateLastModified={this.updateLastModified}
                                            position={0}
                                            total={0}
                                            updateLoading={this.updateLoading}
                                            userCookie={this.props.userCookie}/>
                                    </GridRow>
                                </GridColumn>
                            </Segment>
                            <Button className={'narrow-btn graph-btn'}
                                    onClick={this.handleRefreshButtonClick}
                                    attached={'bottom'}
                                    color={'grey'}>
                                REFRESH
                                <Icon className={'see-more-arrow'} name={'redo'}/>
                            </Button>
                        </Segment.Group>
                    </Grid.Column>
                </GridRow>
            </Grid>
        )
    }
}
