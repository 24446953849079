import * as React from "react";
import {
    Button,
    ButtonProps,
    Card,
    CardProps,
    Divider, DropdownProps,
    Form,
    Grid,
    Header,
    Icon,
    Label,
    Segment
} from "semantic-ui-react";
import RLDD from "react-list-drag-and-drop/lib/RLDD";
import SppModal from "../../../../Common/SppModal";
import {info} from "../../../../../services/route-service";
import {DropDownOption, ModalContent, PageGroup} from "../../../../../models/models";
import PageGroupService from "../../../../../services/page-group-service";

import {cloneDeep} from "lodash";
import toast from "../../../../../util/toast";
import {emptyPageGroup} from "../../../../../util/constants";



interface PageProps {
    history: any
    parentPageGroupId: any
    pageGroupClient: PageGroupService
    pageGroups: PageGroup[]
    pageGroupOptions: DropDownOption[]
    compareChildren: (a: any, b: any) => number
}

interface PageState {
    pageGroupChildren: any[]
    selectedName: { name: string, slug: string }
    selected: boolean
    modalOpen: boolean
    modalContent: ModalContent
    buttonPageGroupLoading: boolean
    parentPageGroup: PageGroup

}

export default class PageGroupChildren extends React.Component<PageProps, PageState> {
    constructor(props: PageProps, state: PageState) {
        super(props, state);

        this.state = {
            pageGroupChildren: [],
            selectedName: {name: '', slug: ''},
            selected: false,
            modalOpen: false,
            modalContent: {iconName: '', modalTitle: '', onClose: ()=>{}, modalBody: ''},
            buttonPageGroupLoading: false,
            parentPageGroup: emptyPageGroup
        }
    }

    componentDidMount() {
        this.getPageGroupChildren()
    }

    getPageGroupChildren = () => {
        this.props.pageGroupClient.getPageGroupsByParentGroup(this.props.parentPageGroupId).then((response: PageGroup[]) => {
            response.sort(this.props.compareChildren)
            this.setState({pageGroupChildren: response})
        })
    }

    getClassname = (item: PageGroup) => {
        if(this.state.selectedName.name === item.name && this.state.selectedName.slug === item.slug && this.state.selected)
            return 'selected'
        else return ''

    }

    handleCardClick = (event: any, {name}: CardProps) => {
        if(this.state.selectedName.name === name.name && this.state.selectedName.slug === name.slug )
            this.setState({selectedName: {name: '', slug: ''}, selected: false})
        else
            this.setState({selectedName: {name: name.name, slug: name.slug}, selected: true})
    }

    handleButtonBarClick = (event: any, {name, content}: ButtonProps) => {
        switch (content)
        {
            case 'View':
                this.props.history.push(info.pageGroups.path+'/'+name.id)
                break;
            case 'Move':
                this.setState({modalOpen: true,
                    parentPageGroup: this.props.pageGroups.filter(pg => pg.id === parseInt(this.props.parentPageGroupId))[0]})
                this.getMoveModalContent(name)
                break;
            case 'Delete':
                this.setState({modalOpen: true})
                this.getDeleteModalContent(name)
                break;
        }
    };

    getDeleteModalContent = (item: any) => {
        const modalContent = (
            {
                iconName: 'file excel outline',
                modalTitle: 'Delete Page Group',
                onClose: this.closeModal,
                modalBody:  <Grid id={'conf-info-content'}>
                    <Grid.Row>
                        <Grid.Column>
                            Are you sure you want to delete page group titled: {item.name}?
                        </Grid.Column>
                    </Grid.Row>
                    <Grid.Row>
                        <Grid.Column textAlign={'right'}>
                            <Button className={'modal-action-button'}
                                    onClick={this.closeModal}
                                    color={'red'}
                                    id={'cm-negative-button'}
                                    content={'NO'}/>
                            <Button onClick={this.submitDeletePageGroup}
                                    color={'green'}
                                    name={item}
                                    id={'cm-positive-button'}
                                    className={'modal-action-button'}
                                    content={'YES'}/>
                        </Grid.Column>
                    </Grid.Row>
                </Grid>,
            }
        )
        this.setState({modalContent: modalContent})
    };

    submitDeletePageGroup=(event: any, {name}: ButtonProps)=>{
        this.props.pageGroupClient.deletePageGroup(name.id)
            .then(response => {
                if (response.ok)
                {
                    this.closeModal()
                    this.getPageGroupChildren()
                    toast.success(response.message)
                }

                else toast.error(response.message)
            })
    }

    handleMoveSelectOnChange = (event: any, data: DropdownProps) => {
        event.preventDefault()
        let parentPGArray = cloneDeep(this.props.pageGroups);
        let parentPG = parentPGArray.filter(pg => pg.id === data.value)[0]
        this.setState({parentPageGroup: parentPG})
    }

    getMoveModalContent = (item: any) => {
        const modalContent = (
            {
                iconName: 'exchange',
                modalTitle: 'Move Page Group',
                onClose: this.closeModal,
                modalBody:
                    <Form>
                        <Form.Select options={this.props.pageGroupOptions}
                                     onChange={this.handleMoveSelectOnChange}
                                     defaultValue={item.pageGroup.id}
                                     name={'parentPageGroup'}
                                     search={true}
                                     placeholder={'Select a Parent Group'}
                                     label={'Parent Page Group'}/>
                        <Grid>
                            <Grid.Row>
                                <Grid.Column textAlign={'right'}>
                                    <Button className={'form-close-button'}
                                            color={'red'}
                                            loading={false}
                                            type={'button'}
                                            onClick={this.closeModal}
                                            content={'CANCEL'}/>
                                    <Button color={'green'}
                                            loading={false}
                                            onClick={this.handleMoveFormSubmit}
                                            name={item}
                                            className={'form-action-button'}
                                            content={'MOVE PAGE GROUP'}/>
                                </Grid.Column>
                            </Grid.Row>
                        </Grid>
                    </Form>
            }
        )
        this.setState({modalContent: modalContent})
    };

    handleMoveFormSubmit = (event: any, {name}: ButtonProps) => {
        name.pageGroup = cloneDeep(this.state.parentPageGroup)
        this.props.pageGroupClient.updatePageGroup(name)
            .then(response => {
                if (response.ok)
                {
                    this.closeModal()
                    this.getPageGroupChildren()
                    toast.success(response.message)
                }

                else toast.error(response.message)
            })
    }

    closeModal = () => {
        this.setState({modalOpen: false,
            parentPageGroup: this.props.pageGroups.filter(pg => pg.id === parseInt(this.props.parentPageGroupId))[0]})
    };

    pageGroupChildRenderer = (item: any, index: number): JSX.Element => {
        let parity = () => {
            if(index % 2 === 0)
                return 'even'
            else
                return 'odd'
        };

        return (
            <Card id={'page-group-child-'+parity()}
                  className={this.getClassname(item)}
                  fluid={true}
                  name={item}
                  onClick={this.handleCardClick}>
                <Card.Content>
                    <Card.Header>{item.name}</Card.Header>
                    <Card.Meta>
                        {item.slug}
                        <Button.Group id={'pg-child-card'}  floated={'right'} className={this.getClassname(item)}>
                            <Button name={item} className={'child-button-group'} onClick={this.handleButtonBarClick} content={'View'}/>
                            <Button name={item} className={'child-button-group'} onClick={this.handleButtonBarClick} content={'Move'}/>
                            <Button name={item} className={'child-button-group'} onClick={this.handleButtonBarClick} content={'Delete'}/>
                        </Button.Group>
                    </Card.Meta>
                    <Card.Description>
                        Current Sort Order: {item.sortOrder} ------- New Sort Order: {index}
                    </Card.Description>
                </Card.Content>
            </Card>
        )
    };

    updatePageGroupSortOrder = () => {
        this.setState({buttonPageGroupLoading: true})
        let pageGroups = cloneDeep(this.state.pageGroupChildren);
        let last = pageGroups.length -1;

        pageGroups.forEach((pageGroup: PageGroup, index) => {
            pageGroup.sortOrder=index
            this.props.pageGroupClient.updatePageGroup(pageGroup).then(response => {
                if(response.ok)
                    toast.success(response.message)
                else toast.error(response.message)

                if(index === last)
                {
                    this.setState({buttonPageGroupLoading: false})
                    this.getPageGroupChildren()
                }
            })
        })
    }

    handlePageGroupChange = (reorderedItems: Array<any>) => {
        this.setState({ pageGroupChildren: reorderedItems });
    };

    render() {
        return (
            <Segment padded={true} id={'page-group-children'}>
                <Label attached='top'>Sorted First</Label>
                <Divider horizontal={true} hidden={true}>
                    <Header as='h4'>
                        <Icon name='clone' />
                        Page Groups
                    </Header>
                </Divider>
                <RLDD
                    cssClasses="list-container"
                    items={this.state.pageGroupChildren}
                    itemRenderer={this.pageGroupChildRenderer}
                    onChange={this.handlePageGroupChange}
                />
                <Grid id={'page-group-children-grid'}>
                    <Grid.Row>
                        <Grid.Column textAlign={'right'}>
                            <Button color={'green'}
                                    loading={this.state.buttonPageGroupLoading}
                                    onClick={this.updatePageGroupSortOrder}
                                    className={'form-action-button'}
                                    disabled={this.state.pageGroupChildren.length<1}
                                    content={'UPDATE PAGE GROUP SORT ORDER'}/>
                        </Grid.Column>
                    </Grid.Row>
                </Grid>
                <SppModal
                    open={this.state.modalOpen}
                    modalContent={this.state.modalContent}/>
            </Segment>
        );
    }
}