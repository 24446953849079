import {Feature, PortalProperties} from "../../../models/models";
import React from "react";
import {getFeatures} from "../../../services/feature-service";
import {Segment} from "semantic-ui-react";
import SppReactTable from "../../Common/PaginatedTable/SppReactTable";
import {emptyFeature} from "../../../util/constants";
import {info} from "../../../services/route-service";
import {getFeatureHeaders} from "../../../services/table-header-service";


interface PageProps {
    userCookie: any,
    history: any
    portalProperties: PortalProperties
}

interface PageState {
    tableLoading: boolean
    features: Feature[]
    selectedFeature: Feature
}

class FeaturePage extends React.Component<PageProps, PageState> {
    constructor(props: PageProps, state: PageState) {
        super(props, state);

        this.state = {
            tableLoading: false,
            selectedFeature: emptyFeature,
            features: []
        }
    }

    componentDidMount(): void {
        this.getFeatures();
    }

    getFeatures = () => {
        this.setState({tableLoading: true})

        getFeatures(this.props.userCookie)
            .then( (response: Feature[]) => {
                let booleansConvertedBackslashesRemoved = response.map((feature: Feature) => {
                    return {
                        featureName: feature.featureName, featureEnabled: feature.featureEnabled.toString(), strategyId: feature.strategyId,
                        strategyParams: feature.strategyParams ? feature.strategyParams.replace(/\\/g, ''): ''
                    }
                })
                this.setState({features: booleansConvertedBackslashesRemoved, tableLoading: false});
            })
    }

    setSelectedFeature = (row: Feature) => {
        if (row !== null)
            this.setState({selectedFeature: row});
        else
            this.setState({selectedFeature: emptyFeature});
    }

    handleFeatureEditClick = () => {
        this.props.history.push(info.features.path + '/' + this.state.selectedFeature.featureName)
    }

    render() {
        return (
            <Segment>
                <SppReactTable
                    tableData={this.state.features}
                    headers={getFeatureHeaders()}
                    tableButtonGroupVisible={true}
                    refreshTableButton={true}
                    loading={this.state.tableLoading}
                    handleTableRefresh={this.getFeatures}
                    selectable={true}
                    selectButtonWording={'Edit Feature'}
                    sendSelectedToParent={this.setSelectedFeature}
                    editSelectedRow={true}
                    handleRowEdit={this.handleFeatureEditClick}
                    deleteSelectedRow={false}
                />

            </Segment>
        );
    }
}

export default FeaturePage;