import * as React from 'react';
import {Menu} from "semantic-ui-react";
import moment from 'moment';
import './index.css'
import {AppInfo} from "../../../../../models/models";


interface PageProps {
    appInfo: AppInfo
}

interface PageState {}

export default class Footer extends React.Component<PageProps, PageState> {
    constructor(props: PageProps, state: PageState) {
        super(props, state);

        this.state = {}
    }

    render(){
        let year = moment().year();

        return(
            <Menu id={'footer-menu'} widths={3}>
                <Menu.Item/>
                <Menu.Item className={'footer-item'}>
                    <span id="Copyright footer">©2011 - {year} Southwest Power Pool, Inc. All rights reserved</span>
                </Menu.Item>
                <Menu.Item className={'footer-item'}>
                   <span id={'footer-right'} className={'wt__footer-link'}>
                       <a data-testid="Homepage link" target="_blank" rel="noopener noreferrer" href={`${this.props.appInfo.portalAddress}`}>
                           Portal.spp.org</a>
                   </span>
                </Menu.Item>
            </Menu>
        )
    }
}