import * as React from 'react';
import './index.css'
import {Rail} from "semantic-ui-react";
import {SessionAnnouncement} from "../../../models/models";
import SessionBasedAnnouncement from "./SessionBasedAnnouncement";


interface PageProps {
    sessionAnnouncements: SessionAnnouncement[]
    getSessionAnnouncements: (userCookie: string) => void
    userCookie: any
}

const Announcements: React.FC<PageProps> = (props: PageProps) => {

    let getAnnouncements = () => {
        let announcementArray: any = [];
        if (props.sessionAnnouncements.length > 0)
            props.sessionAnnouncements.forEach((announcement: SessionAnnouncement, index: number) => {
                if (!announcement.dismissed)
                {
                    announcementArray.push(
                        <SessionBasedAnnouncement
                            key={announcement.announcement.id}
                            announcement={announcement.announcement}
                            index={index}
                            getSessionAnnouncements={props.getSessionAnnouncements}
                            userCookie={props.userCookie}/>
                    )
                }
            })
        return announcementArray
    }

    if (props.sessionAnnouncements.length > 0)
    {
        return(
            <Rail
                internal={true}
                position='left'
                id={'sa-rail'}>
                {getAnnouncements()}
            </Rail>
        )
    }
    else
        return null
}

export default Announcements;