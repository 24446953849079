import * as React from 'react';
import {AppInfo, MenuOption, PortalProperties, Principal} from "../../../../../models/models";
import {Button, Grid, GridColumn, Icon, Menu, MenuItem} from "semantic-ui-react";
import './index.css';
import {info} from "../../../../../services/route-service";
import { getMenu } from 'src/services/menu-service';
import {NavLink} from "react-router-dom";
import ReactGA from "react-ga4";


interface PageProps {
    appInfo: AppInfo
    selectedTopMenuOption: string
    setSelectedTopMenuOption: (selectedTopMenuOption: string) => void;
    railDisplay: string
    setRailDisplay: (railDisplay: string) => void
    userCookie: any
    setMenuOptions: (menuOptions: MenuOption[]) => void
    menuOptions: MenuOption[]
    history: any
    getPortalProperties: (xsrfToken: string) => void;
    portalProperties: PortalProperties;
    userPrincipal: Principal
    getUserPrincipal: (xsrfToken: string) => void;
    darkTheme: boolean;
    getSessionAnnouncements: (xsrfToken: string) => void;
}

interface PageState {
    viewWidth: number
    darkMode: boolean
}

export default class AppHeader extends React.Component<PageProps, PageState> {
    constructor(props: PageProps, state: PageState) {
        super(props, state);

        this.updateWindowDimensions = this.updateWindowDimensions.bind(this);

        this.state = {
            viewWidth: 0,
            darkMode: true
        }
    }

    componentDidMount() {
        this.getMenuData();
        this.props.getPortalProperties(this.props.userCookie);
        this.props.getSessionAnnouncements(this.props.userCookie);

        if (this.props.userPrincipal.displayName === '')
            this.props.getUserPrincipal(this.props.userCookie);

        this.updateWindowDimensions();
        window.addEventListener('resize', this.updateWindowDimensions);
    }

    componentDidUpdate(prevProps: Readonly<PageProps>, prevState: Readonly<PageState>, snapshot?: any) {
        if(this.props.railDisplay !== prevProps.railDisplay && this.props.railDisplay === 'none')
            this.props.setSelectedTopMenuOption('')

        if(this.props.userPrincipal.username !== prevProps.userPrincipal.username && !this.props.userPrincipal.unauthenticatedUser)
            ReactGA.set({ userId: this.props.userPrincipal.username});

    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.updateWindowDimensions);
    }

    updateWindowDimensions() {
        this.setState({ viewWidth: window.innerWidth });
    }

    getMenuData = () => {
        getMenu(this.props.userCookie).then((response: MenuOption[]) => {
            response = response.filter((x: MenuOption) => x.name !== 'Welcome');
            this.props.setMenuOptions(response);
        })
    }

    menuItemSelected = (menuItem: string) => {
        if (menuItem === this.props.selectedTopMenuOption && this.props.railDisplay !== 'none')
            return '-selected';
        else return '';
    }

    onHoverMenuOptions = (option: MenuOption) => {
        if (option.type === 'PAGE')
        {
            this.props.setSelectedTopMenuOption('');
            this.props.setRailDisplay('none');
        }
        else
        {
            this.props.setSelectedTopMenuOption(option.name);
            this.props.setRailDisplay('');
        }
    }

    getMenuOptions = () => {
        let menuItems: any = [];
        this.props.menuOptions.forEach(item => {
            if (this.props.userPrincipal.unauthenticatedUser && item.name === 'Public')
            {
                item.childList.forEach( option => {
                    menuItems.push( <Menu.Item key={option.name+option.slug}
                                               className={'rail-menu-item ribbon underline-on-hover' + this.menuItemSelected(option.name)}
                                               onMouseEnter={() => this.onHoverMenuOptions(option)}
                                               onClick={() => {this.handleMenuRowOnClick(option)}}
                                               content={option.name}/>)
                })
            }
            else
                menuItems.push( <Menu.Item key={item.name+item.slug}
                                           className={'rail-menu-item ribbon underline-on-hover' + this.menuItemSelected(item.name)}
                                           onClick={() => {this.handleMenuRowOnClick(item)}}
                                           onMouseEnter={() => this.onHoverMenuOptions(item)}
                                           content={item.name}/>)

        })
        return menuItems;
    }

    handleMenuRowOnClick = (option: MenuOption) => {
        this.closeMenu()
        if (option.type === 'PAGE')
        {
            if (option.pageType === 'external')
                window.open(option.slug, '_blank');
            else if (option.slug === 'user-administration')
                window.open('https://'+this.props.portalProperties.uaaHost+'/manage-users', '_blank');
            else if (option.slug === 'local-security-administration')
                window.open('https://'+this.props.portalProperties.uaaHost+'/lsa/manage-users', '_blank');
            else if (option.slug === 'admin-pages')
                window.open('/admin/pages', '_blank');
            else if (option.slug === 'my-account')
                window.open('https://'+this.props.portalProperties.uaaHost+'/account-management', '_blank');
            else this.props.history.push('/pages/'+option.slug)
        }

        if (option.type === 'PAGE_GROUP')
            this.props.history.push('/groups/'+option.slug)

        if (option.slug === 'your-certificate-information')
            this.props.history.push(info.certInfo.path);

    }

    closeMenu = () => {
        this.props.setRailDisplay('none');
        this.props.setSelectedTopMenuOption('');
    }

    getProperty = (property: string) => {
        if (property === null || property === undefined)
            return ''
        else return property.toUpperCase()
    }

    getSignInButton = () => {
        if (this.props.userPrincipal.unauthenticatedUser)
            return (
                <Button
                    id={'sign-in-button'}
                    onClick={() => {window.location.href = 'https://'+this.props.portalProperties.uaaHost+'/auth-login?target=https://'+this.props.portalProperties.portalHost}}
                    className={'application-info'}>
                    <Icon name={'user'}/>
                    Sign In
                </Button>
            )
        else
            return (
                <Button id={'sign-in-button'}
                        onClick={() => {window.location.href = 'https://'+this.props.portalProperties.uaaHost+'/api/auth/logout?target=https://'+this.props.portalProperties.portalHost}}
                        className={'application-info'}>
                    <Icon name={'user'}/>
                    {this.props.userPrincipal.displayName}
                </Button>
            )
    }

    getMenu = () => {
        if (((this.props.menuOptions.length * 200)+200) > this.state.viewWidth )
        {
            let overflow: MenuOption = {asPage: false, childList: [], slug: "", type: "", name: 'overflow'};
            return (
                <Menu
                    className={'rail-sub-menu'}
                    borderless={true}>
                    <MenuItem
                        id={'condensed-menu'}
                        onMouseOver={() => this.onHoverMenuOptions(overflow)}>
                        <Icon name={'bars'}/>
                        MENU
                    </MenuItem>
                </Menu>
            )
        }
        else
            return (
                <Menu
                    compact={true}
                    className={'rail-sub-menu'}
                    borderless={true}
                    children={this.getMenuOptions()}/>
            )
    }

    getLinkMenu = () => {
        if (this.state.viewWidth > 950)
            return (
                <Grid.Column width={8}>
                    <Menu
                        id={'link-menu'}
                        widths={5}
                        borderless={true}>
                        <Menu.Item
                            as={'a'}
                            target={'_blank'}
                            href={this.props.portalProperties.hrefSppOrg}
                            className={'link-menu'}
                            content={this.getProperty(this.props.portalProperties.hrefNameSppOrg)}/>
                        <Menu.Item
                            as={'a'}
                            target={'_blank'}
                            href={this.props.portalProperties.hrefOasis}
                            className={'link-menu'}
                            content={this.getProperty(this.props.portalProperties.hrefNameOasis)}/>
                        <Menu.Item
                            as={'a'}
                            target={'_blank'}
                            href={this.props.portalProperties.hrefOps1}
                            className={'link-menu'}
                            content={this.getProperty(this.props.portalProperties.hrefNameOps1)}/>
                        <Menu.Item
                            as={'a'}
                            target={'_blank'}
                            href={this.props.portalProperties.hrefCrow}
                            className={'link-menu'}
                            content={this.getProperty(this.props.portalProperties.hrefNameCrow)}/>
                        <Menu.Item
                            as={'a'}
                            target={'_blank'}
                            href={this.props.portalProperties.hrefOatiWebTrans}
                            className={'link-menu'}
                            content={this.getProperty(this.props.portalProperties.hrefNameOatiWebTrans)}/>
                    </Menu>
                </Grid.Column>
            )
        else return false
    }

    render(){
        return(
            <div className={'common'}>
                <Grid>
                    <Grid.Row
                        id={'common-header-row'}
                        columns={this.state.viewWidth > 950 ? 3 : 2}
                        verticalAlign={'middle'}>
                        <Grid.Column width={this.state.viewWidth > 950 ? 4 : undefined}>
                            <NavLink to={info.home.path}>
                                <img
                                    id={'public-spp-logo'}
                                    src={"/images/spplogo.png"}
                                    alt={"SPP logo"}
                                    onClick={this.closeMenu}/>
                            </NavLink>
                        </Grid.Column>
                        {this.getLinkMenu()}
                        <Grid.Column width={this.state.viewWidth > 950 ? 4 : undefined} textAlign={'right'} >
                                <span
                                    className={'application-info ui button'}
                                    id={'environment-version'}>
                                    {this.props.portalProperties.environmentShortName+ ' '+this.props.portalProperties.apiVersion}
                                </span>
                            <span id={'environment-version-divider'} className={'application-info ui button'}>|</span>
                            {this.getSignInButton()}
                        </Grid.Column>
                    </Grid.Row>
                    <Grid.Row columns={1} id={'app-menu-row'}>
                        <GridColumn textAlign={'center'}>
                            {this.getMenu()}
                        </GridColumn>
                    </Grid.Row>

                </Grid>
            </div>
        )
    }
}
