export default class RouteService {}

export const info = {

    announcements: {
        path:'/admin/announcements',
        title: 'Announcements ',
        description: 'Select an announcement to view options'
    },
    managePages: {
        path:'/admin/pages',
        title: 'Pages ',
        description: 'Select a page to view options'
    },

    createPage: {
        path: '/admin/pages/create-page',
    },

    editPage: {
        path: '/admin/pages/:pageId'
    },

    pageGroups: {
        path:'/admin/page-groups',
        title: 'Page Groups ',
        description: 'Select a page group to view options'
    },

    editPageGroup:{
        path: '/admin/page-groups/:pageGroupId'
    },
    pageTypes: {
        path:'/admin/page-types',
        title: 'Page Types ',
        description: 'Select a page type to view options'
    },
    fileBrowsers: {
        path:'/admin/file-browsers',
        title: 'File Browsers ',
        description: 'Select a file browser to view options'
    },
    features: {
        path: '/admin/features',
        title: 'Features',
        description: 'Features are configurable code that execute based on a strategy and/or toggling on or off. ' +
                     'Note: some require wait time, CUF, or Remedy documentation'
    },
    editFeature: {
        path: '/admin/features/:featureName'
    },
    home: {
        path: '/'
    },

    westGraphs: {
        path: '/western-energy-services'
    },

    imGraphs: {
        path: '/integrated-marketplace'
    },

    pages: {
        path: '/pages/:pageSlug'
    },

    termsOfUse: {
        path: '/terms-of-use'
    },

    dashboardSettings: {
        path: '/dashboard-settings'
    },

    certInfo: {
        path: '/pages/your-certificate-information'
    },

    reliabilityDashboard: {
        path: '/pages/real-time-reliability-dashboard'
    },

    pageGroupAsPage: {
        path: '/groups/:groupSlug'
    },

    sitemap: {
        path: '/sitemap'
    }

};
