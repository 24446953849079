import AxiosService from "./axios-service";
import {ActionResponse, PageType, PageTypeConfig} from "../models/models";
import {genericError} from "../util/constants";

export default class PageTypeService {
    private client: AxiosService;

    constructor(csrfToken: string) {
        this.client = new AxiosService(csrfToken);
    }

    getPageTypes = (): Promise<PageType[]> => {
        const url = `/api/pageType/`;
        return new Promise<PageType[]>(((resolve) => {
            this.client.getAxiosInstance().get(url)
                .then(({data}) => {resolve(data)})
                .catch(() => {resolve([])})
        }));
    };

    getPageTypeConfigs = (): Promise<PageTypeConfig[]> => {
        const url = `/api/pageTypeConfig/`;
        return new Promise<PageTypeConfig[]>(((resolve) => {
            this.client.getAxiosInstance().get(url)
                .then(({data}) => {resolve(data)})
                .catch(() => {resolve([])})
        }));
    };

    createPageType = (pageType: PageType): Promise<ActionResponse<PageType>> => {
        const url = `/api/pageType/`;
        return new Promise<ActionResponse<PageType>>(((resolve) => {
            this.client.getAxiosInstance().post(url, pageType)
                .then(({data}: {data: any}): any => {
                    resolve(data)})
                .catch(() => {resolve(genericError)})
        }));
    };

    updatePageType = (pageType: PageType): Promise<ActionResponse<PageType>> => {
        const url = `/api/pageType/`;
        return new Promise<ActionResponse<PageType>>(((resolve) => {
            this.client.getAxiosInstance().put(url, pageType)
                .then(({data}: {data: any}): any => {
                    resolve(data)})
                .catch(() => {resolve(genericError)})
        }));

    };

    deletePageType = (ptId: number): Promise<ActionResponse<PageType>> => {
        const url = `/api/pageType/${ptId}`;
        return new Promise<ActionResponse<PageType>>(((resolve) => {
            this.client.getAxiosInstance().delete(url)
                .then(({data}) => {resolve(data)})
                .catch(() => {resolve(genericError)})
        }));
    };



    createPageTypeConfig = (pageTypeConfig: PageTypeConfig) => {
        const url = `/api/pageTypeConfig/`;
        return new Promise<ActionResponse<any>>(((resolve) => {
            this.client.getAxiosInstance().post(url, pageTypeConfig)
                .then(({data}: {data: any}): any => {
                    resolve(data)})
                .catch(() => {resolve(genericError)})
        }));

    };

    deletePageTypeConfig = (pcId: number) => {
        const url = `/api/pageTypeConfig/${pcId}`;
        return new Promise<ActionResponse<PageTypeConfig>>(((resolve) => {
            this.client.getAxiosInstance().delete(url)
                .then(({data}) => {resolve(data)})
                .catch(() => {resolve(genericError)})
        }));
    };


}