import * as React from 'react';
import {Button, ButtonProps, Grid} from "semantic-ui-react";
import './index.css'
import {info} from "../../../services/route-service";
import MenuRail from "../MenuRail";
import {MenuOption, PortalProperties, Principal} from "../../../models/models";

/*
    Default Error Page Not Found
 */

interface PageProps  {
    selectedTopMenuOption: string
    setSelectedTopMenuOption: (selectedTopMenuOption: string) => void;
    railDisplay: string
    setRailDisplay: (railDisplay: string) => void
    portalProperties: PortalProperties;
    history: any
    menuOptions: MenuOption[]
    userPrincipal: Principal
}

class ErrorNotFound extends React.Component<PageProps, {}> {

    handleClick = (event: React.MouseEvent<HTMLButtonElement>, data: ButtonProps) => {
        this.props.history.push(info.home.path);
    };

    public render() {
        return (
            <div className={'background-image-tou'} style={{backgroundImage: 'url(/images/portal-background-light.png'}}>
                <MenuRail
                    selectedTopMenuItem={this.props.selectedTopMenuOption}
                    railDisplay={this.props.railDisplay}
                    userPrincipal={this.props.userPrincipal}
                    portalProperties={this.props.portalProperties}
                    setRailDisplay={this.props.setRailDisplay}
                    menuOptions={this.props.menuOptions}
                    history={this.props.history}
                    setSelectedTopMenuOption={this.props.setSelectedTopMenuOption}/>
                <Grid >
                    <Grid.Row>
                        <Grid.Column >
                            <div className={'upper-text centered-error'}>
                                <span>404</span>
                            </div>
                            <br />
                            <div className={'middle-text centered-error'}>
                                <span>Page Not Found</span>
                            </div>
                            <br />
                            <div className={'lower-text centered-error'}>
                                <span>Sorry, we couldn't find the page you are looking for</span>
                            </div>
                            <br />
                            <div className={'center-link'}>
                                <Button size={'massive'} onClick={this.handleClick} color={'blue'}>
                                    RETURN HOME
                                </Button>
                            </div>
                        </Grid.Column>
                    </Grid.Row>
                </Grid>
            </div>
        );
    }
}


export default ErrorNotFound;