import * as React from 'react';
import { Grid, Icon, Segment} from "semantic-ui-react";
import './index.css'
import {FileSource, Page, PageConfig, PageGroup, PortalProperties,} from "../../../models/models";
import {emptyPageConfig, emptyPageGroup} from "../../../util/constants";
import toast from "../../../util/toast";
import PageService from "../../../services/page-service";
import {info} from "../../../services/route-service";
import FileBrowserService from "../../../services/file-browser-service";
import PageForm from "../../Common/PageForm";

interface PageProps {
    userCookie: any
    history: any
    portalProperties: PortalProperties
    match: any
}

interface PageState {
    description: any
    uaaApplication: any
    pageGroup: PageGroup
    slug: string
    showEmbeddedTitle: boolean,
    pageConfig: PageConfig
    buttonLoading: boolean
    formLoading: boolean
    fsPath: any
    selectLoading: boolean
    uaaAppRole: any
    allowInUserDashboard: boolean
    tableauCategory: string
    tableauViewName: string
}

export default class CreatePage extends React.Component<PageProps, PageState> {
    private pageClient: PageService;
    private fileBrowserClient: FileBrowserService;

    constructor(props: PageProps, state: PageState) {
        super(props, state);

        this.state = {
            description: '',
            uaaApplication: '',
            pageGroup: emptyPageGroup,
            slug: '',
            showEmbeddedTitle: true,
            pageConfig: emptyPageConfig,
            buttonLoading: false,
            formLoading: false,
            fsPath: '',
            selectLoading: false,
            uaaAppRole: '',
            allowInUserDashboard: true,
            tableauCategory: '',
            tableauViewName: ''

        };

        this.pageClient = new PageService(this.props.userCookie);
        this.fileBrowserClient = new FileBrowserService(this.props.userCookie);
    }

    //creates and returns FsSecurity object
    getFsSecurity = (uaaApp: any) => {
        if (this.state.uaaApplication === '' || this.state.uaaApplication === 0)
            return null;
        else
        {
            let getUaaAppRole = () => {
                if (this.state.uaaAppRole === '')
                    return null;
                else
                    return this.state.uaaAppRole
            };

            if (uaaApp !== undefined)
                return {authzRole: getUaaAppRole(), authzAppKey: uaaApp.key}

            return null
        }
    };

    //makes create page axios call and returns action response.
    handleCreatePage = (page: Page, pageRequest: any, uaaApp: any) => {
        this.setState({buttonLoading: true});
        this.pageClient.createPage(pageRequest).then((response: any) => {
                this.setState({buttonLoading: false});
                if (response.ok && response.item !== undefined) {
                    if (response.item.pageType.name === 'file-browser')
                    {
                        if (this.getFsSecurity(uaaApp) !== null)
                        {
                            this.fileBrowserClient.createFsSecurity(this.getFsSecurity(uaaApp)).then(response => {
                                if (response.ok && response.item !== undefined)
                                {
                                    let newFileSource: FileSource = {
                                        id:0,
                                        fsName: this.state.slug,
                                        fsPath: this.state.fsPath,
                                        fsSecurity: response.item
                                    };

                                    this.fileBrowserClient.createFileSource(newFileSource).then(response => {
                                        if (!response.ok)
                                        {
                                            toast.error(response.message);
                                            return;
                                        }
                                    })
                                }
                            })
                        }
                        else
                        {
                            let newFileSource: FileSource = {
                                id:0,
                                fsName: this.state.slug,
                                fsPath: this.state.fsPath,
                                fsSecurity: null
                            };
                            this.fileBrowserClient.createFileSource(newFileSource).then(response => {
                                if (!response.ok)
                                {
                                    toast.error(response.message);
                                    return;
                                }
                            })
                        }
                    }
                    this.props.history.push(info.managePages.path+'/'+response.item.id)
                } else
                    toast.error(response.message);
            })
    };

    //this set of set methods are allowing child to update parent states
    setConfig = (pageConfig: PageConfig) => {
        this.setState({pageConfig: pageConfig})
    }

    setDescription = (description: string) => {
        this.setState({description: description})
    }

    setShowTitle = (showTitle: boolean) => {
        this.setState({showEmbeddedTitle: showTitle})
    }

    setAllowInDashboard = (allowInDashboard: boolean) => {
        this.setState({allowInUserDashboard: allowInDashboard})
    }

    setTableauCategory = (tableauCategory: string) => {
        this.setState({tableauCategory: tableauCategory})
    }

    setTableauViewName = (tableauViewName: string) => {
        this.setState({tableauViewName: tableauViewName})
    }

    setFsPath = (fsPath: string) => {
        this.setState({fsPath: fsPath})
    }

    setSlug = (slug: string) => {
        this.setState({slug: slug})
    }

    setUaaApplication = (uaaApplication: any) => {
        this.setState({uaaApplication: uaaApplication})
    }

    setUaaAppRole = (uaaAppRole: any) => {
        this.setState({uaaAppRole: uaaAppRole})
    }

    render(){
        return(
            <Grid>
                <Grid.Row columns={1}>
                    <Grid.Column>
                        <Segment.Group id={'cp-segment-group'}>
                            <Segment id={'cp-segment-header'}> <Icon name={'add'}/> Create New Page </Segment>
                            <Segment>
                                <PageForm
                                    userCookie={this.props.userCookie}
                                    history={this.props.history}
                                    match={this.props.match}
                                    pageConfig={this.state.pageConfig}
                                    submitForm={this.handleCreatePage}
                                    setConfig={this.setConfig}
                                    formLoading={this.state.formLoading}
                                    portalProperties={this.props.portalProperties}
                                    setUaaApplication={this.setUaaApplication}
                                    setUaaAppRole={this.setUaaAppRole}
                                    setDescription={this.setDescription}
                                    setShowTitle={this.setShowTitle}
                                    setFsPath={this.setFsPath}
                                    allowInDashboard={this.state.allowInUserDashboard}
                                    setAllowInDashboard={this.setAllowInDashboard}
                                    description={this.state.description}
                                    showEmbeddedTitle={this.state.showEmbeddedTitle}
                                    fsPath={this.state.fsPath}
                                    tableauCategory={this.state.tableauCategory}
                                    setTableauCategory={this.setTableauCategory}
                                    tableauViewName={this.state.tableauViewName}
                                    setTableauViewName={this.setTableauViewName}
                                    uaaAppRole={this.state.uaaAppRole}
                                    buttonLoading={this.state.buttonLoading}
                                    slug={this.state.slug}
                                    setSlug={this.setSlug}/>
                            </Segment>
                        </Segment.Group>
                    </Grid.Column>
                </Grid.Row>
            </Grid>
        )
    }
}
