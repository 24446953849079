import {toast} from "react-toastify";

const toastMessage = {

    success(message: string, options = {}){
        return toast.success(message, {
            ...options,
        });
    },
    error(message: string, options = {}){
        return toast.error(message, {
            ...options,
            style: {
                background: 'red'
            }
        });
    },
    warn(message: string, options = {}){
        return toast.warn(message, {
            ...options,
            style: {
                background: '#fee26e'
            }
        });
    },
    info(message: string, options = {}){
        return toast.info(message, {
            ...options,
            style: {
                background: '#00bff3'
            }
        });
    }
};

export default toastMessage;