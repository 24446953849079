import * as React from 'react';
import {Menu} from "semantic-ui-react";
import moment from 'moment';
import './index.css'
import {info} from "../../../../../services/route-service";




interface PageProps {
    history: any
}

interface PageState {}

export default class PublicFooter extends React.Component<PageProps, PageState> {
    constructor(props: PageProps, state: PageState) {
        super(props, state);

        this.state = {}
    }

    render(){
        let year = moment().year();

        return(
            <Menu id={'footer-menu'} widths={1}>
                <Menu.Item className={'footer-item'}>
                    <span id="Copyright footer">
                        ©2011 - {year} Southwest Power Pool, Inc. All rights reserved
                    </span>
                    <span className={'footer-spacer'}>·</span>
                    <a href={info.termsOfUse.path}
                       className={'footer-link'}
                       target="_blank"
                       style={{color: "grey"}}
                       rel="noreferrer">
                        Terms of Use
                    </a>
                    <span className={'footer-spacer'}>·</span>
                    <a href={'/sitemap'}
                       target="_blank"
                       className={'footer-link'}
                       style={{color: "grey"}}
                       rel="noreferrer">
                        Sitemap
                    </a>
                </Menu.Item>
            </Menu>
        )
    }
}