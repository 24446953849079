import {Feature} from "../models/models";
import {activationStrategies, emptyFeature} from "../util/constants";
import axios from "axios";

export const getFeatures = (xsrfToken: string): Promise<Feature[]> => {
        const url = '/api/features/';

        let axiosRequestConfig = {headers: {
                'X-SPP-UI-Token': xsrfToken,
                'Accept': 'application/json',
                'Content-Type': 'application/json'}};

        return new Promise<Feature[]>( (resolve) => {
            axios.get(url, axiosRequestConfig)
                .then( ({data}) => {resolve(data)})
                .catch( () => {resolve([])} )
        } )
    }
    
export const getFeatureByName = (xsrfToken: string, featureName: string): Promise<Feature> => {
        const url = '/api/features/' + featureName;

        let axiosRequestConfig = {headers: {
                'X-SPP-UI-Token': xsrfToken,
                'Accept': 'application/json',
                'Content-Type': 'application/json'}};

        return new Promise<Feature>( (resolve) => {
            axios.get(url, axiosRequestConfig)
                .then( ({data}) => {resolve(data)})
                .catch( () => { resolve(emptyFeature) })
        } )
    }
    
export const updateFeature = (xsrfToken: string, featureName: string, enabled: boolean, strategy: string, strategyParameter1: string, strategyParameter2?: string): Promise<Boolean> => {
        const url = '/feature-console/edit';

        let params = new URLSearchParams();
        params.append('f', featureName);
        params.append('enabled', enabled ? 'enabled' : '');
        params.append('strategy', strategy ? strategy : '')


            switch(strategy) {
                case activationStrategies.GradualRollout:
                    params.append('p1', strategyParameter1);
                    break;
                case activationStrategies.ReleaseDate:
                    params.append('p7', strategyParameter1);
                    if (strategyParameter2)
                        params.append('p8', strategyParameter2);
                    break;
                case activationStrategies.UserByUsername:
                    params.append('p15', strategyParameter1);
                    break;
                default:
                    break;
            }

        let axiosRequestConfig = { headers: {
            'X-SPP-UI-token': xsrfToken,
            'Accept': 'application/json',
            'Content-Type': 'application/json'},
            maxRedirects: 0,
            params: params
        };

        return new Promise<Boolean>( (resolve) => {
            axios.post(url, params, axiosRequestConfig)
                .then( () => {resolve(true)})
                .catch ( (error) => {
                    if (error.response === undefined)
                        resolve(true);
                    else
                        resolve(false);
                });
        });
    }