import * as React from 'react';
import './index.css'
import MenuRail from "../../Common/MenuRail";
import {Button, Dimmer, Grid, GridColumn, Icon, Loader, Segment} from "semantic-ui-react";
import {info} from "../../../services/route-service";
import {MenuOption, PageConfig, PortalProperties, Principal} from "../../../models/models";
import ChartService from "../../../services/chart-service";
import moment from "moment/moment";
import {chartName, emptyPage} from "../../../util/constants";
import PageConfigService from "../../../services/page-config-service";
import {getChartComponent} from "../../../services/static-service";
import {getBackgroundImage} from "../../../util/functions";
import {NavLink} from "react-router-dom";
import ReactGA from "react-ga4";

interface PageProps {
    selectedTopMenuOption: string
    setSelectedTopMenuOption: (selectedTopMenuOption: string) => void;
    railDisplay: string
    setRailDisplay: (railDisplay: string) => void
    portalProperties: PortalProperties;
    history: any
    menuOptions: MenuOption[]
    userPrincipal: Principal
    userCookie: any
    darkTheme: boolean
}

interface PageState {
    weisInterchangeLoading: boolean
    weisForecastLoading: boolean
    wesiGenMixLoading: boolean
    lastModified: Date
    chartPageConfigs: PageConfig[]
}

export default class WestGraphs extends React.Component<PageProps, PageState> {
    private readonly chartService: ChartService;
    private readonly pageConfigService: PageConfigService
    constructor(props: PageProps, state: PageState) {
        super(props, state);

        this.chartService = new ChartService(this.props.userCookie);
        this.pageConfigService = new PageConfigService(this.props.userCookie);

        this.state = {
            weisInterchangeLoading: true,
            weisForecastLoading: true,
            wesiGenMixLoading: true,
            lastModified: new Date(0),
            chartPageConfigs: []
        }
    }

    componentDidMount() {
        let chartNameList = [chartName.WEIS_INTERCHANGE_TREND, chartName.WEIS_LOAD_FORECAST, chartName.WEIS_GEN_MIX]
        ReactGA.event({category: "user_page_view", action: "View WEIS Chart Page"});
        this.pageConfigService.getPageConfigByChartNames(chartNameList)
            .then(results => this.setState({chartPageConfigs: results}))
    }

    getPageByChartName = (chartName: string) => {
        let pc = this.state.chartPageConfigs.find(chart => chart.pcValue === chartName);
        if (pc !== undefined && pc.page !== undefined)
            return pc.page
        else return emptyPage
    }

    updateLoadingStatus = (loading: boolean, name: string) => {
        switch (name) {
            case chartName.WEIS_INTERCHANGE_TREND:
                this.setState({weisInterchangeLoading: loading})
                break;
            case chartName.WEIS_LOAD_FORECAST:
                this.setState({weisForecastLoading: loading})
                break;
            case chartName.WEIS_GEN_MIX:
                this.setState({wesiGenMixLoading: loading})
                break;
        }
    }

    getLoadingState = (name: string) => {
        switch (name) {
            case chartName.WEIS_INTERCHANGE_TREND:
                return this.state.weisInterchangeLoading
            case chartName.WEIS_LOAD_FORECAST:
                return this.state.weisForecastLoading
            case chartName.WEIS_GEN_MIX:
                return this.state.wesiGenMixLoading
            default:
                return false
        }
    }

    updateLastModified = (lastModified: Date) => {
        if (lastModified > this.state.lastModified)
            this.setState({lastModified: lastModified})
    }

    getWestLogo = () => {
        if (this.props.darkTheme)
            return '/images/western-logo-reverse.png'
        else return '/images/western-logo-crop.png'
    }

    getGridChartColumn = (name: string) => {
        return (
            <Grid.Column className={this.props.darkTheme? 'chart-column dark' : 'chart-column'}>
                <Segment.Group className={'chart-sg'}>
                    <Segment
                        content={this.getPageByChartName(name).title}
                        className={'chart-title-sg'}/>
                    <Segment className={'chart-segment'}>
                        <Dimmer active={this.getLoadingState(name)} inverted={true}>
                            <Loader/>
                        </Dimmer>
                        {getChartComponent(
                            name,
                            this.updateLastModified,
                            this.chartService,
                            this.getLoadingState(name),
                            this.props.darkTheme,
                            this.updateLoadingStatus,
                            false)}
                    </Segment>
                    <NavLink to={'pages/'+this.getPageByChartName(name).slug}>
                        <Button
                            className={'narrow-btn graph-btn'}
                            attached='bottom'
                            color={'grey'}>
                            SEE MORE
                            <Icon className={'see-more-arrow'} name={'arrow right'} />
                        </Button>
                    </NavLink>
                </Segment.Group>
            </Grid.Column>
        )
    }

    render(){
        return(
            <div
                id={'weis-chart-div'}
                className={'background-image-div'}
                style={{backgroundImage: this.props.userPrincipal.unauthenticatedUser?
                        getBackgroundImage(false) : getBackgroundImage(this.props.darkTheme)}}>
                <MenuRail
                    selectedTopMenuItem={this.props.selectedTopMenuOption}
                    railDisplay={this.props.railDisplay}
                    userPrincipal={this.props.userPrincipal}
                    portalProperties={this.props.portalProperties}
                    setRailDisplay={this.props.setRailDisplay}
                    menuOptions={this.props.menuOptions}
                    history={this.props.history}
                    setSelectedTopMenuOption={this.props.setSelectedTopMenuOption}/>
                <Grid id={'weis-chart-grid'}>
                    <Grid.Row
                        id={'west-char-logo-row'}
                        columns={1}
                        textAlign={'right'}>
                        <Grid.Column>
                            <img
                                id={'west-chart-logo'}
                                src={this.getWestLogo()}
                                alt={'west Logo'}/>
                        </Grid.Column>
                    </Grid.Row>
                    <Grid.Row id={'west-chart-row'} columns={1}>
                        {this.getGridChartColumn(chartName.WEIS_LOAD_FORECAST)}
                    </Grid.Row>
                    <Grid.Row id={'west-chart-row'} columns={2}>
                        {this.getGridChartColumn(chartName.WEIS_INTERCHANGE_TREND)}
                        {this.getGridChartColumn(chartName.WEIS_GEN_MIX)}
                    </Grid.Row>
                    <Grid.Row columns={2} id={'back-btn-row'}>
                        <Grid.Column id={'graph-button-column'}>
                            <NavLink to={info.home.path}>
                                <Button
                                    className={'narrow-btn'}
                                    color={'grey'}>
                                    <Icon name={'arrow left'}/>
                                    BACK
                                </Button>
                            </NavLink>
                        </Grid.Column>
                        <GridColumn
                            verticalAlign={'middle'}
                            textAlign={'right'}
                            id={'light-last-modified-column'}>
                            LAST UPDATED {moment(this.state.lastModified).format('YYYY-MM-DD HH:mm:ss')} CT
                        </GridColumn>
                    </Grid.Row>
                </Grid>
            </div>
        )
    }
}