import {getTableauJWT} from "../../../services/chart-service";
import * as React from "react";
import {memo, useEffect, useState} from "react";
import {graphUpdateInterval} from "../../../util/constants";

interface PageProps {
    updateLoading: (loading: boolean, chartName: string) => void
    loading: boolean
    tableauSrc: string
    viewName: string
    userCookie: any
    height?: string
    position: number
    total: number
    updateLastModified?: (lastModified: Date) => void
}

const Tableau: React.FC<PageProps> = (props: PageProps) => {
    const [tableauToken, setTableauToken] = useState<string>('');
    const [loaded, setLoaded] = useState<boolean>(false);

    useEffect(()=> {
        let getTableauToken = () => {
            getTableauJWT(props.userCookie).then(response => {
                setTableauToken(response)

                if (props.updateLastModified)
                    props.updateLastModified(new Date())

                if (props.viewName !== '')
                {
                    let vizElement: any = document.getElementById(props.viewName);
                    if (vizElement !== null)
                    {
                        vizElement.token = response;

                        if (!loaded)
                            vizElement.addEventListener("firstinteractive", handleFirstInteraction)
                    }
                }
            })
        }

        if (props.loading)
            getTableauToken()

        let interval = setInterval(() => {
            if (!props.loading)
                props.updateLoading(true, props.viewName)
            else
                getTableauToken()

        },  graphUpdateInterval); //fetch data every 5 min

        // clean up
        return () => {
            clearInterval(interval)
        };

    }, [props.loading, props.viewName])  // eslint-disable-line


    let getHeight = () => {
        if (props.height)
            return props.height

        if (props.total > 2)
            return 'max(calc((100vh - (7.1rem + 418px )) / 2), 150px)'
        else if (props.total === 0)
            return 'max(calc((100vh - (7.1rem + 418px )) / 1.25), 350px)'
        else
            return 'max(calc(100vh - (7.1rem + 270px )), 450px)'
    }

    let getWidth = () => {
        if (props.total === 1 || (props.total === 3 && props.position === 3) || props.total === 0)
            return 'calc(100vw - (7.1rem)'

       if (props.total === 5 && props.position > 2)
           return 'calc((100vw - (7.1rem) / 3 )'

        return 'calc((100vw - (7.1rem) / 2 )'
    }


    let handleFirstInteraction = () => {
        console.log(props.viewName + ' loaded successfully')
        props.updateLoading(false, props.viewName)
        setLoaded(true);
    }

    if (tableauToken !== '' && props.tableauSrc !== '')
    {
        return (
            <div>
                {/*// @ts-ignore*/}
                <tableau-viz id={props.viewName}
                             src={props.tableauSrc}
                             toolbar={'hidden'}
                             width={getWidth()}
                             height={getHeight()}/>
            </div>
        )
    }
    else
        return <div/>
}

export default memo(Tableau)
