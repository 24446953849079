import matchSorter from 'match-sorter'
import {SppReactTableColumns} from "../models/models";


export const getAnnouncementHeaders = (): Array<SppReactTableColumns> => {
    return (
        [
            {
                Header: "Title",
                accessor: "title",
                id: "title",
                filterMethod: (filter: any, rows: any) =>
                    matchSorter(rows, filter.value, {keys: ["title"]}),
                filterAll: true
            },
            {
                Header: "Display Color",
                accessor: "displayType",
                id: "displayType",
                filterMethod: (filter: any, rows: any) =>
                    matchSorter(rows, filter.value, {keys: ["displayType"]}),
                filterAll: true
            },
            {
                Header: "Published",
                accessor: "published",
                id: "published",
                filterMethod: (filter: any, rows: any) =>
                    matchSorter(rows, filter.value, {keys: ["published"]}),
                filterAll: true
            },
            {
                Header: "Public",
                accessor: "isPublic",
                id: "isPublic",
                filterMethod: (filter: any, rows: any) =>
                    matchSorter(rows, filter.value, {keys: ["isPublic"]}),
                filterAll: true
            },
            {
                Header: "Expires",
                accessor: "expirationDate",
                id: "expirationDate",
                filterMethod: (filter: any, rows: any) =>
                    matchSorter(rows, filter.value, {keys: ["expirationDate"]}),
                filterAll: true
            },
        ]
    )
};

export const getPageHeaders = (): Array<SppReactTableColumns> => {
    return (
        [
            {
                Header: "Title",
                accessor: "title",
                id: "title",
                filterMethod: (filter: any, rows: any) =>
                    matchSorter(rows, filter.value, {keys: ["title"]}),
                filterAll: true
            },
            {
                Header: "Slug",
                accessor: "slug",
                id: "slug",
                filterMethod: (filter: any, rows: any) =>
                    matchSorter(rows, filter.value, {keys: ["slug"]}),
                filterAll: true
            },
            {
                Header: "Active Flag",
                accessor: "activeFlag",
                id: "activeFlag",
                filterMethod: (filter: any, rows: any) =>
                    matchSorter(rows, filter.value, {keys: ["activeFlag"]}),
                filterAll: true
            },
            {
                Header: "Is Public",
                accessor: "isPublic",
                id: "isPublic",
                filterMethod: (filter: any, rows: any) =>
                    matchSorter(rows, filter.value, {keys: ["isPublic"]}),
                filterAll: true
            },
            {
                Header: "Group",
                accessor: "pageGroupName",
                id: "pageGroupName",
                filterMethod: (filter: any, rows: any) =>
                    matchSorter(rows, filter.value, {keys: ["pageGroupName"]}),
                filterAll: true
            },
            {
                Header: "Type",
                accessor: "pageTypeName",
                id: "pageTypeName",
                filterMethod: (filter: any, rows: any) =>
                    matchSorter(rows, filter.value, {keys: ["pageTypeName"]}),
                filterAll: true
            },
            {
                Header: "Depth",
                accessor: "depth",
                id: "depth",
                filterMethod: (filter: any, rows: any) =>
                    matchSorter(rows, filter.value, {keys: ["depth"]}),
                filterAll: true
            }
        ]
    )
};

export const getPageGroupHeaders = (): Array<SppReactTableColumns> => {
    return (
        [
            {
                Header: "Group Name",
                accessor: "name",
                id: "name",
                filterMethod: (filter: any, rows: any) =>
                    matchSorter(rows, filter.value, {keys: ["name"]}),
                filterAll: true
            },
            {
                Header: "Slug",
                accessor: "slug",
                id: "slug",
                filterMethod: (filter: any, rows: any) =>
                    matchSorter(rows, filter.value, {keys: ["slug"]}),
                filterAll: true
            },
            {
                Header: "Parent Group",
                accessor: "pageGroupName",
                id: "pageGroupName",
                filterMethod: (filter: any, rows: any) =>
                    matchSorter(rows, filter.value, {keys: ["pageGroupName"]}),
                filterAll: true
            },
            {
                Header: "Depth",
                accessor: "depth",
                id: "depth",
                filterMethod: (filter: any, rows: any) =>
                    matchSorter(rows, filter.value, {keys: ["depth"]}),
                filterAll: true
            }
        ]
    )
};

export const getFeatureHeaders = (): Array<SppReactTableColumns> => {
        return (
            [
                {
                    Header: "Feature Name",
                    accessor: "featureName",
                    id: "featureName",
                    filterMethod: (filter: any, rows: any) =>
                        matchSorter(rows, filter.value, {keys: ["featureName"]}),
                    filterAll: true
                },
                {
                    Header: "Feature Enabled",
                    accessor: "featureEnabled",
                    id: "featureEnabled",
                    filterMethod: (filter: any, rows: any) =>
                        matchSorter(rows, filter.value, {keys: ["featureEnabled"]}),
                    filterAll: true
                },
                {
                    Header: "Strategy Id",
                    accessor: "strategyId",
                    id: "strategyId",
                    filterMethod: (filter: any, rows: any) =>
                        matchSorter(rows, filter.value, {keys: ["strategyId"]}),
                    filterAll: true
                },
                {
                    Header: "Strategy Params",
                    accessor: "strategyParams",
                    id: "strategyParams",
                    filterMethod: (filter: any, rows: any) =>
                        matchSorter(rows, filter.value, {keys: ["strategyParams"]}),
                    filterAll: true
                }
            ]
        );
};