import * as React from 'react';
import {Menu} from "semantic-ui-react";
import "./index.css"

interface PageProps {
    selectable: boolean
    rowSelected: any
    editRow?: boolean
    deleteRow?: boolean
    customEditRowWording?: string
    handledEditRowClick?: Function
    handleDeleteRowClick?: Function
    customButtons?: any[]
}

interface PageState {}

class TableActionBar extends React.Component<PageProps, PageState> {
    constructor(props: PageProps, state: PageState){
        super(props, state);

        this.state = {}
    }

    getEditButtonCustomWording = () => {
        if(this.props.customEditRowWording === undefined)
            return 'Edit';
        else return this.props.customEditRowWording
    };


    getEditRowMenuItem = () => {
        if(this.props.editRow)
        {
            return(
                <Menu.Item content={this.getEditButtonCustomWording()}
                           id={'aab-edit-button'}
                           className={'ab-menu-item'}
                           onClick={this.handleEditRowClick}/>)
        }
        else
            return null
    };

    handleEditRowClick = () => {
        if(this.props.handledEditRowClick === undefined)
            return;
        else
            this.props.handledEditRowClick()
    };

    getDeleteRowMenuItem = () => {
        if(this.props.deleteRow)
        {
            return(
                <Menu.Item content={'Delete'}
                           id={'tab-delete-button'}
                           className={'ab-menu-item'}
                           onClick={this.handleDeleteRowClick}/>)
        }
        else
            return null
    };

    handleDeleteRowClick = () => {
        if(this.props.handleDeleteRowClick === undefined)
            return;
        else
            this.props.handleDeleteRowClick()
    };

    getCustomMenuItems = () => {
        if(this.props.customButtons !== undefined)
            return this.props.customButtons.join();
        else
            return null
    };

    render(){
        if(this.props.rowSelected !== null && this.props.selectable)
            return(
                <Menu compact={true}
                      borderless={true}
                      stackable={true}
                      size={'small'}
                      className={'ab-menu'}>
                    {this.getEditRowMenuItem()}
                    {this.getCustomMenuItems()}
                    {this.getDeleteRowMenuItem()}
                </Menu>
            );
        else
            return null
    }

}

export default TableActionBar;