import * as React from 'react';
import {Tab,} from "semantic-ui-react";
import PageService from "../../../../services/page-service";
import PageGroupService, {getPageGroups} from "../../../../services/page-group-service";
import './index.css'
import PageGroupChildren from "./PageGroupChildren";
import PageChildren from "./PageChildren";
import {DropDownOption, PageGroup} from "../../../../models/models";


interface PageProps {
    pageClient: PageService;
    pageGroupClient: PageGroupService;
    parentPageGroupId: any
    history: any
    match: any
    userCookie: any
}

interface PageState {
    pageGroups: PageGroup[]
    pageGroupOptions: DropDownOption[]
}

export default class ChildrenTab extends React.Component<PageProps, PageState> {
    constructor(props: PageProps, state: PageState) {
        super(props, state);

        this.state = {
            pageGroups: [],
            pageGroupOptions: [],
        }
    }

    componentDidMount() {
        this.getParentPageGroups()
    }

    //fetches all possible page group and sets the parent page group dropdown
    getParentPageGroups = () => {
       getPageGroups(this.props.userCookie)
            .then((response: PageGroup[]) => {
                let getSlug = (slug?: string) => {
                    if(slug === null || slug === '' || slug === undefined)
                        return 'none';
                    else
                        return slug};
                this.setState({pageGroups: response});

                const pageGroups = response.map(pageGroup => {
                    return {key: pageGroup.id.toString(), text: pageGroup.name + ' - '+getSlug(pageGroup.slug), value: pageGroup.id}
                });


                pageGroups.unshift({key: '0', text: 'Select Parent Group...', value: 0});
                this.setState({pageGroupOptions: pageGroups})
            } )
    };

    compareChildren = (a: any, b: any) => {
        if (a.sortOrder !== null && b.sortOrder !== null)
        {
            if(a.sortOrder > b.sortOrder)
                return 1
            if(a.sortOrder < b.sortOrder)
                return -1
            if(a.sortOrder === b.sortOrder)
            {
                if(a.name > b.name)
                    return 1
                if(a.name < b.name)
                    return -1
            }
        }
        if (a.sortOrder == null && b.sortOrder !== null)
            return -1
        if(a.sortOrder !== null && b.sortOrder == null)
            return 1
        return 0;
    }


    render(){
        return(
            <Tab.Pane>
                <PageGroupChildren
                    history={this.props.history}
                    parentPageGroupId={this.props.parentPageGroupId}
                    pageGroupOptions={this.state.pageGroupOptions}
                    compareChildren={this.compareChildren}
                    pageGroups={this.state.pageGroups}
                    pageGroupClient={this.props.pageGroupClient}/>
                <PageChildren
                    pageClient={this.props.pageClient}
                    parentPageGroupId={this.props.parentPageGroupId}
                    compareChildren={this.compareChildren}
                    pageGroupOptions={this.state.pageGroupOptions}
                    pageGroups={this.state.pageGroups}
                    history={this.props.history}/>
            </Tab.Pane>
        )
    }
}