import * as React from 'react';
import './index.css'
import {Page, PageConfig, PortalProperties, Principal} from "../../../../models/models";
import {Button, Segment, Divider, Dimmer, Loader, Grid, GridColumn, GridRow, Icon} from "semantic-ui-react";
import FileDownload from 'js-file-download';
import ChartService from "../../../../services/chart-service";
import moment from "moment/moment";
import parse from 'html-react-parser';
import {emptyPage} from "../../../../util/constants";
import {chartPcValueToConfigValue, getChartComponent} from "../../../../services/static-service";
import {getPageBreadcrumb} from "../../../../util/functions";
import {ToastComponent} from "../../../Common/ToastComponent";
import ReactGA from "react-ga4";

interface PageProps {
    pageConfig: PageConfig
    userCookie: any
    darkTheme: boolean
    portalProperties: PortalProperties;
    userPrincipal: Principal
}

interface PageState {
    chartLoading: boolean
    lastModified: Date
    page: Page
    summaryHeight: number
}

export default class StaticPage extends React.Component<PageProps, PageState> {
    private readonly chartService: ChartService;
    ref: any
    constructor(props: PageProps, state: PageState) {
        super(props, state);

        this.chartService = new ChartService(this.props.userCookie)
        this.ref = React.createRef()

        this.state = {
            chartLoading: true,
            lastModified: new Date(0),
            page: emptyPage,
            summaryHeight: 40,
        }
    }

    componentDidMount() {
        if (this.props.pageConfig.page !== undefined)
        {
            ReactGA.event({category: "user_page_view", action: "View "+ this.props.pageConfig.page.title +" Static Page"});
            this.setState({page: this.props.pageConfig.page})
        }

    }

    componentDidUpdate(prevProps: Readonly<PageProps>, prevState: Readonly<PageState>, snapshot?: any) {
        if (this.state.page.summary !== prevState.page.summary)
            this.setState({summaryHeight: this.ref.current.clientHeight+14})
    }

    updateChartLoading = (loading: boolean) => {
        this.setState({chartLoading: loading})
    }

    updateLastModified = (lastModified: Date) => {
        if (lastModified > this.state.lastModified)
            this.setState({lastModified: lastModified})
    }

    getFileDownload = (charName: string) => {
        this.chartService.getChartDownload(charName).then(response => {
            FileDownload(response, charName+'.csv')
        })
    }

    getRefreshButtonWording = () => {
        let pieChartList = [
            'weis-gen-mix',
            'generation-mix']

        if (pieChartList.includes(this.props.pageConfig.pcValue)) {
            return 'refresh'.toUpperCase()
        }
        else
            return 'reset zoom'.toUpperCase()
    }

    getDownloadButton = () => {
        if (this.props.pageConfig.pcValue === 'ace-chart')
            return (
                <div id={'static-dl-btn-gp'}>
                    <Grid.Row className={'download-button-row'}>
                        <Button
                            onClick={() => this.getFileDownload(chartPcValueToConfigValue(this.props.pageConfig.pcValue))}
                            className={this.props.darkTheme ? ' dark' : ''}
                            content={'DOWNLOAD IN .CSV FORMAT'.toUpperCase()}/>
                    </Grid.Row>
                    <Grid.Row className={'download-button-row'}>
                        <Button
                            onClick={() => this.getFileDownload('ace-1min')}
                            className={this.props.darkTheme ? ' dark' : ''}
                            content={'Download Current Year 1-Minute Averages in .CSV format'.toUpperCase()}/>
                    </Grid.Row>
                    <Grid.Row className={'download-button-row'}>
                        <Button
                            onClick={() => this.getFileDownload('ace-10min')}
                            className={this.props.darkTheme ? ' dark' : ''}
                            content={'Download Current Year 10-Minute Averages in .CSV format'.toUpperCase()}/>
                    </Grid.Row>
                    <Grid.Row className={'download-button-row'}>
                        <Button
                            onClick={() => this.getFileDownload('ace-prev-yr-1min')}
                            className={this.props.darkTheme ? ' dark' : ''}
                            content={'Download Prior Year 1-Minute Averages in .CSV format'.toUpperCase()}/>
                    </Grid.Row>
                    <Grid.Row className={'download-button-row'}>
                        <Button
                            onClick={() => this.getFileDownload('ace-prev-yr-10min')}
                            className={this.props.darkTheme ? ' dark' : ''}
                            content={'Download Prior Year 10-Minute Averages in .CSV format'.toUpperCase()}/>
                    </Grid.Row>
                </div>)
        else
            return <Button
                onClick={() => this.getFileDownload(chartPcValueToConfigValue(this.props.pageConfig.pcValue))}
                id={'static-dl-btn'}
                className={this.props.darkTheme ? ' dark' : ''}
                content={'DOWNLOAD IN .CSV FORMAT'.toUpperCase()}/>

    }

    render(){
        return(
            <Grid>
                <ToastComponent/>
                <GridRow stretched={true}>
                    <Grid.Column id={'static-chart-column'} className={this.props.darkTheme? ' dark' : ''}>
                        <div id={'breadcrumb'}>
                            {getPageBreadcrumb(
                                this.state.page.breadcrumb? this.state.page.breadcrumb : [],
                                this.props.portalProperties.uaaHost)}
                        </div>
                        <Segment.Group>
                            <Segment
                                content={this.state.page.title +' for '+ moment(this.state.lastModified).format('YYYY-MM-DD HH:mm:ss')+ ' (Central Time)'}
                                className={'chart-title-sg'}/>
                            <Segment id={'static-body-sg'}>
                                <GridColumn>
                                    <GridRow>
                                        <div ref={this.ref}>
                                            {parse(this.state.page.summary)}
                                            <Divider/>
                                        </div>
                                        <Dimmer active={this.state.chartLoading} inverted={!this.props.darkTheme}>
                                            <Loader/>
                                        </Dimmer>
                                    </GridRow>
                                    <GridRow id={'static-chart-row'}>
                                        {getChartComponent(
                                            this.props.pageConfig.pcValue,
                                            this.updateLastModified,
                                            this.chartService,
                                            this.state.chartLoading,
                                            this.props.darkTheme,
                                            this.updateChartLoading,
                                            true)}
                                    </GridRow>
                                </GridColumn>
                            </Segment>
                            <Button className={'narrow-btn graph-btn'}
                                    onClick={() => {this.setState({chartLoading: true})}}
                                    attached={'bottom'}
                                    color={'grey'}>
                                {this.getRefreshButtonWording()}
                                <Icon className={'see-more-arrow'} name={'redo'}/>
                            </Button>
                        </Segment.Group>
                    </Grid.Column>
                </GridRow>
                <GridRow>
                    <GridColumn textAlign={'center'} verticalAlign={'middle'}>
                        {this.getDownloadButton()}
                    </GridColumn>
                </GridRow>
            </Grid>
        )
    }
}