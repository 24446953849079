import * as React from 'react';
import {Button, Grid, Icon, Menu, Segment} from "semantic-ui-react";
import './index.css'
import {ActionResponse, FileSource, Page, PageConfig, PortalProperties,} from "../../../models/models";
import {emptyPageConfig, pageTypeKey} from "../../../util/constants";
import toast from "../../../util/toast";
import PageService from "../../../services/page-service";
import {info} from "../../../services/route-service";
import FileBrowserService from "../../../services/file-browser-service";
import PageConfigService from "../../../services/page-config-service";
import SppModal from "../../Common/SppModal";
import PageForm from "../../Common/PageForm";

interface PageProps {
    userCookie: any
    history: any
    match: any
    portalProperties: PortalProperties
}

interface PageState {
    description: any
    pageConfig: PageConfig
    formLoading: boolean
    buttonLoading: boolean
    showEmbeddedTitle: boolean
    fsPath: any
    selectLoading: boolean
    originalPageFileSource: any
    uaaAppRole: string
    modalOpen: boolean
    slug: string
    uaaApplication: any
    allowInUserDashboard: boolean
    tableauCategory: string
    tableauViewName: string
}

export default class EditPage extends React.Component<PageProps, PageState> {
    private pageClient: PageService;
    private fileBrowserClient: FileBrowserService;
    private pageConfigClient: PageConfigService;

    constructor(props: PageProps, state: PageState) {
        super(props, state);

        this.state = {
            description: '',
            pageConfig: emptyPageConfig,
            formLoading: false,
            buttonLoading: false,
            showEmbeddedTitle: true,
            fsPath: '',
            selectLoading: false,
            originalPageFileSource: {fsSecurity: null, fsPath: '', fsName: '', id: 0},
            uaaAppRole: '',
            modalOpen: false,
            slug: '',
            uaaApplication: '',
            allowInUserDashboard: true,
            tableauCategory: '',
            tableauViewName: ''
        };

        this.pageClient = new PageService(this.props.userCookie);
        this.fileBrowserClient = new FileBrowserService(this.props.userCookie);
        this.pageConfigClient = new PageConfigService(this.props.userCookie);
    }

    componentDidMount(): void {
        this.getPageConfigs();
    }

    //returns page config and page object based on path page Id
    getPageConfigs = () => {
        this.setState({formLoading: true});
        this.pageConfigClient.getPageConfigs().then(response => {

            let getPageId = (page: any) => {
                if (page === undefined)
                    return 0;
                else return page.id.toString()
            };

            let pageConfigs = response.filter(config => getPageId(config.page) === this.props.match.params.pageId );

            if (pageConfigs.length === 1)
                this.setState({pageConfig: pageConfigs[0]});

            else if (pageConfigs.length > 1)
            {
                let config = pageConfigs.find(config => (config.pcKey === pageTypeKey.FILE_BROWSER) || config.pcKey === pageTypeKey.EMBEDDED);
                if (config !== undefined)
                    this.setState({pageConfig: config});

                let fbDescription = pageConfigs.find(config => config.pcKey === pageTypeKey.FB_DESCRIPTION);
                if (fbDescription !== undefined)
                    this.setState({description: fbDescription.pcValue})

                let embeddedTitle = pageConfigs.find(config => config.pcKey === pageTypeKey.EMBEDDED_TITLE);
                if (embeddedTitle !== undefined)
                    this.setState({showEmbeddedTitle: embeddedTitle.pcValue.toLowerCase() === 'true'})

                let allowDashboard = pageConfigs.find(config => config.pcKey === pageTypeKey.ALLOW_DASHBOARD);
                if (allowDashboard !== undefined)
                    this.setState({allowInUserDashboard: allowDashboard.pcValue.toLowerCase() === 'true'})

                let tableauCategory = pageConfigs.find(config => config.pcKey === pageTypeKey.TABLEAU_CATEGORY);
                if (tableauCategory !== undefined)
                    this.setState({tableauCategory: tableauCategory.pcValue})

                let tableauViewName = pageConfigs.find(config => config.pcKey === pageTypeKey.TABLEAU_VIEW_NAME);
                if (tableauViewName !== undefined)
                    this.setState({tableauViewName: tableauViewName.pcValue})
            }

            if(pageConfigs.length > 0)
                if(pageConfigs[0].page !== undefined)
                    this.getFileSource(pageConfigs[0].page);
        })
    };

    //returns fileSource and FsSecurity for page
    getFileSource = (page: Page) => {
        if(page.pageType.name === 'file-browser')
        {
            this.fileBrowserClient.getFileSourceBySlug(page.slug).then(response => {
                this.setState({formLoading: false})
                if (response !== undefined)
                {
                    if (response.fsSecurity !== null && response.fsSecurity !== undefined)
                    {
                        if (response.fsSecurity.authzRole !== null)
                            this.setState({uaaAppRole: response.fsSecurity.authzRole});
                    }
                    this.setState({fsPath: response.fsPath, originalPageFileSource: response});
                }
            })
        }
        else
            this.setState({formLoading: false})
    };

    //if file-browser deletes file source and fsSecurity, then edits page and recreates file browser if necessary
    handleEditPage = (page: Page, pageRequest: any, uaaApp: any) => {
        this.setState({buttonLoading: true});
        if (page.pageType.name === 'file-browser' && this.state.originalPageFileSource !== '')
        {
            this.fileBrowserClient.deleteFileSource(this.state.originalPageFileSource.id).then(response =>{
                if (response.ok)
                {
                    if (this.state.originalPageFileSource.fsSecurity !== null)
                    {
                        this.fileBrowserClient.deleteFsSecurity(this.state.originalPageFileSource.fsSecurity.id).then(response =>{
                            if (response.ok)
                            {
                                this.updatePage(pageRequest, uaaApp);
                                this.setState({originalPageFileSource: {fsSecurity: null, fsPath: '', fsName: '', id: 0}})
                            }
                            else
                            {
                                toast.error(response.message);
                                this.setState({formLoading: false, buttonLoading: false});
                                return;
                            }
                        })
                    }
                    else
                    {
                        this.updatePage(pageRequest, uaaApp);
                        this.setState({originalPageFileSource: {fsSecurity: null, fsPath: '', fsName: '', id: 0}})
                    }
                }
                else
                {
                    toast.error(response.message);
                    this.setState({formLoading: false, buttonLoading: false});
                    return;
                }
            })
        }
        else
            this.updatePage(pageRequest, uaaApp)
    };

    //called the update page axios method
    updatePage = (pageRequest: any, uaaApp: any) => {
        this.pageClient.updateFullPage(pageRequest).then((response: ActionResponse<any>) => {
                this.setState({buttonLoading: false});
                if (response.ok && response.item !== undefined)
                {
                    if (response.item.pageType.name === 'file-browser')
                        this.createFileBrowser(response.item, uaaApp);

                    this.getPageConfigs();
                    toast.success('Page Updated.')
                }
                else
                {
                    this.setState({formLoading: false});
                    toast.error(response.message);
                }
            })
    };

    //creates file browser file source and fs security
    createFileBrowser = (page: Page, uaaApp: any) => {
        if (this.getFsSecurity(uaaApp) !== null)
        {
            this.fileBrowserClient.createFsSecurity(this.getFsSecurity(uaaApp)).then(response => {
                if (response.ok && response.item !== undefined)
                {
                    let newFileSource: FileSource = {
                        id:0,
                        fsName: this.state.slug,
                        fsPath: this.state.fsPath,
                        fsSecurity: response.item
                    };

                    this.fileBrowserClient.createFileSource(newFileSource).then(response => {
                        if (response.ok)
                        {
                            this.getFileSource(page);
                        }
                        else
                        {
                            toast.error(response.message);
                            return;
                        }
                    })
                }
            })
        }
        else
        {
            let newFileSource: FileSource = {
                id:0,
                fsName: this.state.slug,
                fsPath: this.state.fsPath,
                fsSecurity: null
            };
            this.fileBrowserClient.createFileSource(newFileSource).then(response => {
                if (response.ok)
                {
                    this.getFileSource(page);
                }
                else
                {
                    toast.error(response.message);
                    return;
                }
            })
        }
    };

    //returns FsSecurity object
    getFsSecurity = (uaaApp: any) => {
        if (this.state.uaaApplication === '' || this.state.uaaApplication === 0)
            return null;
        else
        {
            let getUaaAppRole = () => {
                if (this.state.uaaAppRole === '')
                    return null;
                else
                    return this.state.uaaAppRole
            };

            if (uaaApp !== undefined)
                return {authzRole: getUaaAppRole(), authzAppKey: uaaApp.key}

            return null
        }
    };

    //opens delete page modal and sets modal type
    deleteRowOnClick = () => {
        this.setState({ modalOpen: true})
    };

    //makes delete page axios call and returns action response. closes modal if successful
    handleDeleteSubmit = () => {
        this.setState({buttonLoading: true});
        this.pageClient.getPage(this.props.match.params.pageId).then(response => {
            this.pageClient.deletePage(response)
                .then(response => {
                    this.setState({ buttonLoading: false});
                    if (response.ok) {
                        this.props.history.push(info.managePages.path);
                    } else
                        toast.error(response.message);
                })
        })
    };

    //returns modal content for delete modal
    getModalContent = () => {
        return(
            {
                iconName: 'file excel outline',
                modalTitle: 'Delete Page',
                onClose: this.closeModal,
                modalBody:  <Grid id={'conf-info-content'}>
                    <Grid.Row>
                        <Grid.Column>
                            Are you sure you want to delete page titled: {this.state.pageConfig.page? this.state.pageConfig.page.title : ''}?
                        </Grid.Column>
                    </Grid.Row>
                    <Grid.Row>
                        <Grid.Column textAlign={'right'}>
                            <Button className={'modal-action-button'}
                                    onClick={this.closeModal}
                                    color={'red'}
                                    loading={this.state.buttonLoading}
                                    id={'cm-negative-button'}
                                    content={'NO'}/>
                            <Button onClick={this.handleDeleteSubmit}
                                    color={'green'}
                                    loading={this.state.buttonLoading}
                                    id={'cm-positive-button'}
                                    className={'modal-action-button'}
                                    content={'YES'}/>
                        </Grid.Column>
                    </Grid.Row>
                </Grid>,
            }
        )
    };

    //this set of set methods are allowing child to update parent states
    setConfig = (pageConfig: PageConfig) => {
        this.setState({pageConfig: pageConfig})
    }

    setDescription = (description: string) => {
        this.setState({description: description})
    }

    setTableauCategory = (tableauCategory: string) => {
        this.setState({tableauCategory: tableauCategory})
    }

    setTableauViewName = (tableauViewName: string) => {
        this.setState({tableauViewName: tableauViewName})
    }

    setShowTitle = (showTitle: boolean) => {
        this.setState({showEmbeddedTitle: showTitle})
    }

    setAllowInDashboard = (allowInDashboard: boolean) => {
        this.setState({allowInUserDashboard: allowInDashboard})
    }

    setFsPath = (fsPath: string) => {
        this.setState({fsPath: fsPath})
    }

    setSlug = (slug: string) => {
        this.setState({slug: slug})
    }

    setUaaApplication = (uaaApplication: any) => {
        this.setState({uaaApplication: uaaApplication})
    }

    setUaaAppRole = (uaaAppRole: any) => {
        this.setState({uaaAppRole: uaaAppRole})
    }

    closeModal = () => {
        this.setState({modalOpen: false})
    };

    render(){
        return(
            <Grid>
                <Grid.Row columns={1}>
                    <Grid.Column>
                        <Segment.Group id={'cp-segment-group'}>
                            <Segment id={'cp-segment-header'}>
                                <Grid>
                                    <Grid.Row columns={2}>
                                        <Grid.Column
                                            textAlign={'left'}
                                            width={6}
                                            verticalAlign={'middle'}>
                                            <Icon
                                                name={'edit outline'}
                                                size={'large'}/>
                                            Edit Page: {this.state.pageConfig.page? this.state.pageConfig.page.title : ''}
                                        </Grid.Column>
                                        <Grid.Column textAlign={'right'} width={10}>
                                            <Menu
                                                compact={true}
                                                borderless={true}
                                                stackable={true}
                                                className={'ab-menu'}
                                                size={'small'}>
                                                <Menu.Item
                                                    onClick={this.deleteRowOnClick}
                                                    id={'aab-delete-click'}
                                                    className={'ab-menu-item'}
                                                    content={'Delete'}/>
                                            </Menu>
                                        </Grid.Column>
                                    </Grid.Row>
                                </Grid>
                            </Segment>
                            <Segment>
                                <PageForm userCookie={this.props.userCookie}
                                          history={this.props.history}
                                          match={this.props.match}
                                          pageConfig={this.state.pageConfig}
                                          submitForm={this.handleEditPage}
                                          setConfig={this.setConfig}
                                          setDescription={this.setDescription}
                                          setShowTitle={this.setShowTitle}
                                          setAllowInDashboard={this.setAllowInDashboard}
                                          setFsPath={this.setFsPath}
                                          slug={this.state.slug}
                                          setSlug={this.setSlug}
                                          setUaaApplication={this.setUaaApplication}
                                          setUaaAppRole={this.setUaaAppRole}
                                          uaaApplication={this.state.uaaApplication}
                                          description={this.state.description}
                                          portalProperties={this.props.portalProperties}
                                          showEmbeddedTitle={this.state.showEmbeddedTitle}
                                          allowInDashboard={this.state.allowInUserDashboard}
                                          tableauCategory={this.state.tableauCategory}
                                          setTableauCategory={this.setTableauCategory}
                                          tableauViewName={this.state.tableauViewName}
                                          setTableauViewName={this.setTableauViewName}
                                          fsPath={this.state.fsPath}
                                          originalPageFileSource={this.state.originalPageFileSource}
                                          uaaAppRole={this.state.uaaAppRole}
                                          formLoading={this.state.formLoading}
                                          buttonLoading={this.state.buttonLoading}/>
                            </Segment>
                        </Segment.Group>
                    </Grid.Column>
                </Grid.Row>
                <SppModal
                    open={this.state.modalOpen}
                    modalContent={this.getModalContent()}/>
            </Grid>
        )
    }
}
