import * as React from 'react';
import './index.css'
import {Button} from "semantic-ui-react";

interface PageProps {
    visible: boolean
    createButton: boolean
    downloadButton: boolean
    refreshButton: boolean
    handleTableRefresh?: Function
    handleCreate?: Function
    handleDownload?: Function
    customButtons?: any[]

}

interface PageState {}

class TableButtonGroup extends React.Component<PageProps, PageState> {
    constructor(props: PageProps, state: PageState){
        super(props, state);

        this.state = {}
    }

    getCreateButton = () => {
        if(this.props.createButton)
            return(
                <Button icon={'add'}
                        title={'Create New Row'}
                        id={'tbg-create-button'}
                        onClick={this.handleOnCreateClick}
                />
            );
        else
            return null;
    };

    handleOnCreateClick = () => {
        if(this.props.handleCreate !== undefined)
            this.props.handleCreate();
        else
            return
    };

    getRefreshButton = () => {
        if(this.props.refreshButton)
            return(
                <Button icon={'refresh'}
                        title={'Refresh Table'}
                        id={'tbg-refresh-button'}
                        onClick={this.handleOnRefreshClick}
                />
            );
        else
            return null;
    };

    handleOnRefreshClick = () => {
        if(this.props.handleTableRefresh !== undefined)
            this.props.handleTableRefresh();
        else
            return
    };

    getDownloadButton = () => {
        if(this.props.downloadButton)
            return(
                <Button icon={'download'}
                        title={'Download Table'}
                        id={'tbg-download-button'}
                        onClick={this.handleOnDownloadClick}
                />
            );
        else
            return null;
    };

    handleOnDownloadClick = () => {
        if(this.props.handleDownload !== undefined)
            this.props.handleDownload();
        else
            return
    };

    getCustomButtons = () => {
        if(this.props.customButtons !== undefined)
            return this.props.customButtons.join();
        else
            return null
    };

    render(){
        if(!this.props.visible)
            return null;
        else
            return(
                <div>
                    {this.getCreateButton()}
                    {this.getRefreshButton()}
                    {this.getDownloadButton()}
                    {this.getCustomButtons()}
                </div>
            )
    }
}

export default TableButtonGroup;