import AxiosService from "./axios-service";
import { UaaApplication, UaaAppRole} from "../models/models";

export default class UAAService {
    private client: AxiosService;

    constructor(csrfToken: string) {
        this.client = new AxiosService(csrfToken);
    }

    getUaaApplications = (): Promise<UaaApplication[]> => {
        const url = `/uaa/application`;
        return new Promise<UaaApplication[]>(((resolve) => {
            this.client.getAxiosInstance().get(url)
                .then(({data}) => {resolve(data)})
                .catch(() => {resolve([])})
        }));
    };

    getUaaApplicationRoles = (appId: number): Promise<UaaAppRole[]> => {
        const url = `/uaa/application/${appId}/role`;
        return new Promise<UaaAppRole[]>(((resolve) => {
            this.client.getAxiosInstance().get(url)
                .then(({data}) => {resolve(data)})
                .catch(() => {resolve([])})
        }));
    };
}