import * as React from 'react';
import {Button, DropdownProps, Form, FormCheckboxProps, FormGroup, Grid, InputProps} from "semantic-ui-react";
import {CKEditor} from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import {
    DropDownOption,
    Page,
    PageConfig,
    PageGroup,
    PageType,
    PortalProperties,
    UaaApplication
} from "../../../models/models";
import {emptyPage, emptyPageConfig, emptyPageGroup, emptyPageType, pageTypeKey} from "../../../util/constants";
import UAAService from "../../../services/uaa-services";
import {getPageGroups} from "../../../services/page-group-service";
import PageTypeService from "../../../services/page-type-service";
import {info} from "../../../services/route-service";
import AccessService from "../../../services/access-service";
import {cloneDeep} from "lodash";
import toastMessage from "../../../util/toast";

interface PageProps {
    userCookie: any
    history: any
    match: any
    pageConfig: PageConfig
    submitForm: (page: Page, pageRequest: any, uaaApp: any) => void
    setConfig: (pageConfig: PageConfig) => void
    formLoading: boolean
    buttonLoading: boolean
    description?: any
    showEmbeddedTitle?: boolean
    allowInDashboard?: boolean
    tableauCategory?: string
    tableauViewName?: string
    setTableauCategory?: (tableauCategory: string) => void
    setTableauViewName?: (tableauViewName: string) => void
    setDescription?: (description: string) => void
    setShowTitle?: (showEmbeddedTitle: boolean) => void
    setAllowInDashboard?: (allowInDashboard: boolean) => void
    setFsPath?: (fsPath: string) => void
    setUaaAppRole?: (uaaAppRole: string) => void
    fsPath?: any
    originalPageFileSource?: any
    uaaAppRole?: any
    uaaApplication?: any
    setUaaApplication?: (uaaApplication: any) => void
    slug: string
    setSlug: (slug: string) => void
    portalProperties: PortalProperties
}

interface PageState {
    page: any
    summary: any
    pageType: PageType
    pageTypes: DropDownOption[]
    uaaApplications : DropDownOption[]
    pageGroups: any[]
    pageGroup: PageGroup
    title: string,
    activeFlag: boolean,
    isPublic: boolean,
    fileSource: any
    selectLoading: boolean
    selectedUaaAppRoles: any[]
    appRolesPresent: boolean
    pageConfigs: any[]
    modalOpen: boolean
}

export default class PageForm extends React.Component<PageProps, PageState> {
    private uaaClient: UAAService;
    private pageTypeClient: PageTypeService;
    private accessClient: AccessService;

    constructor(props: PageProps, state: PageState) {
        super(props, state);

        this.state = {
            pageConfigs: [],
            page: emptyPage,
            summary: '',
            pageType: emptyPageType,
            uaaApplications: [],
            pageGroups: [],
            pageGroup: emptyPageGroup,
            pageTypes: [],
            title: '',
            activeFlag: false,
            isPublic: false,
            fileSource: {id: 0, fsName: '', fsPath: '', fsSecurity: null},
            selectLoading: false,
            selectedUaaAppRoles: [],
            appRolesPresent: false,
            modalOpen: false
        };

        this.uaaClient = new UAAService(this.props.userCookie);
        this.pageTypeClient = new PageTypeService(this.props.userCookie);
        this.accessClient = new AccessService(this.props.userCookie);
    }

    componentDidMount(): void {
        this.getUaaApplications();
        this.getPageGroups();
        this.getPageTypes();
        this.getPageAccess();
        if (this.props.pageConfig.page !== undefined)
            this.setPage(this.props.pageConfig.page);
    }

    componentDidUpdate(prevProps: Readonly<PageProps>, prevState: Readonly<PageState>, snapshot?: any): void {
        if (this.props.uaaApplication !== prevProps.uaaApplication &&
            (this.props.uaaApplication !== '' || this.props.uaaApplication !== 0))
            this.getApplicationRoleOptions(this.props.uaaApplication)

        if (this.props.pageConfig !== prevProps.pageConfig)
            if (this.props.pageConfig.page !== undefined)
                this.setPage(this.props.pageConfig.page);

        if (this.props.match !== prevProps.match)
            this.getPageAccess()
    }

    //sets the page related states based on return from get config
    setPage = (page: Page) => {
        let getSummary = (page: any) => {
            if (page.summary === null || page.summary === undefined)
                return '';
            else return page.summary
        };

        this.props.setSlug(page.slug);

        this.setState({
            page: page,
            title: page.title,
            pageGroup: page.pageGroup,
            pageType: page.pageType,
            summary: getSummary(page),
            isPublic: page.isPublic,
            activeFlag: page.activeFlag,
        });
    };

    //fetches all possible UAA applications and sets uaa dropdown options
    getUaaApplications = () => {
        this.uaaClient.getUaaApplications()
            .then((response: UaaApplication[]) => {
                const uaaApplications = response.map(uaaApplication => {
                    return {key: uaaApplication.key, text: uaaApplication.application, value: uaaApplication.id}
                });
                uaaApplications.unshift({key: '0', text: 'Select Application....', value: 0});
                this.setState({uaaApplications: uaaApplications})
            } )
    };

    //fetches all possible page group and sets the page group dropdown
    getPageGroups = () => {
        getPageGroups(this.props.userCookie)
            .then((response: PageGroup[]) => {
                let getSlug = (slug?: string) => {
                    if(slug === undefined || slug === null || slug === '')
                        return 'none';
                    else
                        return slug
                };

                let validPageGroups = response.filter(pageGroup => {
                    return pageGroup.asPage || pageGroup.depth < this.props.portalProperties.maxMenuDepth;
                })

                let pageGroups = validPageGroups.map(pageGroup => {
                    return {key: pageGroup.id.toString(), text: pageGroup.name +' - '+getSlug(pageGroup.slug),
                        value: pageGroup.id, name: pageGroup.name, depth: pageGroup.depth}
                });
                this.setState({pageGroups: pageGroups, pageGroup: {id: pageGroups[0].value,
                        name: pageGroups[0].name, depth: pageGroups[0].depth}})
            } )
    };

    //fetches all possible page types and sets the page type dropdown
    getPageTypes = () => {
        this.pageTypeClient.getPageTypes()
            .then((response: PageType[]) => {
                const pageTypes = response.map(pageType => {
                    return {key: pageType.name, text: pageType.name[0].toUpperCase()+pageType.name.substring(1), value: pageType.id}
                });

                this.setState({pageTypes: pageTypes})

                //if pageConfig is not set for the page, set it to embedded
                if (this.props.pageConfig === emptyPageConfig)
                {
                    this.props.setConfig({pcKey: this.getPageConfigKey(pageTypes[0].key), pcValue: ''})
                    this.setState({pageType: {id: pageTypes[0].value, name: pageTypes[0].key}})
                }
            } )
    };

    //return pcKey based on pageType
    getPageConfigKey = (pageType: string) => {
        switch (pageType) {
            case 'embedded':
                return pageTypeKey.EMBEDDED;
            case 'external':
                return pageTypeKey.EXTERNAL;
            case 'file-browser':
                return pageTypeKey.FILE_BROWSER;
            case 'static':
                return pageTypeKey.STATIC;
            case 'tableau':
                return pageTypeKey.TABLEAU;
            default: return '';
        }
    };

    //returns page access
    getPageAccess = () => {
        if (this.props.match.params.pageId !== undefined)
            this.accessClient.getAccessByPage(this.props.match.params.pageId).then(response => {
                if (response.id !== undefined && this.props.setUaaApplication)
                    this.props.setUaaApplication( response.authzAppId)
            })
    };

    //sets the form input anc checkbox states based on value and form field name
    handleInputAndCheckboxChange = (event: any, {name, value, checked}: InputProps | FormCheckboxProps) => {
        switch (name) {
            case 'title':
                this.setState({title: value});
                if (this.props.setSlug)
                    this.props.setSlug((value.replace(/ /g, '-')).toLowerCase())
                break;
            case 'slug':
                if (this.props.setSlug)
                    this.props.setSlug((value.replace(/ /g, '-')).toLowerCase())
                break;
            case 'activeFlag':
                this.setState({activeFlag: checked});
                break;
            case 'isPublic':
                this.setState({isPublic: checked});
                break;
            case 'showEmbeddedTitle':
                if (this.props.setShowTitle)
                    this.props.setShowTitle(checked);
                break;
            case 'allowInCustomDashboards':
                if (this.props.setAllowInDashboard)
                    this.props.setAllowInDashboard(checked);
                break;
            case 'tableauCategory':
                if (this.props.setTableauCategory)
                    this.props.setTableauCategory(value)
                break;
            case 'tableauViewName':
                if (this.props.setTableauViewName)
                    this.props.setTableauViewName(value)
                break;
            case 'pcValue':
                this.props.setConfig({pcKey: this.getPageConfigKey(this.state.pageType.name), pcValue: value})
                break;
            case 'fsPath':
                if (this.props.setFsPath)
                this.props.setFsPath(value);
        }
    };

    //sets the summary value state from the ckEditor rich text box
    handleSummaryOnBlur = (event: any, editor: any) => {
        this.setState({summary: editor.getData()})
    };

    //sets the description value state from the ckEditor rich text box
    handleDescriptionOnBlur = (event: any, editor: any) => {
        if (this.props.setDescription)
            this.props.setDescription(editor.getData())
    };

    //sets the UAA Application value based on dropdown selection
    handleUaaApplicationChange = (e: any, {value}: DropdownProps) =>{
        if (this.props.setUaaAppRole)
            this.props.setUaaAppRole('');
        this.setState({selectLoading: true, appRolesPresent: false});
        if (value !== 0)
        {
            this.getApplicationRoleOptions(parseInt((value || '').toString()));
            if (this.props.setUaaApplication)
                this.props.setUaaApplication(parseInt((value || '').toString()))
        }
        else
        if (this.props.setUaaApplication)
            this.props.setUaaApplication('')
    };

    //returns all the uaa role options for file browser based on page access
    getApplicationRoleOptions = (appId: number) => {
        this.uaaClient.getUaaApplicationRoles(appId).then(response => {
                this.setState({selectLoading: false});
                if (response.length > 0)
                {
                    let roleOptions = response.map(role => {
                        return{key: role.id, value: role.role, text: role.name}
                    });
                    roleOptions.unshift({key: 0, value: '', text: 'Select Role for File-Browser Access'});
                    this.setState({selectedUaaAppRoles: roleOptions, appRolesPresent: true})
                }

            }
        );
    };

    //sets state for file browser FsSecurity role
    handleUaaAppRoleChange = (e: any, {value}: DropdownProps) => {
        if (this.props.setUaaAppRole)
            this.props.setUaaAppRole((value || '').toString())
    };

    //sets value states based on dropdown selection
    handleSelectChange = (e: any, {name, value, options}: DropdownProps) => {
        let selectedOption;

        if (options !== undefined)
            selectedOption = options.find(o => o.value === value);

        if (selectedOption !== undefined)
        {
            switch (name) {
                case 'pageGroup':
                    if (selectedOption.value !== undefined && (selectedOption.text !== undefined && selectedOption.text !== null))
                        this.setState({pageGroup: {id: parseInt(selectedOption.value.toString()), name: selectedOption.name, depth: selectedOption.depth}});
                    break;
                case 'pageType':
                    if (selectedOption.value !== undefined )
                    {
                        this.props.setConfig({pcKey: this.getPageConfigKey(selectedOption.key.toString()), pcValue: ''})
                        this.setState({
                            pageType: {id: parseInt(selectedOption.value.toString()), name: selectedOption.key.toString()},
                        });
                    }
                    break;
            }
        }
    };

    //returns form inputs based on page type for pcValue
    getPageConfigInput = () => {
        switch (this.state.pageType.name) {
            case ('embedded'):
                return (
                    <Form.Input label={'Embedded Url'}
                                required={true}
                                name={'pcValue'}
                                value={this.props.pageConfig.pcValue}
                                onChange={this.handleInputAndCheckboxChange}
                                placeholder={'Page Url'}/>
                );
            case ('external'):
                return (
                    <Form.Input label={'External Url'}
                                required={true}
                                name={'pcValue'}
                                value={this.props.pageConfig.pcValue}
                                onChange={this.handleInputAndCheckboxChange}
                                placeholder={'Page Url'}/>
                );
            case ('static'):
                return (
                    <Form.Input label={'Static Page View Name'}
                                required={true}
                                name={'pcValue'}
                                value={this.props.pageConfig.pcValue}
                                onChange={this.handleInputAndCheckboxChange}
                                placeholder={'Static Page Name'}/>
                );
            case ('tableau'):
                return (
                    <Form.Input label={'Tableau View URL'}
                                required={true}
                                name={'pcValue'}
                                value={this.props.pageConfig.pcValue}
                                onChange={this.handleInputAndCheckboxChange}
                                placeholder={'Tableau View URL'}/>
                );
            case ('file-browser'):
                return (
                    <Form.Input label={'File Source Path'}
                                required={true}
                                name={'fsPath'}
                                value={this.props.fsPath}
                                onChange={this.handleInputAndCheckboxChange}
                                placeholder={'File Source Path'}/>
                );
            default: return null

        }
    };

    //returns role dropdown only if an application is selected
    getAppRoleDropdown = () => {
        if (this.props.uaaApplication !== '' && this.state.pageType.name === 'file-browser' && this.state.appRolesPresent)
            return(
                <Form.Select
                    label={'Uaa Application Role for File-Browser Permission'}
                    name={'appRole'}
                    placeholder={'Select Role for File Browser Access'}
                    loading={this.state.selectLoading}
                    value={this.props.uaaAppRole}
                    onChange={this.handleUaaAppRoleChange}
                    options={this.state.selectedUaaAppRoles}/>
            );
        else
            return null
    };

    //returns description input for file browser
    getDescription = () => {
        if (this.state.pageType.name === 'file-browser')
            return (
                <Form.Field>
                    <label>Description</label>
                    <CKEditor
                        editor={ClassicEditor}
                        data={this.props.description}
                        onBlur={this.handleDescriptionOnBlur}/>
                </Form.Field>
            );
        else return false;
    };

    getAllowInCustomDashboards = () => {
        if (this.state.pageType.name === 'tableau')
            return (
                <Form.Checkbox label='Allow in User Custom Dashboard'
                               checked={this.props.allowInDashboard}
                               onChange={this.handleInputAndCheckboxChange}
                               name={'allowInCustomDashboards'} />
            );
        else return false;
    }

    getTableauInputs = () => {
        if (this.state.pageType.name === 'tableau' && this.props.allowInDashboard)
            return (
                <FormGroup widths={'equal'}>
                    <Form.Input label={'Tableau Dashboard Category'}
                                required={true}
                                name={'tableauCategory'}
                                value={this.props.tableauCategory}
                                onChange={this.handleInputAndCheckboxChange}
                                placeholder={'Tableau Dashboard Category'}/>
                    <Form.Input label={'Tableau View Name'}
                                required={true}
                                name={'tableauViewName'}
                                value={this.props.tableauViewName}
                                onChange={this.handleInputAndCheckboxChange}
                                placeholder={'Tableau View Name'}/>
                </FormGroup>

            );
        else return false;
    }

    getShowEmbeddedTitle = () => {
        if (this.state.pageType.name === 'embedded')
            return (
                <Form.Checkbox label='Show Page Title'
                               checked={this.props.showEmbeddedTitle}
                               onChange={this.handleInputAndCheckboxChange}
                               name={'showEmbeddedTitle'} />
            );
        else return false;
    }

    //if file-browser deletes file source and fsSecurity, then edits page and recreates file browser if necessary
    handleSubmit = () => {
        let pageRequest = this.getPageRequest();

        if (pageRequest.page.title === pageRequest.page.pageGroup.name)
        {
            toastMessage.error('Page can not have same name as Page Group')
            return
        }

        let uaaApp = this.state.uaaApplications.find(app => app.value === this.props.uaaApplication);
        this.props.submitForm(this.state.page, pageRequest, uaaApp)
    };

    //creates the Page Request object for update page calls
    getPageRequest = (): any => {
        let getPage = () => {
            return {
                id: this.state.page.id,
                title: this.state.title,
                slug: this.props.slug,
                activeFlag: this.state.activeFlag,
                isPublic: this.state.isPublic,
                summary: this.state.summary,
                pageType: this.state.pageType,
                pageGroup: this.state.pageGroup
            }
        };

        let getUaaApplication = (uaaApp: any) => {
            if (uaaApp === 0 || uaaApp === '')
                return null;
            else return uaaApp
        };

        let getPageConfig = () => {
            if (this.state.pageType.name === 'file-browser')
            {
                let pageConfig = [{pcKey: pageTypeKey.FILE_BROWSER, pcValue: this.props.slug}];
                if (this.props.description !== '' )
                    pageConfig.push(
                        {
                            pcKey: pageTypeKey.FB_DESCRIPTION,
                            pcValue: this.props.description
                        });
                return pageConfig
            }
            else if (this.state.pageType.name === 'embedded')
            {
                let pageConfig = [cloneDeep(this.props.pageConfig)]
                pageConfig.push(
                    {
                        pcKey: pageTypeKey.EMBEDDED_TITLE,
                        pcValue: this.props.showEmbeddedTitle !== undefined? this.props.showEmbeddedTitle.toString() : 'true'
                    });
                return pageConfig
            }

            else if (this.state.pageType.name === 'tableau')
            {
                let pageConfig = [cloneDeep(this.props.pageConfig)]
                pageConfig.push(
                    {
                        pcKey: pageTypeKey.ALLOW_DASHBOARD,
                        pcValue: this.props.allowInDashboard !== undefined? this.props.allowInDashboard.toString() : 'true'
                    });
                if (this.props.tableauCategory !== '' &&  this.props.tableauCategory !== undefined)
                    pageConfig.push(
                        {
                            pcKey: pageTypeKey.TABLEAU_CATEGORY,
                            pcValue: this.props.tableauCategory
                        });
                if (this.props.tableauViewName !== '' && this.props.tableauViewName !== undefined )
                    pageConfig.push(
                        {
                            pcKey: pageTypeKey.TABLEAU_VIEW_NAME,
                            pcValue: this.props.tableauViewName
                        });
                return pageConfig
            }

            else return [this.props.pageConfig]
        };

        return {
            pageConfigs: getPageConfig(),
            authzAppId: getUaaApplication(this.props.uaaApplication),
            page: getPage()
        }
    };

    render(){
        return(
            <Form onSubmit={this.handleSubmit} loading={this.props.formLoading}>
                <Form.Input
                    label={'Name'}
                    name={'title'}
                    value={this.state.title}
                    onChange={this.handleInputAndCheckboxChange}
                    required={true}
                    placeholder={'Page Name'}/>
                <Form.Input
                    label={'Slug'}
                    name={'slug'}
                    value={this.props.slug}
                    onChange={this.handleInputAndCheckboxChange}
                    placeholder = {'Page Slug'}
                    required={true}/>
                <Form.Select
                    required={true}
                    label={'Page Group'}
                    search={true}
                    name={'pageGroup'}
                    placeholder={'Select Page Group...'}
                    value={this.state.pageGroup.id}
                    options={this.state.pageGroups}
                    onChange={this.handleSelectChange}/>
                <Form.Select
                    required={true}
                    label={'Page Type'}
                    name={'pageType'}
                    placeholder={'Select Page Type....'}
                    value={this.state.pageType.id}
                    onChange={this.handleSelectChange}
                    options={this.state.pageTypes}/>
                {this.getPageConfigInput()}
                {this.getTableauInputs()}
                <Form.Field>
                    <label>Summary</label>
                    <CKEditor
                        editor={ClassicEditor}
                        data={this.state.summary}
                        onBlur={this.handleSummaryOnBlur}/>
                </Form.Field>
                {this.getDescription()}
                <Form.Select
                    search={true}
                    label={'Page Access'}
                    name={'pageAccess'}
                    placeholder={'Select Application...'}
                    options={this.state.uaaApplications}
                    value={this.props.uaaApplication}
                    onChange={this.handleUaaApplicationChange}/>
                {this.getAppRoleDropdown()}
                <Form.Group>
                    <Form.Checkbox label='Active Flag'
                                   name={'activeFlag'}
                                   checked={this.state.activeFlag}
                                   onChange={this.handleInputAndCheckboxChange}/>
                    <Form.Checkbox label='Is Public'
                                   checked={this.state.isPublic}
                                   onChange={this.handleInputAndCheckboxChange}
                                   name={'isPublic'} />
                    {this.getShowEmbeddedTitle()}
                    {this.getAllowInCustomDashboards()}
                </Form.Group>
                <Grid>
                    <Grid.Row columns={1} textAlign={'right'}>
                        <Grid.Column>
                            <div id={'cu-submit-button-group'} style={{float: 'right'}}>
                                <Button id={'cp-cancel-button'}
                                        type={'button'}
                                        loading={this.props.buttonLoading}
                                        icon={'arrow left'}
                                        color={'grey'}
                                        onClick={()=>{this.props.history.push(info.managePages.path)}}
                                        content={'RETURN TO PAGES'}/>
                                <Button id={'cp-submit-button'}
                                        type={'submit'}
                                        loading={this.props.buttonLoading}
                                        color={'green'}
                                        content={'UPDATE PAGE'}/>
                            </div>
                        </Grid.Column>
                    </Grid.Row>
                </Grid>
            </Form>
        )
    }
}
