import {GlobalStore, MenuOption} from "../models/models";
import {
    getAppInfo, getPortalProperties, getSessionAnnouncements,
    getUserPrincipal, setMenuOptions,
    setRailDisplay,
    setSelectedTopMenuOption,
    setUserCookie
} from "../services/middleware-service";


/**
 * Mapping state (global redux) to props to be made available to this component
 * @param state
 */
export const mapStateToProps = (state: GlobalStore) => {
    return {
        ...state
    }
};

/**
 * dispatch will take any actions from the component and will update the state (global redux)
 * @param dispatch
 */
export const mapDispatchToProps = (dispatch: any) => {
    return {
        getUserPrincipal: (xsrfToken: string) => getUserPrincipal(xsrfToken, dispatch),
        setUserCookie: (userCookie: string) => setUserCookie(userCookie, dispatch),
        getAppInfo: (xsrfToken: string) => getAppInfo(xsrfToken, dispatch),
        setSelectedTopMenuOption: (selectedTopMenuOption: string) => setSelectedTopMenuOption(selectedTopMenuOption, dispatch),
        setRailDisplay: (railDisplay: string) => setRailDisplay(railDisplay, dispatch),
        setMenuOptions: (menuOptions: MenuOption[]) => setMenuOptions(menuOptions, dispatch),
        getPortalProperties: (xsrfToken: string) => getPortalProperties(xsrfToken, dispatch),
        getSessionAnnouncements: (xsrfToken: string) => getSessionAnnouncements(xsrfToken, dispatch)
    }
};