import axios from "axios";

export const createFavorite = (xsrfToken: string, pageGroup: string, pageId: string): Promise<boolean> => {
    const url = `/api/favorite/`;

    const requestData = {
        pageGroup: pageGroup,
        pageId: pageId,
    };

    let axiosRequestConfig = {
        headers: {
            'X-SPP-UI-Token': xsrfToken,
            'Accept': 'application/json',
            'Content-Type': 'application/json',
        }
    };

    return new Promise<boolean>((resolve) => {
        axios.post(url, requestData, axiosRequestConfig)
            .then(({ data }: any) => {
                resolve(data);
            })
            .catch(() => {
                resolve(false);
            });
    });
};

export const deleteFavorite = (xsrfToken: string, pageGroup: string, pageId: string): Promise<boolean> => {
    const url = `/api/favorite/`;

    const requestData = {
        pageGroup: pageGroup,
        pageId: pageId,
    };

    let axiosRequestConfig = {
        headers: {
            'X-SPP-UI-Toke': xsrfToken,
            'Accept': 'application/json',
            'Content-Type': 'application/json',
        }
    };

    return new Promise<boolean>((resolve) => {
        axios.delete(url, { ...axiosRequestConfig, data: requestData })
            .then(({ data }: any) => {
                resolve(data);
            })
            .catch(() => {
                resolve(false);
            });
    });
};