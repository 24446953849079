import * as React from 'react';
import moment from "moment-timezone";
import {Line} from "react-chartjs-2";
import ChartService from "../../../services/chart-service";
import 'chartjs-adapter-moment';
import {memo, useEffect, useRef, useState} from "react";
import ResizeObserver from "resize-observer-polyfill";
import {chartName, graphUpdateInterval} from "../../../util/constants";
import zoomPlugin from 'chartjs-plugin-zoom';
import {Chart, registerables} from "chart.js";
import {ChartData} from "../../../models/models";
import {chartPcValueToConfigValue} from "../../../services/static-service";
Chart.register(...registerables, zoomPlugin);

interface PageProps {
    chartService: ChartService
    updateLoading: (loading: boolean, chartName: string) => void
    updateLastModified: (lastModified: Date) => void
    loading: boolean
    zoom?: boolean
}

const ImGenMixYTD: React.FC<PageProps> = (props: PageProps) => {
    const [chartData, setChartData] = useState<ChartData>({data: [], label: []});
    const [showLegend, setShowLegend] = useState<boolean>(false);
    let ref: any = useRef();

    useEffect(() => {
        let getChartData = () => {
            props.updateLoading(true, chartName.IM_GEN_MIX_YTD);
            setChartData({data: [], label: []})
            props.chartService.getChartData(chartPcValueToConfigValue(chartName.IM_GEN_MIX_YTD)).then(response => {
                setChartData({data:response.response.datasets, label: response.response.labels})
                props.updateLastModified(new Date(response.response.lastModifiedDate))

                if (response.response.datasets.length === 0)
                    props.updateLoading(false, chartName.IM_GEN_MIX_YTD)
            })
        };

        if (props.loading)
            getChartData();

        let interval = setInterval(() => {
            getChartData();

        }, graphUpdateInterval); //fetch data every 5 min

        const resizeObserver = new ResizeObserver((entries: ResizeObserverEntry[]) => {
            setShowLegend(entries[0].target.clientHeight > 250)})

        resizeObserver.observe(ref.current);

        return () => {
            clearInterval(interval)
            resizeObserver.disconnect()}; // clean up

    }, [props.loading]) // eslint-disable-line

    //unmount data cleanup
    useEffect( () => () => {
        setChartData({data: [], label: []})
        setShowLegend(false)
    }, [] );

    let updateLoadComplete = () => {
        if (chartData.data.length > 0 || chartData.label.length > 0 )
            props.updateLoading(false, chartName.IM_GEN_MIX_YTD)
    };

    return (
        <div ref={ref} style={{height: '100%'}}>
            <Line type={'line'}
                  data={{
                      labels: chartData.label,
                      datasets: chartData.data
                  }}
                  options={{
                      maintainAspectRatio: false,
                      spanGaps: true,
                      animation: {
                          duration: 10,
                          onComplete: () => updateLoadComplete()
                      },
                      hover: {
                          animationDuration: 0
                      },
                      responsiveAnimationDuration: 0,
                      elements: {
                          line: {
                              tension: 0
                          }
                      },
                      normalized: true,
                      responsive: true,
                      plugins: {
                          zoom: {
                              zoom: {
                                  drag: {
                                      enabled: props.zoom ? props.zoom : false,
                                      threshold: 10
                                  }
                              }
                          },
                          legend: {
                              display: showLegend,
                              position: 'top',

                          },
                          tooltip: {
                              mode: 'index',
                              intersect: false,
                              callbacks: {
                                  title: function(tooltipItem: any) {
                                      let fmt = "YYYY-MM-DD HH:mm:ss";
                                      return moment(tooltipItem[0].label, "MMM DD, YYYY, h:mm:ss a").tz("America/Chicago").format(fmt);
                                  }
                              }
                          },
                      },
                      scales: {
                          x: {
                              type: 'time',
                              time: {
                                  unit: 'month',
                                  displayFormats: {
                                      'minute': "MMM 'YY",
                                  }
                              },
                              ticks: {
                                  stepSize: 1
                              }
                          }
                      }
                  }}/>
        </div>
    )
}

export default memo(ImGenMixYTD)