import axios, {AxiosInstance} from "axios";



export default class AxiosService {
    private xsrfToken: string;
    private client: AxiosInstance;

    constructor(xsrfToken: string) {
        this.xsrfToken = xsrfToken;
        this.client = axios.create({
            baseURL: '',
            withCredentials: true,
            headers: {
                'X-XSRF-TOKEN': this.xsrfToken,
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            }
        });
    }

    getAxiosInstance = (): AxiosInstance => {
        return this.client;
    };

    getXsrfToken = (): string => {
        return this.xsrfToken;
    };

    setXsrfToken = (xsrfToken: string): void => {
        this.xsrfToken = xsrfToken;
    };
}
