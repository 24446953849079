import * as React from 'react';
import './index.css'

interface PageProps {
    title?: string,
    description?: string
}

interface PageState {}

export default class PageTitle extends React.Component<PageProps, PageState> {
    constructor(props: PageProps, state: PageState) {
        super(props, state);

        this.state = {}
    }

    getTitleClass = () => {
        if (this.props.title === undefined || this.props.description === undefined)
            return 'page-title-not-visible';
        else
            return 'page-title'
    };

    render(){
        return(
            <div id={this.getTitleClass()}>
                <h1>{this.props.title}</h1>
                <p>{this.props.description}</p>
            </div>)
    }

}
