import * as React from 'react';
import './index.css'
import {FileBrowsing, Page, PageConfig, PortalProperties} from "../../../../models/models";
import {Button, ButtonProps, Dimmer, Grid, GridColumn, GridRow, Icon, Loader, Menu, Segment} from "semantic-ui-react";
import { emptyPage} from "../../../../util/constants";
import parse from 'html-react-parser';
import {cloneDeep} from "lodash";
import FileDownload from "js-file-download";
import {createRef} from "react";
import ResizeObserver from "resize-observer-polyfill";
import {browseFiles, downloadFile} from "../../../../services/file-service";
import {getPageBreadcrumb} from "../../../../util/functions";
import ReactGA from "react-ga4";

interface PageProps {
    pageConfig: PageConfig
    userCookie: any
    descriptionConfig: PageConfig
    darkTheme: boolean
    portalProperties: PortalProperties;
}

interface PageState {
    page: Page
    folderFileList: FileBrowsing[]
    items: any[]
    showSummary: boolean
    showDescription: boolean
    sortDesc: boolean
    gridView: boolean
    path: string
    loading: boolean
    buttonLoadingName: string
    condensedView: boolean
}

export default class FileBrowserPage extends React.Component<PageProps, PageState> {
    resizeObserver: any;
    resizeElement = createRef();
    constructor(props: PageProps, state: PageState) {
        super(props, state);

        this.state = {
            page: emptyPage,
            folderFileList: [],
            items: [],
            showSummary: false,
            showDescription: false,
            sortDesc: true,
            gridView: true,
            path: '',
            loading: true,
            buttonLoadingName: '',
            condensedView: false
        }
    }

    componentDidMount() {
        this.getFiles("")

        if (this.props.pageConfig.page !== undefined)
        {
            ReactGA.event({category: "user_page_view", action: "View "+ this.props.pageConfig.page.title +" File Browser Page"});
            this.setState({page: this.props.pageConfig.page})
        }

        this.resizeObserver = new ResizeObserver((entries) => {
            if (entries[0].target.clientWidth <= 1000)
                this.setState({gridView: false, condensedView: true})
            if (entries[0].target.clientWidth > 1000)
                this.setState({condensedView: false})
        });

        this.resizeObserver.observe(this.resizeElement.current);
    }

    componentWillUnmount() {
        if (this.resizeObserver) {
            this.resizeObserver.disconnect();
        }
    }

    getFiles = (path: string) => {
        this.setState({loading: true})
        let fileBrowsing: FileBrowsing = {
            fsName: this.props.pageConfig.pcValue,
            name: this.props.pageConfig.pcValue,
            path: path,
            type: 'folder'
        }

        browseFiles(fileBrowsing, this.props.userCookie).then((response:FileBrowsing[])  => {
            this.setState({folderFileList: response, path: path, loading: false})
        })
    }

    componentDidUpdate(prevProps: Readonly<PageProps>, prevState: Readonly<PageState>, snapshot?: any) {
        if ((this.state.folderFileList !== prevState.folderFileList) || (this.state.gridView !== prevState.gridView) ||
            (this.state.buttonLoadingName !== prevState.buttonLoadingName))
            this.formatFileView()

    }


    formatFileView = () => {
        let itemArray: any[] = [];

        this.state.folderFileList.forEach((item, index) => {
            if (item.type === 'folder')
                itemArray.push(
                    <GridColumn key={index} className={this.props.darkTheme? ' dark' : ''}>
                        <Segment
                            className={'folder-segment'}
                            onClick={()=>{this.getFiles(item.path)}} >
                            <span id={'folder-icon'} className={'spp-icon folder'}/>
                            <span className={'item-name'}>
                                {item.name}
                            </span>
                        </Segment>
                    </GridColumn>
                )
            else
            {
                if (this.state.gridView)
                {
                    let fileType = item.name.split('.').pop();

                    itemArray.push(
                        <GridColumn key={index} className={this.props.darkTheme? ' dark' : ''}>
                            <Segment
                                onClick = {()=>this.downloadFileOnClick(item)}
                                className={'file-segment'}>
                                <Dimmer active={item.name === this.state.buttonLoadingName} inverted={!this.props.darkTheme}>
                                    <Loader active={item.name === this.state.buttonLoadingName}/>
                                </Dimmer>
                                <Grid columns={2} stretched={true} >
                                    <GridColumn
                                        style={{width: '8rem'}}
                                        width={4}
                                        id={'file-icon-column'}>
                                        <span
                                            className={"spp-icon file f-"+ fileType}>
                                            .{fileType}
                                        </span>
                                    </GridColumn>
                                    <GridColumn  width={12}  >
                                        <GridRow className={'item-name'}>
                                            {item.name}
                                        </GridRow>
                                        <GridRow className={'item-info'}>
                                            {this.getFileSize(item.size? item.size : 0)} <br/> {item.modified? item.modified : ''}
                                        </GridRow>
                                    </GridColumn>
                                </Grid>
                            </Segment>
                        </GridColumn>
                    )
                }
                else
                {
                    let fileType = item.name.split('.').pop();
                    itemArray.push(
                        <GridColumn key={index} className={this.props.darkTheme? ' dark' : ''}>
                            <Segment
                                onClick = {()=>this.downloadFileOnClick(item)}
                                className={'file-segment'}>
                                <Dimmer active={item.name === this.state.buttonLoadingName} inverted={!this.props.darkTheme}>
                                    <Loader active={item.name === this.state.buttonLoadingName}/>
                                </Dimmer>
                                <span
                                    className={"spp-icon file f-"+ fileType}>
                                    .{fileType}
                                </span>
                                <span
                                    className={'item-name'}>
                                    {item.name}
                                </span>
                                <div id={'file-info-div'}>
                                    {this.getFileSize(item.size? item.size : 0)} <br/> {item.modified? item.modified : ''}
                                </div>
                            </Segment>
                        </GridColumn>
                    )
                }
            }
        })

        this.setState({items: itemArray})
    }

    downloadFileOnClick = (item: FileBrowsing) => {
        this.setState({buttonLoadingName: item.name})
        let fileType = item.name.split('.').pop();

        downloadFile(item.fsName, item.path, this.props.userCookie).then(response => {
            FileDownload(response, item.name+'.'+fileType)
            this.setState({buttonLoadingName: ''})
        })
    }

    getFileSize = (bytes: number): string => {
        let sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
        if (bytes === 0) return '0 Bytes';
        let i = Math.floor(Math.log(bytes) / Math.log(1024));
        return Math.round(bytes / Math.pow(1024, i)) + ' ' + sizes[i];
    }

    getFormattedItemRows = () => {
        if (this.state.items.length === 0 || this.state.loading)
            return (
                <div className={'nothingfound'+this.state.loading}>
                    <Dimmer active={this.state.loading} inverted={!this.props.darkTheme}>
                        <Loader active={this.state.loading}/>
                    </Dimmer>
                    <div className="nofiles"/>
                    <span>
                        No files here
                    </span>
                </div>
            )

        let gridRowArray: any[] = []

        if (this.state.gridView)
        {
            for (let i: number =0; i < this.state.items.length/3; i++ )
            {
                gridRowArray.push(
                    <GridRow key={i} columns={3} className={'fb-segment-row'}>
                        {this.state.items[(i*3)]}
                        {this.state.items[(i*3)+1] !== undefined ? this.state.items[(i*3)+1] : <GridColumn/>}
                        {this.state.items[(i*3)+2] !== undefined ? this.state.items[(i*3)+2] : <GridColumn/>}
                    </GridRow>
                )
            }

            return(
                <Grid columns={'equal'}>
                    {gridRowArray}
                </Grid>
            )
        }
        else
        {
            this.state.items.forEach((item, index) => {
                gridRowArray.push(
                    <GridRow key={index} className={'fb-segment-row'}>
                        {item}
                    </GridRow>
                )
            })

            return(
                <Grid>
                    {gridRowArray}
                </Grid>
            )
        }
    }

    handleButtonClick = (event: any, {name}: ButtonProps) => {
        switch (name) {
            case 'summary':
                this.setState({showSummary: !this.state.showSummary})
                break;
            case 'description':
                this.setState({showDescription: !this.state.showDescription})
                break;
        }
    }

    getPageSummary = () => {
        if (this.state.showSummary)
            return (
                <Segment attached={true}>
                    {parse(this.state.page.summary)}
                </Segment>
            )
        else
            return null
    }

    getDescription = () => {
        if (this.state.showDescription)
            return (
                <Segment attached={true}>
                    {parse(this.props.descriptionConfig.pcValue)}
                </Segment>
            )
        else
            return null
    }

    getSummaryBtn = () => {
        if (this.state.page.summary)
            return (
                <Button
                    name={'summary'}
                    className={'fb-info-btn'}
                    onClick={this.handleButtonClick}
                    fluid={true}
                    attached={'top'}>
                    SUMMARY
                    <Icon  className={'see-more-arrow'} name={this.state.showSummary? 'minus' : 'plus'}/>
                </Button>
            )
        else
            return null
    }

    getDescriptionBtn = () => {
        if (this.props.descriptionConfig.pcValue)
            return (
                <Button
                    name={'description'}
                    className={'fb-info-btn'}
                    onClick={this.handleButtonClick}
                    attached={"top"}
                    fluid={true}>
                    DESCRIPTION
                    <Icon  className={'see-more-arrow'} name={this.state.showDescription? 'minus' : 'plus'}/>
                </Button>
            )
        else
            return null
    }

    handleSortOnClick = () => {
        let sortedList = cloneDeep(this.state.folderFileList);

        if (this.state.sortDesc)
        {
            sortedList.sort((a,b) => b.name.localeCompare(a.name));
            this.setState({sortDesc: false, folderFileList: sortedList})
        }
        else {
            sortedList.sort((a,b) => a.name.localeCompare(b.name));
            this.setState({sortDesc: true, folderFileList: sortedList})
        }
    }

    getBreadCrumb = () => {
        let breadCrumbArray = []
        breadCrumbArray.push(
            <Button
                key={this.props.pageConfig.pcValue}
                onClick={()=> {this.getFiles('')}}
                id={'folder-name'}>
                {this.props.pageConfig.pcValue}
            </Button>)

        if (this.state.path)
        {
            let buttonPath: string = '/';
            let pathArray = (this.state.path.substring(1)).split('/')
            pathArray.forEach((path) => {
                buttonPath = buttonPath+path;
                let updatedButtonPath = buttonPath;

                breadCrumbArray.push(
                    <span >
                        <Icon
                            key={'icon-'+path}
                            size={'large'}
                            id={'fb-arrow-icon'}
                            name={'angle right'}/>
                    </span>)

                breadCrumbArray.push(
                    <Button
                        key={path}
                        onClick={()=> {this.getFiles(updatedButtonPath)}}
                        id={'folder-name'}>
                        {path}
                    </Button>)
                buttonPath = buttonPath+'/'
            })
        }
       return  breadCrumbArray
    }

    render(){
        return(
            <Grid style={{height: '100%'}} >
                <Grid.Row>
                    <Grid.Column id={'fb-title-column'} className={this.props.darkTheme? ' dark' : ''}>
                        <div id={'breadcrumb'}>
                            {getPageBreadcrumb(
                                this.state.page.breadcrumb? this.state.page.breadcrumb : [],
                                this.props.portalProperties.uaaHost)}
                        </div>
                        {/*// @ts-ignore*/}
                        <div ref={this.resizeElement} id={'file-browser-title'}>
                            {this.props.pageConfig.page?.title}
                        </div>
                        {this.getSummaryBtn()}
                        {this.getPageSummary()}
                        {this.getDescriptionBtn()}
                        {this.getDescription()}
                        <Segment id={'file-browser-segment'}>
                            <Menu secondary={true} id={'fb-icon-menu'}>
                                <Menu.Menu position={'right'}>
                                    <Menu.Item
                                        title={this.state.sortDesc? 'Sort Alphabetically Desc' : 'Sort Alphabetically Asc'}
                                        onClick={this.handleSortOnClick}>
                                        <Icon
                                            className={'fb-icon'}
                                            size={'large'}
                                            name={this.state.sortDesc? 'sort amount down' : 'sort amount up'}/>
                                    </Menu.Item>
                                    <Menu.Item
                                        title={this.state.gridView? 'Change View to List' : 'Change View to Grid'}
                                        disabled={this.state.condensedView}
                                        onClick={() => {this.setState({gridView: !this.state.gridView})}}>
                                        <Icon
                                            className={'fb-icon'}
                                            size={'large'}
                                            name={this.state.gridView? 'th list' : 'th'}/>
                                    </Menu.Item>
                                </Menu.Menu>
                            </Menu>
                            <GridRow id={'fb-breadcrumb-roll'}>
                                {this.getBreadCrumb()}
                            </GridRow>
                            <GridRow >
                                {this.getFormattedItemRows()}
                            </GridRow>
                        </Segment>
                    </Grid.Column>
                </Grid.Row>
            </Grid>
        )
    }
}