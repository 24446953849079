import * as React from 'react';
import AnnouncementService from "../../../services/announcement-service";
import {ActionResponse, Announcement, ModalContent} from "../../../models/models";
import {Button, DropdownProps, Form, FormGroup, Grid, Segment} from "semantic-ui-react";
import SppReactTable from "../../Common/PaginatedTable/SppReactTable";
import {getAnnouncementHeaders} from "../../../services/table-header-service";
import {announcementColors, modalTypes} from "../../../util/constants";
import SppModal from "../../Common/SppModal";
import toast from "../../../util/toast"
import { cloneDeep } from 'lodash';
import"./index.css"
import SemanticDatepicker from "react-semantic-ui-datepickers";
import moment from "moment";


interface PageProps {
    userCookie: any
}

interface PageState {
    announcements: Announcement[]
    modalOpen: boolean
    expirationDate: string
    announcementColor: any
    selectedAnnouncement: Announcement
    tableLoading: boolean,
    modalType: string
    resetTableSelection: boolean,
    modalButtonLoading: boolean
}

class Announcements extends React.Component<PageProps, PageState> {
    private client: AnnouncementService;

    constructor(props: PageProps, state: PageState){
        super(props, state);

        this.client = new AnnouncementService(this.props.userCookie);

        this.state = {
            announcements: [],
            modalOpen: false,
            expirationDate: '',
            announcementColor: 'blue',
            selectedAnnouncement: {id:0, title: '', content: '', published: 'false', expirationDate: '', displayType: '', isPublic: 'false'},
            tableLoading: false,
            modalType: '',
            resetTableSelection: false,
            modalButtonLoading: false
        };
    }

    //initial call to get table data
    componentDidMount(): void {
        this.getAnnouncements();
    }

    //fetches announcements list
    getAnnouncements = () => {
        this.setState({tableLoading: true});
        this.client.getAnnouncements()
            .then((response: Announcement[]) => {
                const announcements  = response.map(announcement => {
                    return {
                        id: announcement.id,
                        title: announcement.title,
                        displayType: announcement.displayType,
                        published: announcement.published.toString(),
                        expirationDate: announcement.expirationDate,
                        content: announcement.content,
                        isPublic: announcement.isPublic.toString()
                    }
                });
                this.setState({announcements: announcements, tableLoading: false});
            })
    };

    //opens create modal and resets controlled input values
    createButtonOnClick = () => {
        this.setState({
            modalOpen: true,
            modalType: modalTypes.CREATE,
            expirationDate: '',
            announcementColor: 'green'
        })
    };

    //opens edit modal
    editAnnouncementOnClick = () => {
        this.setState({modalOpen: true, modalType: modalTypes.UPDATE})
    };

    //calls either create or update announcement service call depending on modal type
    handleFormSubmit = (event: any) => {
        this.setState({modalButtonLoading: true});

        //check that expiration date is valid
        if (moment(this.state.expirationDate, 'YYYY-MM-DD') < moment())
        {
            toast.error("Expiration date can not be in past")
            return
        }

        let announcement: Announcement = {
            title: event.target.title.value,
            content: event.target.content.value,
            displayType: this.state.announcementColor,
            expirationDate: event.target.expiration.value,
            published: event.target.published.checked,
            isPublic: event.target.isPublic.checked
        };

        if (this.state.modalType === modalTypes.CREATE)
        {
            this.client.createAnnouncement(announcement).then((response: ActionResponse<Announcement>) => {
                this.setState({modalButtonLoading: false});
                if (response.ok)
                {
                    this.getAnnouncements();
                    this.setState({modalOpen: false, expirationDate: '', announcementColor: 'blue'});
                    toast.success('Announcement Created.')
                }
                else
                    toast.error(response.message);
            })
        }
        else
        {
            announcement.id = this.state.selectedAnnouncement.id;
            this.client.updateAnnouncement(announcement).then((response: ActionResponse<Announcement>) => {
                this.setState({modalButtonLoading: false});
                if (response.ok)
                {
                    this.getAnnouncements();
                    this.setState({modalOpen: false, selectedAnnouncement: announcement});
                    toast.success('Announcement Updated.')
                }
                else
                    toast.error(response.message);
            })
        }
    };

    //opens the delete confirmation modal
    deleteAnnouncementOnClick = () => {
        this.setState({modalOpen: true, modalType: modalTypes.DELETE})
    };

    //calls the service delete call and resets selected row in paginated table component
    handleDeleteSubmit = () => {
        this.setState({resetTableSelection: true, modalButtonLoading: true});
        this.client.deleteAnnouncement(this.state.selectedAnnouncement.id || 0).then((response: ActionResponse<null>) => {
            this.setState({resetTableSelection: false, modalButtonLoading: false});
            if (response.ok)
            {
                this.getAnnouncements();
                this.setState({modalOpen: false, expirationDate: '', announcementColor: 'blue'});
                toast.success('Announcement Deleted.')
            }
            else
                toast.error(response.message);
        })
    };

    //controls the color change select value
    handleAnnouncementColorChange= (e: any, {value}: DropdownProps) =>{
        this.setState({announcementColor: value})

    };

    //retrieves the row object from paginated table when a row is selected then sets it as a local state to this component
    setSelectedAnnouncement = (row: Announcement) => {
        if (row !== null)
            this.setState({selectedAnnouncement: row, announcementColor: row.displayType, expirationDate: (row.expirationDate || "")});
        else
            this.setState({selectedAnnouncement: {id:0, title: '', content: '', published: 'false', expirationDate: '', displayType: '', isPublic: 'false'}});
    };

    //gets modal content to pass to modal component - not as state to avoid race condition
    getModalContent = (): ModalContent => {
        return(
            {
                iconName: this.getModalIcon(),
                modalTitle: this.getModalTitle(),
                onClose: this.closeModal,
                modalBody: this.getModalBody()
            }
        )
    };

    //gets the modal icon based on modal type
    getModalIcon = () => {
        switch (this.state.modalType) {
            case modalTypes.CREATE:
                return 'calendar plus outline';
            case modalTypes.UPDATE:
                return 'calendar alternate outline';
            case modalTypes.DELETE:
                return 'calendar minus outline';
            default:
                return '';
        }
    };

    //gets the modal title based on modal type
    getModalTitle = () => {
        switch (this.state.modalType) {
            case modalTypes.CREATE:
                return 'Create Announcement';
            case modalTypes.UPDATE:
                return 'Edit Announcement';
            case modalTypes.DELETE:
                return 'Delete Announcement';
            default:
                return '';
        }
    };

    //closes modal and resets relevant states
    closeModal = () => {
        let selectedAnnouncement = cloneDeep(this.state.selectedAnnouncement);
        this.setState({modalOpen: false,
            modalType: '',
            announcementColor: selectedAnnouncement.displayType,
            expirationDate: (selectedAnnouncement.expirationDate || '')})
    };

    //decided what should be the body of the modal based on modal type
    getModalBody = () => {
        if (this.state.modalType === modalTypes.DELETE)
            return this.getDeleteModalBody();
        else
            return this.getModalForm()
    };

    //returns main body of delete confirmation modal
    getDeleteModalBody = () => {
        return (
            <Grid id={'conf-info-content'}>
                <Grid.Row>
                    <Grid.Column>
                        Are you sure you want to delete announcement: {this.state.selectedAnnouncement.title}?
                    </Grid.Column>
                </Grid.Row>
                <Grid.Row>
                    <Grid.Column textAlign={'right'}>
                            <Button className={'modal-action-button'}
                                    onClick={this.closeModal}
                                    color={'red'}
                                    loading={this.state.modalButtonLoading}
                                    id={'cm-negative-button'}
                                    content={'NO'}/>
                            <Button onClick={this.handleDeleteSubmit}
                                    color={'green'}
                                    id={'cm-positive-button'}
                                    loading={this.state.modalButtonLoading}
                                    className={'modal-action-button'}
                                    content={'YES'}/>
                    </Grid.Column>
                </Grid.Row>
            </Grid>
        )
    };

    //returns main body of the announcement form modal. default values determined by modal type
    getModalForm = () => {
        let getDefaultValues = () => {
            if (this.state.modalType === modalTypes.CREATE)
                return {id:0, title: '', content: '', published: 'false', expirationDate: '', displayType: '', isPublic: 'false'};
            else
                return this.state.selectedAnnouncement;
        };
        return(
            <Form onSubmit={this.handleFormSubmit}>
                <Form.Input fluid={true}
                            label={'Title'}
                            name={'title'}
                            placeholder={'Title'}
                            defaultValue={getDefaultValues().title}
                            required={true}/>
                <Form.TextArea label={'Content'}
                               placeholder={'What you want the announcement to say...'}
                               name={'content'}
                               defaultValue={getDefaultValues().content}
                               required={true}/>
                <Form.Select required={true}
                             fluid={true}
                             name={'announcementType'}
                             options={announcementColors}
                             value={this.state.announcementColor}
                             placeholder={'Pick a Color'}
                             onChange={this.handleAnnouncementColorChange}
                             label={'Announcement Display Color'}/>
                <Form.Field id={'expiration-date-picker'}>
                    <SemanticDatepicker value={this.state.expirationDate === '' ? null : moment(this.state.expirationDate, 'YYYY-MM-DD').toDate()}
                                        label={'Expiration Date'}
                                        name={'expiration'}
                                        pointing={"top right"}
                                        filterDate={this.futureDatesOnly}
                                        datePickerOnly={true}
                                        format={'YYYY-MM-DD'}/>
                </Form.Field>

                <FormGroup>
                    <Form.Checkbox label='Published' name={'published'} defaultChecked={JSON.parse(getDefaultValues().published)}/>
                    <Form.Checkbox label='Public' name={'isPublic'} defaultChecked={JSON.parse(getDefaultValues().isPublic)}/>
                </FormGroup>
                {this.getModalButtons()}
            </Form>
        )
    };

    futureDatesOnly = (date: Date): boolean => {
        return date > new Date();
    }

    //returns buttons for create and update forms based on modal type state
    getModalButtons = () => {
        if (this.state.modalType === modalTypes.CREATE)
            return (
                <Form.Button floated={"right"}
                             loading={this.state.modalButtonLoading}
                             style={{marginBottom: '1rem'}}
                             content={"CREATE ANNOUNCEMENT"}
                             positive={true}/>
            );
        else
            return (
                <Grid>
                    <Grid.Row>
                        <Grid.Column textAlign={'right'}>
                            <Button className={'form-close-button'}
                                    color={'red'}
                                    loading={this.state.modalButtonLoading}
                                    type={'button'}
                                    onClick={this.closeModal}
                                    content={'CANCEL'}/>
                            <Button color={'green'}
                                    loading={this.state.modalButtonLoading}
                                    className={'form-action-button'}
                                    content={'UPDATE ANNOUNCEMENT'}/>
                        </Grid.Column>
                    </Grid.Row>
                </Grid>
            )
    };

    public render(){
        return (
            <Segment id={'announcement-table'}>
                <SppReactTable
                    tableData={this.state.announcements}
                    tableButtonGroupVisible={true}
                    createRowButton={true}
                    handleCreateRow={this.createButtonOnClick}
                    refreshTableButton={true}
                    handleTableRefresh={this.getAnnouncements}
                    headers={getAnnouncementHeaders()}
                    selectable={true}
                    editSelectedRow={true}
                    deleteSelectedRow={true}
                    sendSelectedToParent={this.setSelectedAnnouncement}
                    handleRowEdit={this.editAnnouncementOnClick}
                    handleRowDelete={this.deleteAnnouncementOnClick}
                    loading={this.state.tableLoading}
                    resetSelect={this.state.resetTableSelection}
                    selectButtonWording={'Edit Announcement'}/>
                <SppModal
                    open={this.state.modalOpen}
                    modalContent={this.getModalContent()}/>
            </Segment>
        )
    }
}

export default Announcements;