import AxiosService from "./axios-service";
import {Access} from "../models/models";
import {emptyPage} from "../util/constants";
//import {genericError} from "../util/constants";

export default class AccessService {
    private client: AxiosService;

    constructor(csrfToken: string) {
        this.client = new AxiosService(csrfToken);
    }

    getAccessByPage = (pageId: number): Promise<Access> => {
        const url = `/api/access/byPageId/${pageId}`;
        return new Promise<Access>(((resolve) => {
            this.client.getAxiosInstance().get(url)
                .then(({data}) => {resolve(data)})
                .catch(() => {resolve({id: 0, authzAppId: 0, page: emptyPage})})
        }));
    };

}