import * as React from 'react';
import * as ReactDOM from 'react-dom';
import './index.css';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import 'semantic-ui-css/semantic.css';
import {reducer} from "./store/reducers";
import {Provider} from "react-redux";
import {createStore, applyMiddleware} from "redux";
import 'react-toastify/dist/ReactToastify.css';
import 'react-semantic-ui-datepickers/dist/react-semantic-ui-datepickers.css';
import thunk from "redux-thunk";
import AppRoutes from "./routes";
import {CookiesProvider} from "react-cookie";

const store = createStore(reducer, applyMiddleware(thunk));

ReactDOM.render(
    <CookiesProvider>
        <Provider store={store}><AppRoutes /></Provider>
    </CookiesProvider>,
  document.getElementById('root') as HTMLElement
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.register();
