import * as React from "react";
import {connect} from "react-redux";
import {compose} from "redux";
import {Container, Grid} from "semantic-ui-react";
import {withRouter} from "react-router-dom";
import {withCookies} from "react-cookie";
import {mapDispatchToProps, mapStateToProps} from "../../../../store/mappers";
import {AppProps} from "../interfaces";
import CommonFooter from "./Footer";
import checkPrincipal from "../Auth";
import AppMenu from "./AppMenu";
import PageTitle from "./PageTitle";
import {ToastComponent} from "../../ToastComponent";

const withAdmin: any =
    <P extends AppProps> ( WrappedComponent: React.ComponentType<P>, title?: string, description?: string ) => {

        let getTitleRow = (title: string | undefined, description: string | undefined) => {
            if(title === undefined && description === undefined)
                return null;
            else
                return (
                    <Grid.Row  className={'pt-title-row'} >
                        <Grid.Column computer={14} style={{left: '16rem'}}>
                            <PageTitle title={title} description={ description}/>
                        </Grid.Column>
                    </Grid.Row>
                )
        };
        return (props: any) => {
            return (
                <Container fluid={true}>
                    <ToastComponent/>
                    <Grid>
                        <Grid.Row columns={1} id={'image-header-row'}>

                            <Grid.Column computer={16} id={'image-column'}>
                                <div className={'common admin'}>
                                    <div id={'image-surround'}>
                                        <img id={'spp-logo'} src={"/images/spplogo.png"} alt={"SPP logo"} />
                                    </div>
                                </div>
                            </Grid.Column>
                        </Grid.Row >
                        <Grid.Row columns={1} id={'app-info-row'}>
                            <Grid.Column computer={16} >
                                <AppMenu {...props}/>
                            </Grid.Column>
                        </Grid.Row >
                        {getTitleRow(title, description)}
                        <Grid.Row className={'pt-component-row'}>
                            {/*If the component is CreatePage or an edit component, then the class is chosen to restrict max-width to prevent overflow into side menu when fields are tabbed.*/}
                            <Grid.Column computer={14} id={window.location.href === `${window.location.origin}/admin/announcements` || window.location.href === `${window.location.origin}/admin/pages` || window.location.href === `${window.location.origin}/admin/page-groups` || window.location.href === `${window.location.origin}/admin/features`? 'component-column' : 'restricted-width-component' } >
                                <WrappedComponent {...props}  />
                            </Grid.Column>
                        </Grid.Row>
                        <Grid.Row>
                            <Grid.Column computer={16} verticalAlign={'bottom'}>
                                <CommonFooter {...props}/>
                            </Grid.Column>
                        </Grid.Row>
                    </Grid>
                </Container>
            )
        };
    };

export default compose(connect(mapStateToProps, mapDispatchToProps),  withCookies, withRouter, checkPrincipal, withAdmin);