import './index.css'

import * as React from 'react';
import {Icon, Menu, MenuMenuProps} from "semantic-ui-react";
import './index.css'
import {AppInfo, PortalProperties, Principal} from "../../../../../models/models";
import {info} from "../../../../../services/route-service";


interface PageProps {
    userPrincipal: Principal
    getUserPrincipal: (xsrfToken: string) => void;
    userCookie: string
    history: any
    portalProperties: PortalProperties
    appInfo: AppInfo
    getAppInfo: (xsrfToken: string) => void;
    getPortalProperties: (xsrfToken: string) => void;
}

interface PageState {
}

export default class AppMenu extends React.Component<PageProps, PageState> {
    constructor(props: PageProps, state: PageState) {
        super(props, state);

        this.state = {
        };
    }

    componentDidMount(): void {
        if (this.props.userPrincipal.displayName === '')
            this.props.getUserPrincipal(this.props.userCookie);
        
        if (this.props.appInfo.environment === '')
            this.props.getAppInfo(this.props.userCookie);
        
        this.props.getPortalProperties(this.props.userCookie);
    }

    handleMenuClick = (event: any, {name}: MenuMenuProps) => {

        switch (name) {
            case 'announcements':
                this.props.history.push(info.announcements.path);
                break;
            case 'pageGroups':
                this.props.history.push(info.pageGroups.path);
                break;
            case 'pages':
                this.props.history.push(info.managePages.path);
                break;
            case 'features':
                this.props.history.push(info.features.path);
        }
    };

    render(){

        return(
            <div>
                <Menu id={'header-menu'} borderless={false} >
                    <Menu.Menu position={'right'}>
                        <Menu.Item>
                            {this.props.appInfo.envDisplay}
                        </Menu.Item>
                        <Menu.Item>
                            <Icon name={'user'}/>
                            {this.props.userPrincipal.displayName}
                        </Menu.Item>
                    </Menu.Menu>
                </Menu>
                <Menu id={'nav-menu'} borderless={true} vertical={true} fixed={"left"} inverted={true}>
                    <Menu.Item onClick={this.handleMenuClick}
                               name={'announcements'}
                               id={'announcement-menu-item'}>
                        <Icon name={'announcement'}/>
                        Announcements
                    </Menu.Item  >
                    <Menu.Item name={'pageGroups'}
                               onClick={this.handleMenuClick}
                               id={'page-group-menu-item'}>
                        <Icon name={'sitemap'}/>
                        Page Groups
                    </Menu.Item>
                    <Menu.Item onClick={this.handleMenuClick}
                               name={'pages'}
                               id={'page-menu-item'}>
                        <Icon name={'copy outline'} />
                        Pages
                    </Menu.Item>
                    <Menu.Item onClick={this.handleMenuClick}
                               name={'features'}
                               id={'page-menu-item'}>
                        <Icon name={'toggle on'} />
                        Features
                    </Menu.Item>
                </Menu>
            </div>
        )
    }
}