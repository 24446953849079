import {ActionResponse, TermsOfAcceptance} from "../models/models";
import {genericError} from "../util/constants";
import axios from "axios";

export const  acceptTerms = (xsrfToken: string): Promise<ActionResponse<TermsOfAcceptance>> => {
    const url = `/api/terms/accept`;
    let axiosRequestConfig = {headers: {
            'X-SPP-UI-Token': xsrfToken,
            'Accept': 'application/json',
            'Content-Type': 'application/json',}};
    return new Promise<ActionResponse<TermsOfAcceptance>>(((resolve) => {
        axios.post(url, axiosRequestConfig)
            .then(({data}: any) => {resolve(data)})
            .catch(() => {resolve(genericError)})
    }));
};