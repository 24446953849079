import * as React from 'react';
import {Button, Dimmer, Grid, GridColumn, Icon, Loader, Segment} from "semantic-ui-react";
import moment from "moment";
import {MenuOption, Page, PageConfig, PortalProperties, Preference, Principal} from "../../../models/models";
import {NavLink} from "react-router-dom";
import ReactGA from "react-ga4";
import Tableau from "../../Common/Tableau";
import {getBackgroundImage, getPageBreadcrumb} from "../../../util/functions";
import MenuRail from "../../Common/MenuRail";
import Favorite from "../../Common/Favorite";
import {emptyPage, emptyPageConfig} from "../../../util/constants";
import PageConfigService from "../../../services/page-config-service";
import {getPreferenceReliability} from "../../../services/preference-service";


interface PageProps {
    history: any
    darkTheme: boolean
    userCookie: any
    userPrincipal: Principal;
    selectedTopMenuOption: string
    setSelectedTopMenuOption: (selectedTopMenuOption: string) => void;
    portalProperties: PortalProperties;
    railDisplay: string
    setRailDisplay: (railDisplay: string) => void
    menuOptions: MenuOption[]
    getUserPrincipal: any
    setMenuOptions: (menuOptions: MenuOption[]) => void
    match: any
}

interface PageState {
    reliabilityCharts: Preference[]
    chart1Loading: boolean
    chart2Loading: boolean
    chart3Loading: boolean
    chart4Loading: boolean
    lastModified: Date
    loading: boolean
    page: Page
    pageConfig: PageConfig
}

export default class ReliabilityGraphs extends React.Component<PageProps, PageState> {
    private pageConfigClient: PageConfigService;
    constructor(props: PageProps, state: PageState) {
        super(props, state);

        this.pageConfigClient = new PageConfigService(this.props.userCookie)

        this.state = {
            reliabilityCharts: [],
            chart1Loading: true,
            chart2Loading: true,
            chart3Loading: true,
            chart4Loading: true,
            lastModified: new Date(0),
            loading: true,
            page: emptyPage,
            pageConfig: emptyPageConfig
        }
    }

    componentDidMount() {
        ReactGA.event({category: "user_page_view", action: "View Real Time Reliability Dashboard"});

        this.getTableauCharts();
    }

    getTableauCharts = () => {
        getPreferenceReliability(this.props.userCookie).then(response => {
            if (response.length < 1)
                this.props.history.push('/page-not-found')

            else
            {
                this.setState({reliabilityCharts: response})
                this.getPageFromSlug()
            }
        })
    }

    getPageFromSlug = () => {
        this.pageConfigClient.getPageConfigByPage(this.props.match.path.split("/pages/")[1]).then(response => {
            if (response.length < 1)
                this.props.history.push('/page-not-found')

            this.setState({loading: false, pageConfig: response[0], page: response[0].page ? response[0].page : emptyPage})
        })
    }

    updateLoadingStatus = (loading: boolean, chartName: string) => {
        let position: number = this.state.reliabilityCharts.findIndex(chart => chart.value === chartName);
        switch (position) {
            case 0:
                this.setState({chart1Loading: loading})
                break;
            case 1:
                this.setState({chart2Loading: loading})
                break;
            case 2:
                this.setState({chart3Loading: loading})
                break;
            case 3:
                this.setState({chart4Loading: loading})
                break;
        }
    }

    updateLastModified = (lastModified: Date) => {
        if (lastModified > this.state.lastModified)
            this.setState({lastModified: lastModified})
    }

    getChartLoadingState = (index: number) => {
        switch (index) {
            case 0:
                return this.state.chart1Loading
            case 1:
                return this.state.chart2Loading
            case 2:
                return this.state.chart3Loading
            case 3:
                return this.state.chart4Loading
            default:
                return this.state.chart1Loading
        }
    }

    getChartsFromPreferences = () => {
        let gridRows: any[] = [];
        let gridColumnRow1: any[] = [];
        let totalCharts = this.state.reliabilityCharts.length

        //generating columns for first row
        if (totalCharts > 0)
            gridColumnRow1.push(this.getChartColumnsByPreference(this.state.reliabilityCharts[0], 0))

        if (totalCharts === 2)
            gridColumnRow1.push(this.getChartColumnsByPreference(this.state.reliabilityCharts[1], 1))

        gridRows.push(
            <Grid.Row key={1} style={{minHeight: '450px'}} className={totalCharts > 2 ? 'db-chart-row' : '.db-chart-single-row'} columns={totalCharts === 2 ? 2 : 1}>
                {gridColumnRow1}
            </Grid.Row>
        )

        //generating columns for second row IF needed
        if (this.state.reliabilityCharts.length > 2)
        {
            let gridColumnRow2: any[] = [];

            for (let i = 1; (i < totalCharts && i < 4); i++ )
                gridColumnRow2.push(this.getChartColumnsByPreference(this.state.reliabilityCharts[i], i))

            gridRows.push(
                <Grid.Row key={2} style={{minHeight: '450px'}} className={'db-chart-row'} columns={totalCharts < 4 ? 2 : 3 }>
                    {gridColumnRow2}
                </Grid.Row>
            )
        }
        return gridRows;
    }

    getChartColumnsByPreference = (preference: Preference, index: number) =>  {
        return (
            <Grid.Column id={'chart-grid-col'} className={this.props.darkTheme? 'chart-column dark' : '.chart-column'} key={index}>
                <Segment.Group className={'chart-sg'} >
                    <Segment
                        content={this.state.reliabilityCharts[index].page?.title}
                        className={'chart-title-sg'}/>
                    <Segment className={'chart-segment tableau'}>
                        <Dimmer
                            active={this.getChartLoadingState(index)}
                            className={"tableau-dimmer"}
                            inverted={!this.props.darkTheme}>
                            <Loader/>
                        </Dimmer>

                        <Tableau
                            loading={this.getChartLoadingState(index)}
                            updateLoading={this.updateLoadingStatus}
                            userCookie={this.props.userCookie}
                            position={index+1}
                            height={'max(calc((100vh - (7.1rem + 418px )) / 2), 330px)'}
                            total={this.state.reliabilityCharts.length}
                            viewName={preference.value}
                            updateLastModified={this.updateLastModified}
                            tableauSrc={preference.tableauURL ? preference.tableauURL : ''}/>
                    </Segment>
                    <NavLink to={this.state.reliabilityCharts[index].page?.slug}>
                        <Button
                            className={'narrow-btn graph-btn'}
                            attached='bottom'
                            color={'grey'}>
                            SEE MORE
                            <Icon className={'see-more-arrow'} name={'arrow right'}/>
                        </Button>
                    </NavLink>
                </Segment.Group>
            </Grid.Column>
        )
    }

    render(){
        return(
            <div className={'background-image-tou'} style={{backgroundImage: getBackgroundImage(this.props.darkTheme)}} >
                <Dimmer inverted={!this.props.darkTheme} active={this.state.loading}>
                    <Loader/>
                </Dimmer>
                <MenuRail selectedTopMenuItem={this.props.selectedTopMenuOption}
                          railDisplay={this.props.railDisplay}
                          setRailDisplay={this.props.setRailDisplay}
                          userPrincipal={this.props.userPrincipal}
                          portalProperties={this.props.portalProperties}
                          history={this.props.history}
                          menuOptions={this.props.menuOptions}
                          setSelectedTopMenuOption={this.props.setSelectedTopMenuOption}/>
                <Grid id={'im-graph-grid'}>
                    <Favorite favorite={this.state.pageConfig.favorite ?? false}
                              pageType={this.state.pageConfig.pcKey}
                              userCookie={this.props.userCookie}
                              userPrincipal={this.props.userPrincipal}
                              pageId={this.state.pageConfig.page?.id ?? -1}
                              pageGroupChild={false}
                              darkTheme={this.props.darkTheme}
                              loading={this.state.loading}
                              setMenuOptions={this.props.setMenuOptions}/>
                    <Grid.Row
                        id={'db-title-row'}
                        style={{height: '4.5rem'}}
                        columns={1}>
                        <div id={'breadcrumb'} style={{paddingLeft: '1rem', paddingTop: '.5rem'}}>
                            {getPageBreadcrumb(
                                this.state.page.breadcrumb? this.state.page.breadcrumb : [],
                                this.props.portalProperties.uaaHost)}
                        </div>
                        <Grid.Column verticalAlign={'bottom'}>
                           <span className={this.props.darkTheme? 'dark' : ''} id={'dashboard-title'}>
                               Real-Time Reliability
                           </span>
                        </Grid.Column>

                    </Grid.Row>
                    {this.getChartsFromPreferences()}
                    <Grid.Row columns={2} style={{paddingTop: 0}} >
                        <Grid.Column />
                        <GridColumn
                            verticalAlign={'middle'}
                            textAlign={'right'}
                            id={'dark-last-modified-column'}>
                            LAST UPDATED {moment(this.state.lastModified).format('YYYY-MM-DD HH:mm:ss')} CT
                        </GridColumn>
                    </Grid.Row>
                </Grid>
            </div>
        )
    }
}
