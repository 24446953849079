import AxiosService from "./axios-service";
import {ActionResponse, FileSource, FsSecurity} from "../models/models";
import { genericError} from "../util/constants";

export default class FileBrowserService {
    private client: AxiosService;

    constructor(csrfToken: string) {
        this.client = new AxiosService(csrfToken);
    }

    getFileSources = (): Promise<FileSource[]> => {
        const url = `/api/file-browser/file-source`;
        return new Promise<FileSource[]>(((resolve) => {
            this.client.getAxiosInstance().get(url)
                .then(({data}) => {resolve(data)})
                .catch(() => {resolve([])})
        }));
    };

    getFileSourceBySlug = (slug: string): Promise<FileSource> => {
        const url = `/api/file-browser/file-source/${slug}`;
        return new Promise<FileSource>(((resolve) => {
            this.client.getAxiosInstance().get(url)
                .then(({data}) => {resolve(data)})
                .catch(() => {resolve({id:0, fsName: '', fsPath: '', fsSecurity: null})})
        }));
    };

    createFileSource = (fileSource: FileSource): Promise<ActionResponse<FileSource>> => {
        const url = `/api/file-browser/file-source`;
        return new Promise<ActionResponse<FileSource>>(((resolve) => {
            this.client.getAxiosInstance().post(url, fileSource)
                .then(({data}: {data: any}): any => {
                    resolve(data)})
                .catch(() => {resolve(genericError)})
        }));
    };

    updateFileSource = (fileSource: FileSource): Promise<ActionResponse<FileSource>> => {
        const url = `/api/file-browser/file-source`;
        return new Promise<ActionResponse<FileSource>>(((resolve) => {
            this.client.getAxiosInstance().put(url, fileSource)
                .then(({data}: {data: any}): any => {
                    resolve(data)})
                .catch(() => {resolve(genericError)})
        }));
    };

    createFsSecurity = (fsSecurity: any): Promise<ActionResponse<FsSecurity>> => {
        const url = `/api/file-browser/fs-security`;
        return new Promise<ActionResponse<FsSecurity>>(((resolve) => {
            this.client.getAxiosInstance().post(url, fsSecurity)
                .then(({data}) => {resolve(data)})
                .catch(() => {resolve(genericError)})
        }));
    };

    updateFsSecurity = (fsSecurity: any): Promise<ActionResponse<FsSecurity>> => {
        const url = `/api/file-browser/fs-security`;
        return new Promise<ActionResponse<FsSecurity>>(((resolve) => {
            this.client.getAxiosInstance().post(url, fsSecurity)
                .then(({data}) => {resolve(data)})
                .catch(() => {resolve(genericError)})
        }));
    };

    deleteFsSecurity = (fsSecId: number): Promise<ActionResponse<FsSecurity>> => {
        const url = `/api/file-browser/fs-security/${fsSecId}`;
        return new Promise<ActionResponse<FsSecurity>>(((resolve) => {
            this.client.getAxiosInstance().delete(url)
                .then(({data}) => {resolve(data)})
                .catch(() => {resolve(genericError)})
        }));
    };

    deleteFileSource = (fsId: number): Promise<ActionResponse<FileSource>> => {
        const url = `/api/file-browser/file-source/${fsId}`;
        return new Promise<ActionResponse<FileSource>>(((resolve) => {
            this.client.getAxiosInstance().delete(url)
                .then(({data}) => {resolve(data)})
                .catch(() => {resolve(genericError)})
        }));

    }

}