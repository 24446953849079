import React from "react"

/*
 * Component which serves the purpose of a "root route component".
 */
interface PageProps {
    title: string
    component: any
}

interface PageState {}
class RouteRender extends React.Component<PageProps, PageState> {
    constructor(props: PageProps, state: PageState){
        super(props, state);

        this.state = {}
    }

    componentDidMount() {
        document.title = this.props.title
    }

    componentDidUpdate(prevProps: Readonly<PageProps>, prevState: Readonly<PageState>, snapshot?: any) {
        if (this.props.title !== prevProps.title)
            document.title = this.props.title
    }

    render() {
        const PageComponent = this.props.component

        return (
            <PageComponent />
        )
    }
}

export default RouteRender