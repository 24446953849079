import * as React from 'react';
import {Button, Grid, Icon} from "semantic-ui-react";
import {info} from "../../../../services/route-service";
import {NavLink} from "react-router-dom";
import ReactGA from "react-ga4";

interface PageProps {
    history: any
}

interface PageState {
    viewWidth: number
}

export default class PublicHome extends React.Component<PageProps, PageState> {
    constructor(props: PageProps, state: PageState) {
        super(props, state);

        this.updateWindowDimensions = this.updateWindowDimensions.bind(this);

        this.state = {
            viewWidth: 0
        }
    }

    componentDidMount() {
        ReactGA.event({category: "user_page_view", action: "View Public Homepage"});
        this.updateWindowDimensions();
        window.addEventListener('resize', this.updateWindowDimensions);
    }

    updateWindowDimensions() {
        this.setState({ viewWidth: window.innerWidth });
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.updateWindowDimensions);
    }

    render(){
        if (this.state.viewWidth > 768 || (window.innerWidth / window.innerHeight > 1)) {
            return(
                <Grid
                    id={'home-logo-grid'}
                    columns={2} >
                    <Grid.Column
                        textAlign={'center'}
                        verticalAlign={'middle'}>
                        <Grid.Row id={'im-logo-row'}>
                            <img
                                id={'im-logo-img'}
                                src={"/images/im-logo.png"}
                                alt={'IM Logo'}/>
                        </Grid.Row>
                        <Grid.Row id={'im-btn-row'} >
                            <NavLink to={info.imGraphs.path}>
                                <Button
                                    id={'im-see-graph-btn'}
                                    basic={true}>
                                    <Icon name={'arrow left'}/>
                                    SEE GRAPHS
                                </Button>
                            </NavLink>
                        </Grid.Row>
                    </Grid.Column>
                    <Grid.Column textAlign={'center'} verticalAlign={'middle'}>
                        <Grid.Row id={'weis-logo-row'}>
                            <img
                                id={'weis-logo-img'}
                                src={"/images/western-logo-crop.png"}
                                alt={'west Logo'}/>
                        </Grid.Row>
                        <Grid.Row>
                            <NavLink to={info.westGraphs.path}>
                                <Button
                                    id={'west-see-graph-btn'}
                                    basic={true}>
                                    SEE GRAPHS
                                    <Icon name={'arrow right'}/>
                                </Button>
                            </NavLink>
                        </Grid.Row>
                    </Grid.Column>
                    <Icon
                        name={'arrows alternate horizontal'}
                        id={'home-divider-icon'}
                        size={'massive'}/>
                </Grid>
            )

        }
        else
            return(
                <Grid id={'home-logo-grid'} columns={1}>
                    <Grid.Column
                        textAlign={'center'}
                        style={{height: '100%'}}
                        stretched={true}
                        verticalAlign={'middle'}>
                        <Grid.Row id={'im-logo-row'} >
                            <div style={{position: "relative", top: '25%'}}>
                                <img
                                    id={'im-logo-img'}
                                    src={"/images/im-logo.png"}
                                    alt={'IM Logo'}/>

                                <NavLink to={info.imGraphs.path}>
                                    <Button
                                        id={'im-see-graph-btn'}
                                        basic={true}>
                                        <Icon name={'arrow left'}/>
                                        SEE GRAPHS
                                    </Button>
                                </NavLink>

                            </div>
                        </Grid.Row>
                        <Grid.Row id={'weis-logo-row'} verticalAlign={"middle"} textAlign={"center"} >
                            <div style={{position: "relative", top: '40%'}}>
                                <img
                                    id={'weis-logo-img'}
                                    src={"/images/western-logo-crop.png"}
                                    alt={'west Logo'}/>

                                <NavLink to={info.westGraphs.path}>
                                    <Button
                                        id={'west-see-graph-btn'}
                                        basic={true}>
                                        SEE GRAPHS
                                        <Icon name={'arrow right'}/>
                                    </Button>
                                </NavLink>
                            </div>

                        </Grid.Row>
                    </Grid.Column>
                    <Icon
                        name={'arrows alternate horizontal'}
                        id={'home-divider-icon'}
                        size={'massive'}/>
                </Grid>
            )
    }
}