import {ActionResponse, chart, Preference} from "../models/models";
import axios from "axios";
import {genericError} from "../util/constants";

export const getChartPreferences = (xsrfToken: string): Promise<Preference[]> => {
    const url = `/api/preferences/chart`;
    let axiosRequestConfig = {headers: {
            'X-SPP-UI-Token': xsrfToken,
            'Accept': 'application/json',
            'Content-Type': 'application/json',}};
    return new Promise<Preference[]>(((resolve) => {
        axios.get(url, axiosRequestConfig)
            .then(({data}: any) => {resolve(data)})
            .catch(() => {resolve([])})
    }));
};
export const getPreferences = (xsrfToken: string):Promise<Preference[]> => {
    const url = `/api/preferences/`;
    let axiosRequestConfig = {headers: {
            'X-SPP-UI-Token': xsrfToken,
            'Accept': 'application/json',
            'Content-Type': 'application/json',}};
    return new Promise<Preference[]>(((resolve) => {
        axios.get(url, axiosRequestConfig)
            .then(({data}: any) => {resolve(data)})
            .catch(() => {resolve([])})
        }));
}

export const getPreferenceOptions = (xsrfToken: string):Promise<chart[]> => {
    const url = `/api/preferences/options`;
    let axiosRequestConfig = {headers: {
            'X-SPP-UI-Token': xsrfToken,
            'Accept': 'application/json',
            'Content-Type': 'application/json',}};
    return new Promise<chart[]>(((resolve) => {
        axios.get(url, axiosRequestConfig)
            .then(({data}: any) => {resolve(data)})
            .catch(() => {resolve([])})
    }));
}


export const updatePreferences = (xsrfToken: string, preferenceRecord: Record<string, Preference[]>): Promise<ActionResponse<null>> => {
    const url = `/api/preferences/update-list`;
    let axiosRequestConfig = {headers: {
            'X-SPP-UI-Token': xsrfToken,
            'Accept': 'application/json',
            'Content-Type': 'application/json',}};
    return new Promise<ActionResponse<null>>(((resolve) => {
        axios.put(url, preferenceRecord, axiosRequestConfig,)
            .then(({data}: any) => {resolve(data)})
            .catch(() => {resolve(genericError)})
    }));
};

export const updatePreference = (xsrfToken: string, preference: Preference): Promise<ActionResponse<Preference>> => {
    const url = `/api/preferences/`;
    let axiosRequestConfig = {headers: {
            'X-SPP-UI-Token': xsrfToken,
            'Accept': 'application/json',
            'Content-Type': 'application/json',}};
    return new Promise<ActionResponse<Preference>>(((resolve) => {
       axios.put(url, preference, axiosRequestConfig,)
            .then(({data}: any) => {resolve(data)})
            .catch(() => {resolve(genericError)})
    }));
};

export const getPreferenceReliability = (xsrfToken: string):Promise<Preference[]> => {
      const url = `/api/reliability-dash`;
    let axiosRequestConfig = {headers: {
            'X-SPP-UI-Token': xsrfToken,
            'Accept': 'application/json',
            'Content-Type': 'application/json',}};
    return new Promise<Preference[]>(((resolve) => {
        axios.get(url, axiosRequestConfig)
            .then(({data}: any) => {resolve(data)})
            .catch(() => {resolve([])})
    }));
};
