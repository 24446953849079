export const TOGGLE_LOGIN = 'TOGGLE_LOGIN';
export const CHANGE_TOKEN = 'CHANGE_TOKEN';
export const SET_COOKIE = 'SET_COOKIE';
export const GET_USER_PRINCIPAL = 'GET_USER_PRINCIPAL';
export const GET_DARK_THEME = 'GET_DARK_THEME';
export const GET_APP_INFO = 'GET_APP_INFO';
export const GET_SELECTED_TOP_MENU_OPTION = 'GET_SELECTED_TOP_MENU_OPTION';
export const GET_RAIL_DISPLAY = 'GET_RAIL_DISPLAY';
export const GET_MENU_OPTIONS = 'GET_MENU_OPTIONS';
export const GET_PORTAL_PROPERTIES = 'GET_PORTAL_PROPERTIES'
export const GET_TERMS_ACCEPTED = 'GET_TERMS_ACCEPTED';
export const GET_SESSION_ANNOUNCEMENTS = 'GET_SESSION_ANNOUNCEMENTS';
