import {
    GET_APP_INFO, GET_DARK_THEME,
    GET_MENU_OPTIONS, GET_PORTAL_PROPERTIES,
    GET_RAIL_DISPLAY,
    GET_SELECTED_TOP_MENU_OPTION, GET_SESSION_ANNOUNCEMENTS,
    GET_TERMS_ACCEPTED,
    GET_USER_PRINCIPAL,
    SET_COOKIE
} from "./types";
import {AppInfo, MenuOption, PortalProperties, Principal, SessionAnnouncement} from "../models/models";

export const resolvedSetCookie = (data: string) => {
    return {
        type: SET_COOKIE,
        payload: data
    }
};

export const resolvedGetUserPrincipal = (data: Principal) => {
    return {
        type: GET_USER_PRINCIPAL,
        payload: data
    }
};

export const resolvedGetDarkTheme = (data: boolean) => {
    return {
        type: GET_DARK_THEME,
        payload: data
    }
};

export const resolvedTermsAccepted = (data: boolean) => {
    return {
        type: GET_TERMS_ACCEPTED,
        payload: data
    }
};
export const resolvedGetAppInfo = (data: AppInfo) => {
    return {
        type: GET_APP_INFO,
        payload: data
    }
};

export const resolvedGetTopMenuOption = (data: string) => {
    return {
        type: GET_SELECTED_TOP_MENU_OPTION,
        payload: data
    }
}

export const resolvedSetRailDisplay = (data: string) => {
    return {
        type: GET_RAIL_DISPLAY,
        payload: data
    }
}

export const resolvedSetMenuOptions = (data: MenuOption[]) => {
    return {
        type: GET_MENU_OPTIONS,
        payload: data
    }
}

export const resolvedSetPortalProperties = (data: PortalProperties) => {
    return {
        type: GET_PORTAL_PROPERTIES,
        payload: data
    }
}

export const resolvedGetSessionAnnouncements = (data: SessionAnnouncement[]) => {
    return {
        type: GET_SESSION_ANNOUNCEMENTS,
        payload: data
    }
};
