import {MenuOption} from "../models/models";
import {NavLink} from "react-router-dom";
import * as React from "react";

export const getBackgroundImage = (darkTheme: boolean): string => {
    if (darkTheme)
        return 'url(/images/portal-background-dark.png)'
    else
        return 'url(/images/portal-background-light.png)'
};

export const  getMenuOptionHref = (option: MenuOption, uaaHost: string) => {
    if (option.type === 'PAGE')
    {
        if (option.pageType === 'external')
            return option.slug;
        else if (option.slug === 'user-administration')
            return 'https://'+uaaHost+'/manage-users';
        else if (option.slug === 'local-security-administration')
            return 'https://'+uaaHost+'/lsa/manage-users';
        else if (option.slug === 'my-account')
            return 'https://'+uaaHost+'/account-management'
        else if (option.slug === 'admin-pages')
            return '/admin/pages';
        else return '/pages/'+option.slug
    }

    return '/groups/'+option.slug

}

export const getPageBreadcrumb = (options: MenuOption[], uaaHost: string) => {

    let breadcrumb: any[] = [];
    options.forEach(option => {
        if (breadcrumb.length === 0 )
            breadcrumb.push(
                <NavLink
                    key={option.type+'-'+option.slug}
                    to={getMenuOptionHref(option, uaaHost)}>
                    {option.name}
                </NavLink>)
    else
        {
            breadcrumb.push(<span key={option.slug+'-span'}> {" > "} </span>)
            breadcrumb.push(
                <NavLink
                    key={option.type+'-'+option.slug}
                    to={getMenuOptionHref(option, uaaHost)}>
                    {option.name}
                </NavLink>)
        }
    })
    return breadcrumb
}
