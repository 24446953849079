import * as React from 'react';
import './index.css'
import {AppInfo, MenuOption, PortalProperties, Preference, Principal,} from "../../../models/models";
import MenuRail from "../../Common/MenuRail";
import PublicHome from "./Public";
import DefaultHome from "./Default";
import ChartService from "../../../services/chart-service";
import {getChartPreferences} from "../../../services/preference-service";
import PersonalizedHome from "./Personalized";
import {Dimmer, Grid, Loader} from "semantic-ui-react";
import {getBackgroundImage} from "../../../util/functions";


interface PageProps {
    appInfo: AppInfo
    selectedTopMenuOption: string
    setSelectedTopMenuOption: (selectedTopMenuOption: string) => void;
    railDisplay: string
    setRailDisplay: (railDisplay: string) => void
    history: any
    userCookie: any
    menuOptions: MenuOption[]
    portalProperties: PortalProperties;
    userPrincipal: Principal
    darkTheme: boolean
}

interface PageState {
    chartPreferences: Preference[]
    loading: boolean
}

export default class Home extends React.Component<PageProps, PageState> {
    private readonly chartService: ChartService;
    constructor(props: PageProps, state: PageState) {
        super(props, state);

        this.chartService = new ChartService(this.props.userCookie)

        this.state = {
            chartPreferences: [],
            loading: false
        }
    }

    componentDidUpdate(prevProps: Readonly<PageProps>, prevState: Readonly<PageState>, snapshot?: any) {
        if (this.props.userPrincipal !== prevProps.userPrincipal)
            this.getChartPreferenceList();
    }

    componentDidMount() {
        this.getChartPreferenceList();
    }

    getHomeComponent = () => {
        if (!this.state.loading)
        {
            if (!this.props.userPrincipal.unauthenticatedUser && this.props.userPrincipal.userId !== '')
            {
                if (this.state.chartPreferences.length > 0)
                    return <PersonalizedHome history={this.props.history}
                                             darkTheme={this.props.darkTheme}
                                             chartService={this.chartService}
                                             userCookie={this.props.userCookie}
                                             chartPreferences={this.state.chartPreferences}/>
                else
                    return <DefaultHome darkTheme={this.props.darkTheme}
                                        history={this.props.history}
                                        chartService={this.chartService}/>
            }
            else
                return <PublicHome history={this.props.history}/>

        }
        else return <Grid style={{height: '100%'}}/>
    }

    getHomeDivStyle = () => {
        if (this.props.userPrincipal.unauthenticatedUser)
            return {backgroundImage: 'url(/images/portal-background-plain.png)'}
        else
            return {backgroundImage: getBackgroundImage(this.props.darkTheme), minHeight: '650px'}
    }

    getChartPreferenceList = () => {
        this.setState({loading: true})
        getChartPreferences(this.props.userCookie).then(results => {
            this.setState({chartPreferences: results.sort((a, b) => a.position - b.position), loading: false})
        })
    }

    render(){

        return(
            <div id={'home-main-div'} className={'background-image-div'} style={this.getHomeDivStyle()}>
                <Dimmer inverted={!this.props.darkTheme} active={this.state.loading}>
                    <Loader/>
                </Dimmer>
                <MenuRail selectedTopMenuItem={this.props.selectedTopMenuOption}
                          userPrincipal={this.props.userPrincipal}
                          railDisplay={this.props.railDisplay}
                          setRailDisplay={this.props.setRailDisplay}
                          portalProperties={this.props.portalProperties}
                          menuOptions={this.props.menuOptions}
                          history={this.props.history}
                          setSelectedTopMenuOption={this.props.setSelectedTopMenuOption}/>
                {this.getHomeComponent()}
            </div>
        )
    }
}
