import {AppProps} from "../interfaces";
import * as React from "react";
import {compose} from "redux";
import {connect} from "react-redux";
import {mapDispatchToProps, mapStateToProps} from "../../../../store/mappers";
import {Container, Grid} from "semantic-ui-react";
import {withRouter} from "react-router-dom";
import {withCookies} from "react-cookie";
import AppHeader from "./AppHeader";
import Footer from "./Footer";
import {Redirect} from "react-router";
import {info} from "../../../../services/route-service";
import Announcements from "../../Announcements";
import ReactGA from "react-ga4";



const withPublic: any =
    <P extends AppProps> ( WrappedComponent: React.ComponentType<P>) => {
        return (props: any) => {

            //making sure we have a value for the google analytics code before initializing
            if(props.portalProperties.googleAnalytics !== 'G-00000')
                ReactGA.initialize(props.portalProperties.googleAnalytics);

            if (props.termsAccepted || (props.match.path === '/terms-of-use')) {
                return (
                    <Container fluid={true}>
                        <Grid>
                            <Grid.Row columns={1}>
                                <Grid.Column computer={16}>
                                    <AppHeader {...props} />
                                </Grid.Column>
                            </Grid.Row>
                            <Grid.Row className={'pt-component-row'} style={{paddingTop: 0}}>
                                <Grid.Column id={'public-column-wrapper'}  computer={16}>
                                    <Announcements {...props} />
                                    <WrappedComponent {...props} />
                                </Grid.Column>
                            </Grid.Row>
                            <Grid.Row>
                                <Grid.Column computer={16} verticalAlign={'bottom'}>
                                    <Footer {...props}/>
                                </Grid.Column>
                            </Grid.Row>
                        </Grid>
                    </Container>
                )
            }

            // has not accepted terms of use
            return <Redirect to={info.termsOfUse.path} />;
        };
    };

export default compose(connect(mapStateToProps, mapDispatchToProps),  withCookies, withRouter, withPublic);