import {AppProps} from "../interfaces";
import * as React from "react";
import {compose} from "redux";
import {connect} from "react-redux";
import {mapDispatchToProps, mapStateToProps} from "../../../../store/mappers";
import {info} from "../../../../services/route-service";
import {Redirect} from "react-router";


const checkPrincipal: any = <P extends AppProps> ( WrappedComponent: React.ComponentType<P> ) => {
    return (props: P & AppProps) => {
        if (!props.userPrincipal.unauthenticatedUser) {
            return <WrappedComponent {...props} />
        }

        // not authenticated
        return <Redirect to={info.home.path} />;
    };
};

export default compose(connect(mapStateToProps, mapDispatchToProps), checkPrincipal);