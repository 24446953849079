import * as React from 'react';
import './index.css'
import MenuRail from "../../Common/MenuRail";
import {MenuOption, PortalProperties, Principal} from "../../../models/models";
import {Button, Dimmer, Grid, Icon, Loader, Segment} from "semantic-ui-react";
import {getMenuOptionBySlug} from "../../../services/menu-service";
import {emptyMenuOption} from "../../../util/constants";
import parse from 'html-react-parser';
import {getBackgroundImage, getMenuOptionHref, getPageBreadcrumb} from "../../../util/functions";
import {NavLink} from "react-router-dom";
import Favorite from "../../Common/Favorite";
import {ToastComponent} from "../../Common/ToastComponent";
import ReactGA from "react-ga4";

interface PageProps {
    match: any
    userCookie: any
    userPrincipal: Principal;
    history: any
    selectedTopMenuOption: string
    setSelectedTopMenuOption: (selectedTopMenuOption: string) => void;
    setSelectedPageGroupAsPage: (selectedPgAsPage: MenuOption) => void;
    setMenuOptions: (menuOptions: MenuOption[]) => void
    portalProperties: PortalProperties;
    railDisplay: string
    setRailDisplay: (railDisplay: string) => void
    menuOptions: MenuOption[]
    darkTheme: boolean
}

interface PageState {
    menuOption: MenuOption
    showSummary: boolean
    loading: boolean
}

export default class PageGroupAsPage extends React.Component<PageProps, PageState> {
    constructor(props: PageProps, state: PageState) {
        super(props, state);

        this.state = {
            menuOption: emptyMenuOption,
            showSummary: false,
            loading: false
        }
    }

    componentDidUpdate(prevProps: Readonly<PageProps>, prevState: Readonly<PageState>, snapshot?: any) {
        if (this.props.match.params.groupSlug !== prevProps.match.params.groupSlug)
            this.getPageGroup()
        if (this.props.userPrincipal !== prevProps.userPrincipal)
            this.getPageGroup()
        if (this.props.match.params.groupSlug === 'favorite' && prevProps.menuOptions.length === 0 && this.props.menuOptions.length !== 0)
            this.getPageGroup()
        if (this.props.match.params.groupSlug === 'administration' && prevProps.menuOptions.length === 0 && this.props.menuOptions.length !== 0)
            this.getPageGroup()
    }

    componentDidMount() {
        this.getPageGroup()
    }

    getPageGroup = () => {
        this.setState({loading: true})
        if (this.props.match.params.groupSlug === 'favorite') {
            if (this.props.menuOptions[0]?.name.toLowerCase() === 'favorites')
            {
                ReactGA.event({category: "user_page_view", action: "View Favorites Page Group Page"});
                this.setState({menuOption: this.props.menuOptions[0], loading: false});
            }
            else if (this.props.menuOptions.length > 0 && this.props.menuOptions[0]?.name.toLowerCase() !== 'favorites')
                this.props.history.push('/page-not-found');
        }
        else if (this.props.match.params.groupSlug === 'administration') {
            let administration;

            if (this.props.menuOptions.length > 0)
                administration = this.props.menuOptions.find(option => {return option.name.toLowerCase() === 'administration'})

            if (administration) {
                ReactGA.event({category: "user_page_view", action: "View Administration Page Group Page"});
                this.setState({menuOption: administration, loading: false});
            }
            else if  ( this.props.menuOptions.length > 0 && administration === undefined)
                this.props.history.push('/page-not-found');
        }
        else
            getMenuOptionBySlug(this.props.match.params.groupSlug, this.props.userCookie).then(response => {

                if (response.slug !== this.props.match.params.groupSlug)
                    this.props.history.push('/page-not-found');
                else
                {
                    ReactGA.event({category: "user_page_view", action: "View "+response.name+" Page Group Page"});
                    this.setState({menuOption: response, loading: false})
                }
            })
    }

    getIconName = (pageType: string, name: string) => {
        switch (pageType)
        {
            case 'file-browser':
                return 'copy'

            case 'external':
                return 'sign out'

            case 'static':
                if (name === 'Integrated Marketplace Generation Mix')
                    return 'pie chart'

                if (name === 'Your Certificate Information Screen')
                    return 'certificate'

                return 'chart line'

            case 'embedded':
                return 'window maximize outline'

            case 'tableau':
                return 'area chart'

            default:
                if (name.toLowerCase().includes('administration'))
                    return 'setting'

                return 'list'
        }
    }

    formatMenuOptions = () => {
        let childArray: any[] = []

        if (this.state.menuOption.childList.length > 0)
        {
            this.state.menuOption.childList.forEach(option => {
                childArray.push(
                    <div key={option.type+'-'+option.slug} style={{margin: '2rem'}}>
                        <Grid.Row>
                            {this.getChild(option)}
                        </Grid.Row>
                        <Grid.Row>
                            {parse(option.summary? option.summary : '')}
                        </Grid.Row>
                    </div>
                )
            })
            return (
                <Segment id={'file-browser-segment'}>
                    {childArray}
                </Segment>
            )
        }
        else
            return null

    }

    getChild = (child: MenuOption) => {
        if (child.type === 'PAGE' && ( child.pageType === 'external' || child.pageType === null))
            {
                return (
                    <div className={'pgap-menu-option'}>
                        <a  href={getMenuOptionHref(child, this.props.portalProperties.uaaHost)}
                            key={child.type+'-'+child.slug}
                            target={'_blank'}
                            rel="noopener noreferrer">
                            <Button className={this.props.darkTheme ? 'pgap-child-btn-dark' : 'pgap-child-btn'} name={child}>
                                <Icon name={this.getIconName(child.pageType? child.pageType : '', child.name)}/>
                                {child.name}
                            </Button>
                        </a>
                        <span id={'pgap-favorite'} className={'pgap-favorite'}>
                            <Favorite pageType={child.type}
                                      userCookie={this.props.userCookie}
                                      userPrincipal={this.props.userPrincipal}
                                      favorite={child.favorite ?? false}
                                      pageId={child.pageId ?? 0}
                                      pageGroupChild={true}
                                      darkTheme={this.props.darkTheme}
                                      loading={false}
                                      setMenuOptions={this.props.setMenuOptions}/>
                        </span>
                    </div>
                )
            }
        else
            return (
                <div className={'pgap-menu-option'}>
                    <NavLink
                        to={getMenuOptionHref(child, this.props.portalProperties.uaaHost)}
                        key={child.type+'-'+child.slug}
                        className={'pgap-nav-link'}>
                        <Button className={this.props.darkTheme ? 'pgap-child-btn-dark' : 'pgap-child-btn'} name={child}>
                            <Icon name={this.getIconName(child.pageType ? child.pageType : '', child.name)}/>
                            {child.name}
                        </Button>
                    </NavLink>
                    <span id={'pgap-favorite'} className={'pgap-favorite'}>
                        <Favorite pageType={child.type}
                                  userCookie={this.props.userCookie}
                                  userPrincipal={this.props.userPrincipal}
                                  favorite={child.favorite ?? false}
                                  pageId={child.pageId ?? 0}
                                  pageGroupChild={true}
                                  darkTheme={this.props.darkTheme}
                                  loading={false}
                                  setMenuOptions={this.props.setMenuOptions}
                        />
                    </span>
                </div>
            )
    }

    getPageSummary = () => {
        if (this.state.showSummary)
            return (
                <Segment attached={true}>
                    {parse(this.state.menuOption.summary? this.state.menuOption.summary : '')}
                </Segment>
            )
        else
            return null
    }

    getSummaryBtn = () => {
        if (this.state.menuOption.summary && this.state.menuOption.summary.length > 0)
            return (
                <Button
                    name={'summary'}
                    className={'fb-info-btn'}
                    onClick={this.handleButtonClick}
                    fluid={true}
                    attached={'top'}>
                    SUMMARY
                    <Icon className={'see-more-arrow'} name={this.state.showSummary? 'minus' : 'plus'}/>
                </Button>
            )
        else
            return null
    }

    handleButtonClick = (event: any) => {
        this.setState({showSummary: !this.state.showSummary})
    }

    getFavorite = () => {
        if (this.props.match.params.groupSlug !== 'favorite')
            return (
                <Favorite pageId={this.state.menuOption.pageId ?? -1}
                          favorite={this.state.menuOption.favorite ?? false}
                          pageType={'PAGE_GROUP'}
                          userCookie={this.props.userCookie}
                          userPrincipal={this.props.userPrincipal}
                          pageGroupChild={false}
                          darkTheme={this.props.darkTheme}
                          loading={this.state.loading}
                          setMenuOptions={this.props.setMenuOptions}
                />
            )

        return false;
    }

    render(){

        return(
            <div className={'background-image-pg-as-page'} style={{backgroundImage: getBackgroundImage(this.props.darkTheme)}}>
                <Dimmer inverted={!this.props.darkTheme} active={this.state.loading}>
                    <Loader/>
                </Dimmer>
                <MenuRail selectedTopMenuItem={this.props.selectedTopMenuOption}
                          railDisplay={this.props.railDisplay}
                          userPrincipal={this.props.userPrincipal}
                          portalProperties={this.props.portalProperties}
                          setRailDisplay={this.props.setRailDisplay}
                          menuOptions={this.props.menuOptions}
                          setSelectedTopMenuOption={this.props.setSelectedTopMenuOption}
                          history={this.props.history}/>
                <ToastComponent/>
                <Grid style={{height: '100%'}} >
                    <Grid.Row>
                        <Grid.Column className={this.props.darkTheme? ' dark' : ''} id={'fb-title-column'}>
                            <div id={'breadcrumb'}>
                                {getPageBreadcrumb(
                                    this.state.menuOption.breadcrumb? this.state.menuOption.breadcrumb : [],
                                    this.props.portalProperties.uaaHost)}
                            </div>
                            {this.getFavorite()}
                            <div id={'file-browser-title'}>
                                {this.state.menuOption.name}
                            </div>
                            {this.getSummaryBtn()}
                            {this.getPageSummary()}
                            {this.formatMenuOptions()}
                        </Grid.Column>
                    </Grid.Row>
                </Grid>
            </div>
        )
    }
}
