import * as React from 'react';
import ChartService from "../../../services/chart-service";
import 'chartjs-adapter-moment';
import {Pie} from "react-chartjs-2";
import {memo, useEffect, useState} from "react";
import ResizeObserver from "resize-observer-polyfill";
import {chartName, graphUpdateInterval} from "../../../util/constants";
import {Chart, registerables} from "chart.js";
import zoomPlugin from "chartjs-plugin-zoom";
import {ChartData} from "../../../models/models";
import {chartPcValueToConfigValue} from "../../../services/static-service";
Chart.register(...registerables, zoomPlugin);

interface PageProps {
    chartService: ChartService
    updateLoading: (loading: boolean, chartName: string) => void
    updateLastModified: (lastModified: Date) => void
    loading: boolean
    align?: string
    darkTheme?: boolean
}

const ImGenMix: React.FC<PageProps> = (props: PageProps) => {
    const [chartData, setChartData] = useState<ChartData>({data: [], label: [], backgroundColor: []});
    const [showLegend, setShowLegend] = useState<boolean>(false);
    let ref: any = React.createRef();

    useEffect(() => {
        let getChartData = () => {
            props.updateLoading(true, chartName.IM_GEN_MIX)
            props.chartService.getChartData(chartPcValueToConfigValue(chartName.IM_GEN_MIX)).then(response => {

                let data: any = [];
                response.response.datasets.forEach((d: any) => {
                    data.push(parseFloat(d.data.pop()))
                })

                setChartData({data:data, label: response.pieLabels, backgroundColor: response.pieColors})
                props.updateLastModified(new Date(response.response.lastModifiedDate))

                if (data.length !== 0)
                    props.updateLoading(false, chartName.IM_GEN_MIX)
            })
        };

        if (props.loading)
            getChartData()

        let interval = setInterval(() => {
            getChartData();
        }, graphUpdateInterval); //fetch data every 5 min

        const resizeObserver = new ResizeObserver((entries: ResizeObserverEntry[]) => {
            setShowLegend(entries[0].target.clientHeight > 220)})

        resizeObserver.observe(ref.current);

        return () => {
            clearInterval(interval)
            resizeObserver.disconnect()}; // clean up

    }, [props.loading]) // eslint-disable-line

    //unmount data cleanup
    useEffect( () => () => {
        setChartData({data: [], label: []})
        setShowLegend(false)
    }, [] );

    let updateLoadComplete = () => {
        if (chartData.data.length > 0 || chartData.label.length > 0 )
            props.updateLoading(false, chartName.IM_GEN_MIX)
    };


    let getPieChart = () => {
        if (props.loading)
            return null
        else
            return (
                <Pie type={'pie'}
                     data={{
                         labels: chartData.label,
                         datasets: [{
                             data: chartData.data,
                             backgroundColor: chartData.backgroundColor}]
                     }}
                     options={{
                         plugins: {
                             legend: {
                                 display: showLegend,
                                 position: 'left',
                                 align: props.align ? props.align : 'center',
                                 labels: {
                                     generateLabels(chart: any) {
                                         const data = chart.data;
                                         var allData = data.datasets[0].data;
                                         var total = 0;
                                         for (var i in allData) {
                                             total += allData[i];
                                         }
                                         if (data.labels.length && data.datasets.length) {
                                             const {labels: {pointStyle, color}} = chart.legend.options;

                                             return data.labels.map((label: any, i: any) => {
                                                 const meta = chart.getDatasetMeta(0);
                                                 const style = meta.controller.getStyle(i);
                                                 var valuePercentage = (data.datasets[0].data[i] / total) * 100;
                                                 if (isNaN(valuePercentage))
                                                     return {text: '', fillStyle: 'white', strokeStyle: 'white'}

                                                 return {
                                                     text: label + ": (" + valuePercentage.toFixed(4) + "%)" ,
                                                     fillStyle: style.backgroundColor,
                                                     strokeStyle: props.darkTheme? '#23262b' : style.borderColor,
                                                     fontColor: color,
                                                     lineWidth: style.borderWidth,
                                                     pointStyle: pointStyle,
                                                     hidden: !chart.getDataVisibility(i),

                                                     // Extra data used for toggling the correct item
                                                     index: i
                                                 };
                                             });
                                         }
                                         return [];
                                     }
                                 },
                             },
                             tooltip: {
                                 callbacks: {
                                     label: function(tooltipItem: any) {
                                         var allData = tooltipItem.dataset.data;
                                         var tooltipLabel = tooltipItem.label;
                                         var tooltipData = tooltipItem.raw;
                                         var total = 0;
                                         for (var i in allData) {
                                             total += allData[i];
                                         }
                                         var tooltipPercentage = (tooltipData / total) * 100;
                                         if (isNaN(tooltipPercentage))
                                             return null;

                                         return tooltipLabel + ': (' + tooltipPercentage.toFixed(4) + '%)';
                                     }
                                 }
                             },
                         },
                         responsive: true,
                         maintainAspectRatio: false,
                         animation: {
                             duration: 10,
                             onComplete: () => updateLoadComplete()
                         }
                     }}
                />
            )
    }

    return (
        <div ref={ref} style={{height: '100%'}}>
            {getPieChart()}
        </div>
    )
}
export default memo(ImGenMix)