import * as React from 'react';
import {Grid, Input} from "semantic-ui-react";
import ReactTable from "./Table"
import TableButtonGroup from "./TableButtonGroup";
import TableActionBar from "./TableActionBar";

interface PageProps {
    loading?: boolean
    defaultPageLength?: number
    selectable?: boolean
    bottomMargin?: boolean
    tableData: any[]
    headers: {}[]
    resetSelect?: boolean
    tableButtonGroupVisible: boolean
    createRowButton?: boolean
    downloadTableButton?: boolean
    refreshTableButton?: boolean
    handleTableRefresh?: Function
    handleCreateRow?: Function
    handleTableDownload?: Function
    customTableButtonGroupButtons?: any[]
    editSelectedRow?:boolean
    deleteSelectedRow?:boolean
    selectButtonWording?: string
    handleRowEdit?: Function
    handleRowDelete?: Function
    sendSelectedToParent?: Function
    darkTheme?: boolean
}

interface PageState {
    selected: null | number
    filterReset: boolean
    pageNumberReset: boolean
    selectedRow: any
}

class SppReactTable extends React.Component<PageProps, PageState> {
    constructor(props: PageProps, state: PageState){
        super(props, state);

        this.state = {
            selected: null,
            filterReset: true,
            pageNumberReset: false,
            selectedRow: null
        }
    }

    // Method for the refresh button in the Table Button Group component
    handleButtonRefresh = () => {
        if(this.props.handleTableRefresh !== undefined)
        {
            this.props.handleTableRefresh();
            this.setState({filterReset: true, pageNumberReset: true, selected: null, selectedRow: null})
        }
    };

    setPageNumberResetState = (state: boolean) => {
        this.setState({pageNumberReset: state})
    };

    // Method to reset the selected and selectedRow back to null
    resetSelect = () => {
        if(this.props.resetSelect)
            this.setState({selected: null, selectedRow: null});
    };

    //selection reset biased on resetSelect boolean
    componentDidUpdate(prevProps: Readonly<PageProps>, prevState: Readonly<PageState>, snapshot?: any): void {
        if(this.props.resetSelect !== prevProps.resetSelect)
            this.resetSelect();
    }

    getTableLoadingState = () => {
        if(this.props.loading !== undefined)
            return this.props.loading;
        else return false;
    };

    defaultColumnFilter = ({column: { filterValue, preFilteredRows, setFilter }} : {column: any}) => {

        //on change event for table filter inputs
        let handleInputChange = (e: any) => {
            setFilter(e.target.value);
            this.setState({filterReset: false})
        };
        return (
            <Input
                value={filterValue || ''}
                fluid={true}
                size={"mini"}
                onChange={handleInputChange}
            />
        )
    };

    getClassName = () => {
        if (this.props.bottomMargin === undefined)
            return '';
        else
            return 'bottom-margin'

    };

    // Method to send the selected row back to the parent component
    sendSelectedToParent = (row: any) => {
        if( this.props.sendSelectedToParent !== undefined)
            this.props.sendSelectedToParent(row)
    };


    getDefaultTableLength =() => {
        if (this.props.defaultPageLength === undefined)
            return 10;
        else
            return this.props.defaultPageLength
    };

    getCreateRowButtonBool = () => {
        if(this.props.createRowButton === undefined)
            return false;
        else
            return this.props.createRowButton
    };

    getDownloadTableButtonBool = () => {
        if(this.props.downloadTableButton === undefined)
            return false;
        else
            return this.props.downloadTableButton
    };
    getRefreshButtonBool = () => {
        if(this.props.refreshTableButton === undefined)
            return false;
        else
            return this.props.refreshTableButton
    };

    getSelectable = () => {
        if(this.props.selectable !== undefined)
            return this.props.selectable;
        else
            return false;

    };

    getEditRowBool = () => {
        if(this.props.editSelectedRow !== undefined)
            return this.props.editSelectedRow;
        else
            return false
    };

    getDeleteRowBool = () => {
        if(this.props.deleteSelectedRow !== undefined)
            return this.props.deleteSelectedRow;
        else
            return false
    };



    render(){
        return(
            <Grid id={'paginated-table-grid'}>
                <Grid.Row id={'action-bar-table-buttons-row'} style={{paddingBottom: 0}} columns={2}>
                    <Grid.Column width={12}>
                        <TableActionBar
                            selectable={this.getSelectable()}
                            rowSelected={this.state.selectedRow}
                            editRow={this.getEditRowBool()}
                            deleteRow={this.getDeleteRowBool()}
                            customEditRowWording={this.props.selectButtonWording}
                            handledEditRowClick={this.props.handleRowEdit}
                            handleDeleteRowClick={this.props.handleRowDelete}
                        />
                    </Grid.Column>
                    <Grid.Column textAlign={'right'} width={4}>
                        <TableButtonGroup
                            visible={this.props.tableButtonGroupVisible}
                            createButton={this.getCreateRowButtonBool()}
                            handleCreate={this.props.handleCreateRow}
                            refreshButton={this.getRefreshButtonBool()}
                            handleTableRefresh={this.handleButtonRefresh}
                            downloadButton={this.getDownloadTableButtonBool()}
                            handleDownload={this.props.handleTableDownload}
                            customButtons={this.props.customTableButtonGroupButtons}/>
                    </Grid.Column>
                </Grid.Row>
                <Grid.Row id={'react-table-row'}>
                    <Grid.Column id={'react-table-grid'}>
                        <ReactTable  columns={this.props.headers}
                                     loading={this.getTableLoadingState()}
                                     darkTheme={this.props.darkTheme? this.props.darkTheme : false}
                                     className={this.getClassName()}
                                     defaultColumnFilter = {this.defaultColumnFilter}
                                     filterReset={this.state.filterReset}
                                     pageNumberReset={this.state.pageNumberReset}
                                     setPageNumberReset={this.setPageNumberResetState}
                                     defaultPageLength={this.getDefaultTableLength()}
                                     // tslint:disable-next-line
                                     getRowProps={(row: any) => ({
                                         onClick: () => {
                                             if(this.props.selectable === false)
                                                 return;

                                             if(this.state.selected === row.index) {
                                                 this.setState({selected: null, selectedRow: null});
                                                 this.sendSelectedToParent(null)
                                             }
                                             else
                                             {
                                                 if(this.props.sendSelectedToParent !== undefined)
                                                 {
                                                     this.setState({selected: row.index,
                                                         selectedRow: row.original});
                                                     this.sendSelectedToParent(row.original)
                                                 }
                                                 else
                                                     this.setState({selected: row.index,
                                                         selectedRow: row.original});
                                             }


                                         },
                                         style: {
                                             background: row.index === this.state.selected ?
                                                 'royalblue'  : null,
                                             color: row.index === this.state.selected ? 'white' : 'black'
                                         }
                                    })}
                                    data={this.props.tableData} />
                    </Grid.Column>
                </Grid.Row>
            </Grid>
        )
    }

}

export default SppReactTable;