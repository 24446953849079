
import * as React from "react";
import {
    Button,
    ButtonProps,
    Card,
    CardProps,
    Divider, DropdownProps,
    Form,
    Grid,
    Header,
    Icon,
    Label,
    Segment
} from "semantic-ui-react";
import RLDD from "react-list-drag-and-drop/lib/RLDD";
import {info} from "../../../../../services/route-service";
import SppModal from "../../../../Common/SppModal";
import {DropDownOption, ModalContent, Page, PageGroup} from "../../../../../models/models";
import {cloneDeep} from "lodash";
import toast from "../../../../../util/toast";
import PageService from "../../../../../services/page-service";
import {emptyPageGroup} from "../../../../../util/constants";

interface PageProps {
    pageClient: PageService
    parentPageGroupId: any
    history: any
    pageGroups: PageGroup[]
    pageGroupOptions: DropDownOption[]
    compareChildren: (a: any, b: any) => number
}

interface PageState {
    pageChildren: any[]
    modalOpen: boolean
    modalContent: ModalContent
    selectedName: { title: string, slug: string }
    selected: boolean
    buttonPageLoading: boolean
    parentPageGroup: PageGroup
}

export default class PageChildren extends React.Component<PageProps, PageState> {
    constructor(props: PageProps, state: PageState) {
        super(props, state);

        this.state = {
            pageChildren: [],
            modalOpen: false,
            modalContent: {iconName: '', modalTitle: '', onClose: ()=>{}, modalBody: ''},
            selectedName: { title: '', slug: '' },
            selected: false,
            buttonPageLoading: false,
            parentPageGroup: emptyPageGroup
        }
    }

    componentDidMount() {
        this.getPageChildren()
    }

    pageChildRenderer = (item: any, index: number): JSX.Element => {
        let parity = () => {
            if(index % 2 === 0)
                return 'even'
            else
                return 'odd'
        };
        return (
            <Card id={'page-child-'+parity()}
                  className={this.getClassname(item)}
                  fluid={true}
                  name={item}
                  onClick={this.handleCardClick}>
                <Card.Content>
                    <Card.Header>{item.title}</Card.Header>
                    <Card.Meta>
                        {item.slug}
                        <Button.Group id={'pg-child-card'}  floated={'right'} className={this.getClassname(item)}>
                            <Button name={item} className={'child-button-group'} onClick={this.handleButtonBarClick} content={'View'}/>
                            <Button name={item} className={'child-button-group'} onClick={this.handleButtonBarClick} content={'Move'}/>
                            <Button name={item} className={'child-button-group'} onClick={this.handleButtonBarClick} content={'Delete'}/>
                        </Button.Group>
                    </Card.Meta>
                    <Card.Description>
                        Current Sort Order: {item.sortOrder} ------- New Sort Order: {index}
                    </Card.Description>
                </Card.Content>
            </Card>
        )
    };

    getClassname = (item: Page) => {
        if(this.state.selectedName.title === item.title && this.state.selectedName.slug === item.slug && this.state.selected)
            return 'selected'
        else return ''
    }

    handleCardClick = (event: any, {name}: CardProps) => {
        if(this.state.selectedName.title === name.title && this.state.selectedName.slug === name.slug )
            this.setState({selectedName: {title: '', slug: ''}, selected: false})
        else
            this.setState({selectedName: {title: name.title, slug: name.slug}, selected: true})
    }

    handleButtonBarClick = (event: any, {name, content}: ButtonProps) => {
        switch (content)
        {
            case 'View':
                this.props.history.push(info.managePages.path+'/'+name.id)
                break;
            case 'Move':
                this.setState({modalOpen: true,
                    parentPageGroup: this.props.pageGroups.filter(pg => pg.id === parseInt(this.props.parentPageGroupId))[0]})
                this.getMoveModalContent(name)
                break;
            case 'Delete':
                this.setState({modalOpen: true})
                this.getDeleteModalContent(name)
                break;
        }
    };

    handleMoveSelectOnChange = (event: any, data: DropdownProps) => {
        event.preventDefault()
        let parentPGArray = cloneDeep(this.props.pageGroups);
        let parentPG = parentPGArray.filter(pg => pg.id === data.value)[0]
        this.setState({parentPageGroup: parentPG})
    }

    getMoveModalContent = (item: any) => {
        const modalContent = (
            {
                iconName: 'exchange',
                modalTitle: 'Move Page to New Page Group',
                onClose: this.closeModal,
                modalBody:
                    <Form>
                        <Form.Select options={this.props.pageGroupOptions}
                                     onChange={this.handleMoveSelectOnChange}
                                     defaultValue={item.pageGroup.id}
                                     name={'parentPageGroup'}
                                     search={true}
                                     placeholder={'Select a Page Group'}
                                     label={'New Page Group'}/>
                        <Grid>
                            <Grid.Row>
                                <Grid.Column textAlign={'right'}>
                                    <Button className={'form-close-button'}
                                            color={'red'}
                                            loading={false}
                                            type={'button'}
                                            onClick={this.closeModal}
                                            content={'CANCEL'}/>
                                    <Button color={'green'}
                                            loading={false}
                                            onClick={this.handleMoveFormSubmit}
                                            name={item}
                                            className={'form-action-button'}
                                            content={'MOVE PAGE'}/>
                                </Grid.Column>
                            </Grid.Row>
                        </Grid>
                    </Form>
            }
        )
        this.setState({modalContent: modalContent})
    };

    handleMoveFormSubmit = (event: any, {name}: ButtonProps) => {
        name.pageGroup = this.state.parentPageGroup;

        this.props.pageClient.updatePage(name)
            .then(response => {
                if (response.ok) {
                    this.closeModal()
                    this.getPageChildren()
                    toast.success(response.message)
                } else
                    toast.error(response.message);
            })
    }

        getDeleteModalContent = (item: any) => {
        const modalContent = (
            {
                iconName: 'file excel outline',
                modalTitle: 'Delete Page',
                onClose: this.closeModal,
                modalBody:  <Grid id={'conf-info-content'}>
                    <Grid.Row>
                        <Grid.Column>
                            Are you sure you want to delete page titled: {item.title}?
                        </Grid.Column>
                    </Grid.Row>
                    <Grid.Row>
                        <Grid.Column textAlign={'right'}>
                            <Button className={'modal-action-button'}
                                    onClick={this.closeModal}
                                    color={'red'}
                                    id={'cm-negative-button'}
                                    content={'NO'}/>
                            <Button onClick={this.submitDeletePage}
                                    color={'green'}
                                    name={item}
                                    id={'cm-positive-button'}
                                    className={'modal-action-button'}
                                    content={'YES'}/>
                        </Grid.Column>
                    </Grid.Row>
                </Grid>,
            }
        )
        this.setState({modalContent: modalContent})
    };

    submitDeletePage = (event: any, {name}: ButtonProps) => {
        this.props.pageClient.getPage(name.id).then(response => {
            this.props.pageClient.deletePage(response)
                .then(response => {
                    if (response.ok) {
                        this.closeModal()
                        this.getPageChildren()
                        toast.success(response.message)
                    } else
                        toast.error(response.message);
                })
        })
    }

    closeModal = () => {
        this.setState({modalOpen: false,
            parentPageGroup: this.props.pageGroups.filter(pg => pg.id === parseInt(this.props.parentPageGroupId))[0]})
    };

    handlePageChange = (reorderedItems: Array<any>) => {
        this.setState({ pageChildren: reorderedItems });
    };

    updatePageSortOrder = () => {
        this.setState({buttonPageLoading: true})
        let pages = cloneDeep(this.state.pageChildren);
        let last = pages.length -1;

        pages.forEach((page: Page, index) => {
            page.sortOrder=index
            this.props.pageClient.updatePage(page).then(response => {
                if(response.ok)
                    toast.success(response.message)
                else toast.error(response.message)

                if(index === last)
                {
                    this.setState({buttonPageLoading: false})
                    this.getPageChildren()
                }
            })
        })
    }

    getPageChildren = () => {
        this.props.pageClient.getPagesByPageGroup(this.props.parentPageGroupId).then((response: Page[]) => {
            response.sort(this.props.compareChildren)
            this.setState({pageChildren: response})
        })
    }


    render(){
        return(
            <Segment padded={true} id={'page-children'}>
                <Label attached='top'>Sorted Second</Label>
                <Divider horizontal={true} hidden={true}>
                    <Header as='h4'>
                        <Icon name='copy outline' />
                        Pages
                    </Header>
                </Divider>
                <RLDD
                    cssClasses="list-container"
                    items={this.state.pageChildren}
                    itemRenderer={this.pageChildRenderer}
                    onChange={this.handlePageChange}/>
                <Grid id={'page-children-grid'}>
                    <Grid.Row>
                        <Grid.Column textAlign={'right'}>
                            <Button id={'cp-cancel-button'}
                                    type={'button'}
                                    icon={'arrow left'}
                                    color={'grey'}
                                    onClick={()=>{this.props.history.push(info.pageGroups.path)}}
                                    content={'RETURN TO PAGE GROUP'}/>
                            <Button color={'green'}
                                    onClick={this.updatePageSortOrder}
                                    loading={this.state.buttonPageLoading}
                                    className={'form-action-button'}
                                    disabled={this.state.pageChildren.length<1}
                                    content={'UPDATE PAGE SORT ORDER'}/>
                        </Grid.Column>
                    </Grid.Row>
                </Grid>
                <SppModal
                    open={this.state.modalOpen}
                    modalContent={this.state.modalContent}/>
            </Segment>
        )
    }
}