import * as React from 'react';
import {Button, Dimmer, Grid, GridColumn, Icon, Loader, Segment} from "semantic-ui-react";
import moment from "moment";
import ImLoadForecast from "../../../Common/Charts/ImForcaseSummary";
import ImGenMix from "../../../Common/Charts/ImGenMix";
import ImACE from "../../../Common/Charts/ImACE";
import ChartService from "../../../../services/chart-service";
import PageConfigService from "../../../../services/page-config-service";
import {PageConfig} from "../../../../models/models";
import {chartName, emptyPage} from "../../../../util/constants";
import {info} from "../../../../services/route-service";
import {NavLink} from "react-router-dom";
import ReactGA from "react-ga4";

interface PageProps {
    history: any
    chartService: ChartService
    darkTheme: boolean
}

interface PageState {
    aceLoading: boolean
    genMixLoading: boolean
    forecastLoading: boolean
    lastModified: Date
    chartPageConfigs: PageConfig[]
}

export default class DefaultHome extends React.Component<PageProps, PageState> {
    _isMounted = false;
    private readonly pageConfigService: PageConfigService;
    constructor(props: PageProps, state: PageState) {
        super(props, state);

        this.pageConfigService = new PageConfigService('')

        this.state = {
            aceLoading:  true,
            genMixLoading:  true,
            forecastLoading:  true,
            lastModified: new Date(0),
            chartPageConfigs: []
        }
    }

    componentDidMount() {
        this._isMounted = true;
        let chartNameList = [chartName.IM_GEN_MIX, chartName.IM_ACE, chartName.IM_LOAD_FORECAST]
        this.pageConfigService.getPageConfigByChartNames(chartNameList)
            .then(results => {
                if (this._isMounted)
                {
                    this.setState({chartPageConfigs: results})
                    ReactGA.event({category: "user_page_view", action: "View Default User Homepage"});
                }
            })
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    updateLoadingStatus = (loading: boolean, name: string) => {
        if (this._isMounted)
            switch (name) {
                case chartName.IM_LOAD_FORECAST:
                    this.setState({forecastLoading: loading})
                    break;
                case chartName.IM_ACE:
                    this.setState({aceLoading: loading})
                    break;
                case chartName.IM_GEN_MIX:
                    this.setState({genMixLoading: loading})
                    break;
        }
    }

    updateLastModified = (lastModified: Date) => {
        if (lastModified > this.state.lastModified && this._isMounted)
            this.setState({lastModified: lastModified})
    }

    getPageByChartName = (chartName: string) => {
        let pc = this.state.chartPageConfigs.find(chart => chart.pcValue === chartName);
        if (pc !== undefined && pc.page !== undefined)
            return pc.page
        else return emptyPage
    }

    render(){
        return(
            <Grid id={'im-graph-grid'}>
                <Grid.Row
                    id={'db-title-row'}
                    columns={2}>
                    <Grid.Column verticalAlign={'middle'} >
                       <span id={'dashboard-title'} className={this.props.darkTheme? ' dark' : ''}>
                           Welcome to your SPP Portal
                       </span>
                    </Grid.Column>
                    <GridColumn
                        verticalAlign={'middle'}
                        textAlign={'right'}>
                        <NavLink to={info.dashboardSettings.path}>
                            <Button color={'grey'}>
                                EDIT SETTINGS
                            </Button>
                        </NavLink>
                    </GridColumn>
                </Grid.Row>
                <Grid.Row className={'db-chart-row'} columns={2}>
                    <Grid.Column width={6} className={this.props.darkTheme? 'chart-column dark' : 'chart-column'}>
                        <Segment.Group className={'chart-sg'} >
                            <Segment
                                content={this.getPageByChartName(chartName.IM_GEN_MIX).title}
                                className={'chart-title-sg'}/>
                            <Segment className={'chart-segment'}>
                                <Dimmer active={this.state.genMixLoading} inverted={!this.props.darkTheme}>
                                    <Loader/>
                                </Dimmer>
                                <ImGenMix
                                    updateLastModified={this.updateLastModified}
                                    chartService={this.props.chartService}
                                    loading={this.state.genMixLoading}
                                    darkTheme={this.props.darkTheme}
                                    updateLoading={this.updateLoadingStatus}/>
                            </Segment>
                            <NavLink to={'pages/'+this.getPageByChartName(chartName.IM_GEN_MIX).slug}>
                                <Button
                                    className={'narrow-btn graph-btn'}
                                    attached='bottom'
                                    color={'grey'}>
                                    SEE MORE
                                    <Icon className={'see-more-arrow'} name={'arrow right'}/>
                                </Button>
                            </NavLink>
                        </Segment.Group>
                    </Grid.Column>
                    <GridColumn width={10} className={this.props.darkTheme? 'chart-column dark' : 'chart-column'}>
                        <Segment.Group className={'chart-sg'}>
                            <Segment
                                content={this.getPageByChartName(chartName.IM_ACE).title}
                                className={'chart-title-sg'}/>
                            <Segment className={'chart-segment'}>
                                <Dimmer active={this.state.aceLoading} inverted={!this.props.darkTheme}>
                                    <Loader/>
                                </Dimmer>
                                <ImACE
                                    updateLastModified={this.updateLastModified}
                                    chartService={this.props.chartService}
                                    loading={this.state.aceLoading}
                                    updateLoading={this.updateLoadingStatus}/>
                            </Segment>
                            <NavLink to={'pages/'+this.getPageByChartName(chartName.IM_ACE).slug}>
                                <Button
                                    className={'narrow-btn graph-btn'}
                                    attached='bottom'
                                    color={'grey'}>
                                    SEE MORE
                                    <Icon className={'see-more-arrow'} name={'arrow right'} />
                                </Button>
                            </NavLink>
                        </Segment.Group>
                    </GridColumn>
                </Grid.Row>
                <Grid.Row className={'db-chart-row'} columns={1}>
                    <Grid.Column className={this.props.darkTheme? 'chart-column dark' : 'chart-column'}>
                        <Segment.Group className={'chart-sg'}>
                            <Segment
                                content={this.getPageByChartName(chartName.IM_LOAD_FORECAST).title}
                                className={'chart-title-sg'}/>
                            <Segment className={'chart-segment'}>
                                <Dimmer active={this.state.forecastLoading} inverted={!this.props.darkTheme}>
                                    <Loader/>
                                </Dimmer>
                                <ImLoadForecast
                                    updateLastModified={this.updateLastModified}
                                    loading={this.state.forecastLoading}
                                    chartService={this.props.chartService}
                                    updateLoading={this.updateLoadingStatus}/>
                            </Segment>
                            <NavLink to={'pages/'+this.getPageByChartName(chartName.IM_LOAD_FORECAST).slug}>
                                <Button
                                    className={'narrow-btn graph-btn'}
                                    attached='bottom'
                                    color={'grey'}>
                                    SEE MORE
                                    <Icon className={'see-more-arrow'} name={'arrow right'} />
                                </Button>
                            </NavLink>
                        </Segment.Group>
                    </Grid.Column>
                </Grid.Row>
                <Grid.Row columns={2} style={{paddingTop: 0}} >
                    <Grid.Column />
                    <GridColumn
                        verticalAlign={'middle'}
                        textAlign={'right'}
                        id={'dark-last-modified-column'}>
                        LAST UPDATED {moment(this.state.lastModified).format('YYYY-MM-DD HH:mm:ss')} CT
                    </GridColumn>
                </Grid.Row>
            </Grid>
        )
    }
}