import * as React from 'react';
import './index.css'
import {MenuOption, Page, PageConfig, PortalProperties, Principal} from "../../../../models/models";
import {Grid, GridColumn, GridRow, Icon, Label} from "semantic-ui-react";
import {emptyPage, emptyPageConfig} from "../../../../util/constants";
import {getPageBreadcrumb} from "../../../../util/functions";
import Favorite from "../../../Common/Favorite";
import ReactGA from "react-ga4";

interface PageProps {
    pageConfig: PageConfig
    embeddedTitleConfig: PageConfig
    userCookie: any
    darkTheme: boolean
    portalProperties: PortalProperties
    userPrincipal: Principal
    setMenuOptions: (menuOptions: MenuOption[]) => void
}

interface PageState {
    page: Page
}

export default class EmbeddedPage extends React.Component<PageProps, PageState> {
    constructor(props: PageProps, state: PageState) {
        super(props, state);

        this.state = {
            page: emptyPage,
        }
    }

    componentDidMount() {
        if (this.props.pageConfig.page !== undefined)
        {
            ReactGA.event({category: "user_page_view", action: "View "+ this.props.pageConfig.page.title +" Embedded Page"});
            this.setState({page: this.props.pageConfig.page})
        }
    }

    showTitle = () => {
        if (this.props.embeddedTitleConfig === emptyPageConfig)
            return true
        else return this.props.embeddedTitleConfig.pcValue.toLowerCase() === 'true';
    }

    getTitle = () => {
        if (this.showTitle())
            return (
                <GridRow id={'em-title-row'} >
                    <div id={'breadcrumb'}>
                        {getPageBreadcrumb(
                            this.state.page.breadcrumb? this.state.page.breadcrumb : [],
                            this.props.portalProperties.uaaHost)}
                    </div>
                    <div id={'embedded-title'} className={this.props.darkTheme? ' dark' : ''}>
                            <span>
                                {this.state.page.title}
                            </span>
                        <span
                            title={this.props.pageConfig.pcValue}
                            id={'external-app-btn'}
                            onClick={() => window.open(this.props.pageConfig.pcValue, '_blank')}>
                                External Application
                                <Icon name={'log out'} id={'external-app-icon'}/>
                        </span>
                    </div>
                </GridRow>
            )
        else
            return null
    }

    getColumnStyle = () => {
        if(this.showTitle())
            return {paddingBottom: 0}
        else return {padding: 0}
    }

    getIframeHeight = () => {
        if (this.showTitle())
            return 'calc(100vh - (130px + 28px + 70px))'
        else
            return 'calc(100vh - (130px + 28px))'
    }

    getLabel = () => {
        if (this.showTitle())
            return null
        else return (
            <Label
                title={this.props.pageConfig.pcValue}
                onClick={() => window.open(this.props.pageConfig.pcValue, '_blank')}
                id={'title-label'}>
                External Application
            </Label>
        )
    }

    getBreadcrumbLabel = () => {
        if (this.showTitle())
            return null
        else return (
            <Label id={'breadcrumb-label'}>
                {getPageBreadcrumb(
                    this.state.page.breadcrumb? this.state.page.breadcrumb : [],
                    this.props.portalProperties.uaaHost)}
                <Favorite pageId={this.props.pageConfig.page?.id ?? -1}
                          userCookie={this.props.userCookie}
                          pageType={this.showTitle() ? this.props.pageConfig.pcKey : "embedded no title"}
                          userPrincipal={this.props.userPrincipal}
                          favorite={this.props.pageConfig.favorite ?? false}
                          pageGroupChild={false}
                          darkTheme={this.props.darkTheme}
                          loading={false}
                          setMenuOptions={this.props.setMenuOptions}/>
            </Label>
        )
    }

    getIframeShowTitleClass = () => {
        if (this.showTitle())
            return 'showing-title'
        else
            return 'no-title'
    }

    render(){
        return(
            <Grid style={{height: '100%'}}>
                <GridColumn style={this.getColumnStyle()} id={'embedded-grid-column'} className={this.props.darkTheme? ' dark' : ''}>
                    {this.getTitle()}
                    {this.getLabel()}
                    {this.getBreadcrumbLabel()}
                    <iframe
                        src={this.props.pageConfig.pcValue}
                        title={this.state.page.title}
                        className={this.props.darkTheme? ' dark '+this.getIframeShowTitleClass() : this.getIframeShowTitleClass()}
                        id={'embedded-frame'}
                        style={{height: this.getIframeHeight()}}/>
                </GridColumn>
            </Grid>
        )
    }
}