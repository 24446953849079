import ImGenMix from "../components/Common/Charts/ImGenMix";
import ImLoadForecast from "../components/Common/Charts/ImForcaseSummary";
import ImLmpTrend from "../components/Common/Charts/ImLmpTrend";
import ImACE from "../components/Common/Charts/ImACE";
import WeisInterchangeTrend from "../components/Common/Charts/WeisInterchangeTrend";
import WesiLoadForecast from "../components/Common/Charts/WesiLoadForecast";
import ImGenMixYTD from "../components/Common/Charts/ImGenMixYTD";
import ImGenMix365 from "../components/Common/Charts/ImGenMix365";
import ImInterchangeTrend from "../components/Common/Charts/ImInterchangeTrend";
import * as React from "react";
import ChartService from "./chart-service";
import {chart} from "../models/models";
import WeisGenMix from "../components/Common/Charts/WeisGenMix";


export const getChartComponent = ( pageConfigValue: string, updateLastModified: (lastModified: Date) => void,
                                   service: ChartService, loading: boolean, darkTheme: boolean,
                                   updateLoading: (loading: boolean, chartName: string) => void, zoom: boolean ) => {
    switch (pageConfigValue) {
        case 'generation-mix':
            return (
                <ImGenMix
                    updateLastModified={updateLastModified}
                    chartService={service}
                    loading={loading}
                    updateLoading={updateLoading}
                    darkTheme={darkTheme}
                    align={'start'}/>
            )

        case 'forecast-vs-actual':
            return (
                <ImLoadForecast
                    updateLastModified={updateLastModified}
                    chartService={service}
                    loading={loading}
                    updateLoading={updateLoading}
                    zoom={zoom}/>
        )

        case 'interchange-trend':
            return (
                <ImInterchangeTrend
                    updateLastModified={updateLastModified}
                    chartService={service}
                    loading={loading}
                    updateLoading={updateLoading}
                    zoom={zoom}/>
        )

        case 'hub-and-interface-prices':
            return (
                <ImLmpTrend
                    updateLastModified={updateLastModified}
                    chartService={service}
                    loading={loading}
                    updateLoading={updateLoading}
                    zoom={zoom}/>
            )

        case 'ace-chart':
            return (
                <ImACE
                    updateLastModified={updateLastModified}
                    chartService={service}
                    loading={loading}
                    updateLoading={updateLoading}
                    zoom={zoom}/>
        )

        case 'weis-interchange-trend':
            return (
                <WeisInterchangeTrend
                    updateLastModified={updateLastModified}
                    chartService={service}
                    loading={loading}
                    updateLoading={updateLoading}
                    zoom={zoom}/>
        )

        case 'weis-forecast-vs-actual':
            return (
                <WesiLoadForecast
                    updateLastModified={updateLastModified}
                    chartService={service}
                    loading={loading}
                    updateLoading={updateLoading}
                    zoom={zoom}/>
        )

        case 'generation-mix-ytd':
            return (
                <ImGenMixYTD
                    updateLastModified={updateLastModified}
                    chartService={service}
                    loading={loading}
                    updateLoading={updateLoading}
                    zoom={zoom}/>
        )

        case 'generation-mix-rolling-365':
            return (
                <ImGenMix365
                    updateLastModified={updateLastModified}
                    chartService={service}
                    loading={loading}
                    updateLoading={updateLoading}
                    zoom={zoom}/>
        )

        case 'weis-gen-mix':
            return (
                <WeisGenMix
                    updateLastModified={updateLastModified}
                    chartService={service}
                    loading={loading}
                    updateLoading={updateLoading}
                    darkTheme={darkTheme}
                    align={'start'}/>
            )

        default:
            return null
    }
}

export const chartPcValueToConfigValue = (pcValue: string): string => {
    switch (pcValue) {
        case 'generation-mix':
            return 'gen-mix';

        case 'generation-mix-ytd':
            return 'gen-mix-ytd';

        case 'generation-mix-rolling-365':
            return 'gen-mix-365';

        case 'forecast-vs-actual':
            return 'load-forecast';

        case 'weis-forecast-vs-actual':
            return 'weis-load-forecast';

        case 'ace-chart':
            return 'ace';

        case 'hub-and-interface-prices':
            return 'lmp-trend';

        default:
            return pcValue
    }
}

export const chartConfigValueToPcValue = (chartConfig: string) => {
    switch (chartConfig) {
        case 'gen-mix':
            return 'generation-mix';

        case 'gen-mix-ytd':
            return 'generation-mix-ytd';

        case 'gen-mix-365':
            return 'generation-mix-rolling-365';

        case 'load-forecast':
            return 'forecast-vs-actual';

        case 'weis-load-forecast':
            return 'weis-forecast-vs-actual';

        case 'ace':
            return 'ace-chart';

        case 'lmp-trend':
            return 'hub-and-interface-prices';

        default:
            return chartConfig
    }
}

export const charts = ():chart[] => {
    return [
        {category: 'Integrated Marketplace', name: 'ACE', value: 'ace-chart'},
        {category: 'Integrated Marketplace', name: 'Forecast vs Actual', value: 'forecast-vs-actual'},
        {category: 'Integrated Marketplace', name: 'Generation Mix', value: 'generation-mix'},
        {category: 'Integrated Marketplace', name: 'Generation Mix Rolling 365', value: 'generation-mix-rolling-365'},
        {category: 'Integrated Marketplace', name: 'Generation Mix YTD', value: 'generation-mix-ytd'},
        {category: 'Integrated Marketplace', name: 'Interchange Trend', value: 'interchange-trend'},
        {category: 'Integrated Marketplace', name: 'Hub and Interface Prices', value: 'hub-and-interface-prices'},
        {category: 'Western Services', name: 'Interchange Trend', value: 'weis-interchange-trend'},
        {category: 'Western Services', name: 'Forecast Summary', value: 'weis-forecast-vs-actual'},
        {category: 'Western Services', name: 'Generation Mix', value: 'weis-gen-mix'}
    ]
}

export const getChartByValue = (options: chart[], value: string):chart | undefined  => {
    let filteredChart: chart[] =  options.filter(chart => {return chart.value === value})
    if (filteredChart.length> 0)
        return filteredChart[0]
    else
        return undefined
}
