import * as React from 'react';
import './index.css'
import {Certificate, MenuOption, Principal, PortalProperties, Page} from "../../../models/models";
import toast from '../../../util/toast'
import MenuRail from "../../Common/MenuRail";
import {
    Button,
    Divider,
    Form,
    Grid,
    Segment,
    Table,
    Icon,
    FormInputProps,
    FormTextAreaProps,
    Popup
} from "semantic-ui-react";
import {getCertificateInfo} from "../../../services/certificate-service";
import {ToastComponent} from "../../Common/ToastComponent";
import ReactGA from "react-ga4";
import {getBackgroundImage, getPageBreadcrumb} from "../../../util/functions";
import PageConfigService from "../../../services/page-config-service";
import {emptyPage} from "../../../util/constants";

interface PageProps {
    userCookie: any
    userPrincipal: Principal
    history: any
    selectedTopMenuOption: string
    setSelectedTopMenuOption: (selectedTopMenuOption: string) => void;
    railDisplay: string
    portalProperties: PortalProperties
    setRailDisplay: (railDisplay: string) => void
    menuOptions: MenuOption[]
    darkTheme: boolean
}

interface PageState {
    cert: Certificate
    email: string
    nerc: string
    comments: string
    hoveringOverCommentsPopup: boolean
    emailError: boolean
    page: Page
}

export default class CertInfo extends React.Component<PageProps, PageState> {

    private pageConfigClient: PageConfigService;

    constructor(props: PageProps, state: PageState) {
        super(props, state);

        this.pageConfigClient = new PageConfigService(this.props.userCookie)

        this.state = {
            cert: {
                certSerial: '',
                certIssuer: '',
                certSubject: '',
                certExpiration: '',
                certExpiresInDays: 0,
                formattedExpirationText: ''
            },
            email: '',
            comments: '',
            nerc: '',
            hoveringOverCommentsPopup: false,
            emailError: false,
            page: emptyPage
        };
    }

    componentDidMount(): void {
        this.getCertificate();
        ReactGA.event({category: "user_page_view", action: "View Certificate Information"});
        this.getPageFromSlug()
    }

    getPageFromSlug = () => {
        this.pageConfigClient.getPageConfigByPage('your-certificate-information').then(response => {
            this.setState({page: response[0].page? response[0].page : emptyPage})
        })
    }

    getCertificate = () => {
        getCertificateInfo(this.props.userCookie).then( (response: Certificate) => {
            this.setState({cert: response})
            }
        )
    }

    handleFormInputChange = (event: any, {name, value}: FormInputProps | FormTextAreaProps) => {
        switch (name)
        {
            case 'email':
                if (this.state.emailError)
                    this.setState({email: value, emailError: !this.isValidEmail(value)});
                else
                    this.setState({email: value});
                break;
            case 'nerc':
                this.setState({nerc: value});
                break;
            case 'comments':
                this.setState({comments: value});
                break;
        }
    }

    generateInfo = () => {
        return 'Portal Environment:\n' + this.props.portalProperties.environmentName + '\n\n'
            + 'Certificate Subject:\n' + this.state.cert.certSubject + '\n\n'
            + 'Certificate Issuer:\n' + this.state.cert.certIssuer + '\n\n'
            + 'Certificate Serial Number:\n' + this.state.cert.certSerial + '\n\n'
            + 'User Entered NERC ID:\n' + this.state.nerc + '\n\n'
            + 'User Entered Comments:\n' + this.state.comments;
    }

    generateEmail = () => {
        if (this.state.email.length > 0) {
            if (this.isValidEmail(this.state.email))
            {
                this.setState({emailError: false})
                const mailtoLink = `mailto:${this.state.email}?subject=Certificate%20Information&body=${encodeURIComponent(this.generateInfo())}`
                window.location.href = mailtoLink;
            }
            else
                this.setState({emailError: true});
        }
    }

    isValidEmail = (email: string) => {
        let validEmailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/

        return validEmailRegex.test(email);
    }

    copyToClipboard = () => {
        navigator.clipboard.writeText(this.generateInfo());
        toast.success('Copied to clipboard!');
    }

    getDividerContent = () => {
        return (<div>
                    <Icon className={'email-icon-cip'}
                          name={'mail'}/>
                    Email Or Copy
                    <Icon className={'clipboard-icon-cip'}
                          name={'clipboard'}/>
                </div>
        );
    }

    getEmailInput = () => {
        return (
            <Form.Input name={'email'}
                        icon={<Icon name={'mail'} color={'black'}/>}
                        required={true}
                        label={'Email To'}
                        id={'ci-email'}
                        placeholder={'Enter an email address'}
                        onChange={this.handleFormInputChange}
                        error={this.state.emailError}/>
        )
    }

    getScreen = () => {
        if (this.state.cert.certSubject && this.state.cert.certSerial && this.state.cert.certIssuer && this.state.cert.certExpiration)
            {
                return (
                    <Grid className={'grid-cip'}>
                        <Grid.Column className={'cert-column-cip'}>
                            <Segment.Group className={this.props.darkTheme? ' dark' : ''} >
                                <Segment content={"Your Certificate Information"}
                                         data-testid={'cert-segment-header'}
                                         className={'chart-title-sg'}/>
                                <Segment id={'ci-body-segment'}>
                                    <h3 className={'table-header-cip'}>
                                        Certificate Information
                                    </h3>
                                    <Table>
                                        <Table.Body>
                                            <Table.Row>
                                                <Table.Cell className={'bold-cell-cip'}>
                                                    Header
                                                </Table.Cell>
                                                <Table.Cell className={'bold-cell-cip'}>
                                                    Value
                                                </Table.Cell>
                                            </Table.Row>
                                            <Table.Row>
                                                <Table.Cell textAlign={"left"}
                                                            width={3}>
                                                    Certificate Serial Number:
                                                </Table.Cell>
                                                <Table.Cell textAlign={"left"}
                                                            data-testid={'cert-serial-value'}
                                                            width={13}>
                                                    {this.state.cert.certSerial}
                                                </Table.Cell>
                                            </Table.Row>
                                            <Table.Row>
                                                <Table.Cell>
                                                    Certificate Issuer DN:
                                                </Table.Cell>
                                                <Table.Cell>
                                                    {this.state.cert.certIssuer}
                                                </Table.Cell>
                                            </Table.Row>
                                            <Table.Row>
                                                <Table.Cell>
                                                    Certificate Subject DN:
                                                </Table.Cell>
                                                <Table.Cell>
                                                    {this.state.cert.certSubject}
                                                </Table.Cell>
                                            </Table.Row>
                                            <Table.Row>
                                                <Table.Cell>
                                                    Certificate Expiration:
                                                </Table.Cell>
                                                <Table.Cell>
                                                    {this.state.cert.formattedExpirationText}
                                                </Table.Cell>
                                            </Table.Row>
                                        </Table.Body>
                                    </Table>
                                    <Divider className={'divider-cip'}
                                             content={this.getDividerContent()}
                                             horizontal/>
                                    <Form>
                                        <Form.Group widths={'equal'}>
                                            <Popup className={'email-error-popup-cip'}
                                                   trigger={this.getEmailInput()}
                                                   content={'Must provide a valid email.'}
                                                   open={this.state.emailError}/>
                                            <Form.Input name={'nerc'}
                                                        icon={<Icon className={'power-icon-cip'} name={'power cord'}/>}
                                                        id={'ci-nerc'}
                                                        iconPosition={'left'}
                                                        label={'NERC Entity Code (Optional)'}
                                                        placeholder={'Enter a NERC code'}
                                                        onChange={this.handleFormInputChange}/>
                                        </Form.Group>
                                        <Form.TextArea name={'comments'}
                                                       label={'Comments (Optional, maximum 750 characters)'}
                                                       placeholder={'Enter a comment'}
                                                       id={'ci-comment'}
                                                       maxLength={'750'}
                                                       onChange={this.handleFormInputChange}/>
                                        <Grid>
                                            <Grid.Row className={'button-row-cip'}
                                                      columns={'1'}
                                                      textAlign={'right'}>
                                                <Grid.Column>
                                                    <Button className={'email-btn-cip'}
                                                            content={'SEND THIS INFORMATION'}
                                                            onClick={this.generateEmail}/>
                                                    <Button className={'clipboard-btn-cip'}
                                                            content={'COPY THIS INFORMATION'}
                                                            type={'button'}
                                                            onClick={this.copyToClipboard}/>
                                                </Grid.Column>
                                            </Grid.Row>
                                        </Grid>
                                    </Form>
                                </Segment>
                            </Segment.Group>
                        </Grid.Column>
                    </Grid>
                )
            }
        else
            return (
                <Grid className={'grid-cip'}>
                    <Grid.Column className={'no-cert-cip'}>
                        <Segment.Group className={this.props.darkTheme? ' dark' : ''}>
                            <Segment content={'Your Certificate Information'}
                                     id={'cert-segment-header'}
                                     className={'chart-title-sg'}/>
                            <Segment>
                                <p className={'tou-body'}>
                                    No certificate information found. To retrieve certificate, please restart your
                                    web browser, return to this site, and choose a valid certificate when prompted.
                                </p>
                            </Segment>
                        </Segment.Group>
                    </Grid.Column>
                </Grid>
            )
    }

    render(){
        return(
            <div className={'background-image-tou'} style={{backgroundImage: getBackgroundImage(this.props.darkTheme)}}>
                <ToastComponent/>
                <MenuRail selectedTopMenuItem={this.props.selectedTopMenuOption}
                          railDisplay={this.props.railDisplay}
                          setRailDisplay={this.props.setRailDisplay}
                          portalProperties={this.props.portalProperties}
                          userPrincipal={this.props.userPrincipal}
                          menuOptions={this.props.menuOptions}
                          setSelectedTopMenuOption={this.props.setSelectedTopMenuOption}
                          history={this.props.history}/>
                <div id={'breadcrumb'} className={'cert-info-bc'} style={{paddingLeft: '4rem', paddingTop: '1rem', fontSize: '14px'}}>
                    {getPageBreadcrumb(
                        this.state.page.breadcrumb? this.state.page.breadcrumb : [],
                        this.props.portalProperties.uaaHost)}
                </div>
                {this.getScreen()}
            </div>
        )
    }
}
