import * as React from 'react';
import {ModalContent, PageConfig} from "../../../models/models";
import PageService from "../../../services/page-service";
import {Button, Grid, Segment} from "semantic-ui-react";
import SppReactTable from "../../Common/PaginatedTable/SppReactTable";
import {getPageHeaders} from "../../../services/table-header-service";
import SppModal from "../../Common/SppModal";
import {
    emptyPage, emptyPageConfig, filteredOutConfigKeys, pageTypeKey,
//    pageTypeKey
} from "../../../util/constants";
import toast from "../../../util/toast"
import PageConfigService from "../../../services/page-config-service";
import {info} from "../../../services/route-service";

interface PageProps {
    userCookie: any
    history: any
}

interface PageState {
    pages: any[]
    loading: boolean
    modalOpen: boolean
    selectedPage: any,
    resetTableSelection: boolean,
    buttonLoading: boolean
}

class Pages extends React.Component<PageProps, PageState> {
    private pageClient: PageService;
    private pageConfigClient: PageConfigService;

    constructor(props: PageProps, state: PageState){
        super(props, state);

        this.state = {
            pages: [],
            loading: false,
            modalOpen: false,
            selectedPage: emptyPageConfig,
            resetTableSelection: false,
            buttonLoading: false,
        };

        this.pageClient = new PageService(this.props.userCookie);
        this.pageConfigClient = new PageConfigService(this.props.userCookie);
    }

    componentDidMount(): void {
        this.getPages();
    }

    //returns list of all page config objects
    getPages = () => {
        this.setState({loading: true});
        this.pageConfigClient.getPageConfigs()
            .then((response: PageConfig[]) => {
                const pageConfigs  = response.map(pageConfig => {
                    let getPage = () => {
                        if(pageConfig.page !== undefined)
                            return pageConfig.page;
                        else
                            return emptyPage
                    };
                    return {
                        id: getPage().id,
                        title: getPage().title,
                        slug: getPage().slug,
                        activeFlag: getPage().activeFlag.toString(),
                        isPublic: getPage().isPublic.toString(),
                        summary: getPage().summary,
                        pageTypeName: getPage().pageType.name,
                        pageGroupName: getPage().pageGroup.name,
                        pageType: getPage().pageType,
                        pageGroup: getPage().pageGroup,
                        depth: getPage().depth,
                        pcKey: pageConfig.pcKey,
                        pcValue: pageConfig.pcValue,
                        pcId: pageConfig.id
                    }
                });


                let filteredPageConfigs = pageConfigs.filter(pageConfig =>
                    (this.filterPageConfigs(pageConfig)));
                this.setState({pages: filteredPageConfigs, loading: false})
            })
    };

    filterPageConfigs = (pageConfig: any): boolean => {
         if (filteredOutConfigKeys.includes(pageConfig.pcKey))
             return false

        if (pageConfig.pageType.name === 'tableau' && pageConfig.pcKey === pageTypeKey.TABLEAU_VIEW_NAME)
            return false

        return true
    }

    //opens create page modal and sets modal type
    createRowOnClick = () => {
        this.props.history.push(info.createPage.path)
    };

    //fetches page authz access, sets all the form states based on selected row and opens edit modal
    editRowOnClick = () => {
        this.props.history.push(info.managePages.path+'/'+this.state.selectedPage.id);
    };

    //opens delete page modal and sets modal type
    deleteRowOnClick = () => {
        this.setState({modalOpen: true})
    };

    //makes delete page axios call and returns action response. closes modal if successful
    handleDeleteSubmit = () => {
        this.setState({resetTableSelection: true, buttonLoading: true});
        this.pageClient.getPage(this.state.selectedPage.id || 0).then(response => {
            this.pageClient.deletePage(response)
                .then(response => {
                    this.setState({resetTableSelection: false, buttonLoading: false});
                    if (response.ok) {
                        this.getPages();
                        this.closeModal();
                        toast.success('Page Successfully Deleted')
                    } else
                        toast.error(response.message);
                })
        })
    };

    //retrieves the row object from paginated table when a row is selected then sets it as a local state to this component
    setSelectedPage = (row: PageConfig) => {
        if(row !== null)
            this.setState({selectedPage: row});
        else
            this.setState({selectedPage: emptyPageConfig});
    };

    //creates and returns the modalContent object for the SPP Modal component
    getModalContent = (): ModalContent => {
        return(
            {
                iconName: 'file excel outline',
                modalTitle: 'Delete Page',
                onClose: this.closeModal,
                modalBody: this.getModalBody(),
            }
        )
    };

    //resets on the form states and closes the modal
    closeModal = () => {
        this.setState({modalOpen: false,})
    };

    //returns modal body for modal content object based on modal type
    getModalBody = () => {
        let getPageTitle = () => {
            if(this.state.selectedPage.title !== undefined)
                return this.state.selectedPage.title;
            else
                return '';
        };

        return(
            <Grid id={'conf-info-content'}>
                <Grid.Row>
                    <Grid.Column>
                        Are you sure you want to delete page titled: {getPageTitle()}?
                    </Grid.Column>
                </Grid.Row>
                <Grid.Row>
                    <Grid.Column textAlign={'right'}>
                        <Button className={'modal-action-button'}
                                onClick={this.closeModal}
                                color={'red'}
                                id={'cm-negative-button'}
                                content={'NO'}/>
                        <Button onClick={this.handleDeleteSubmit}
                                color={'green'}
                                id={'cm-positive-button'}
                                className={'modal-action-button'}
                                content={'YES'}/>
                    </Grid.Column>
                </Grid.Row>
            </Grid>
        );
    };

    render(){
        return(
        <Segment>
            <SppReactTable
                tableData={this.state.pages}
                headers={getPageHeaders()}
                selectable={true}
                tableButtonGroupVisible={true}
                createRowButton={true}
                refreshTableButton={true}
                handleTableRefresh={this.getPages}
                editSelectedRow={true}
                handleRowEdit={this.editRowOnClick}
                deleteSelectedRow={true}
                handleRowDelete={this.deleteRowOnClick}
                selectButtonWording={'Edit Page'}
                sendSelectedToParent={this.setSelectedPage}
                resetSelect={this.state.resetTableSelection}
                loading={this.state.loading}
                handleCreateRow={this.createRowOnClick}
            />
            <SppModal
                open={this.state.modalOpen}
                modalContent={this.getModalContent()}/>
        </Segment>
        )
    }
}

export default Pages;
