import * as React from 'react';
import PageGroupService, { getPageGroups } from "../../../services/page-group-service";
import SppReactTable from "../../Common/PaginatedTable/SppReactTable";
import {getPageGroupHeaders} from "../../../services/table-header-service";
import {Button, Form, Segment, Grid, DropdownProps, FormCheckboxProps, FormInputProps} from "semantic-ui-react";
import {DropDownOption, ModalContent, PageGroup, PortalProperties} from "../../../models/models";
import SppModal from "../../Common/SppModal";
import {emptyPageGroup, modalTypes} from "../../../util/constants";
import {CKEditor} from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import toast from "../../../util/toast";
import { cloneDeep } from 'lodash';
import {info} from "../../../services/route-service";

interface PageProps {
    userCookie: any,
    history: any
    portalProperties: PortalProperties
}

interface PageState {
    pageGroups: any[]
    modalOpen: boolean
    modalType: string
    parentPageGroups: PageGroup[]
    name: string
    slug: string
    summary: string
    parentPageGroup: PageGroup
    selectedPageGroup: any
    resetSelectedRow: boolean
    tableLoading: boolean
    buttonLoading: boolean
    asPage: boolean
}

class PageGroups extends React.Component<PageProps, PageState> {
    private pageGroupClient: PageGroupService;

    constructor(props: PageProps, state: PageState){
        super(props, state);

        this.state = {
            pageGroups: [],
            modalOpen: false,
            modalType: '',
            parentPageGroups: [],
            summary: '',
            name: '',
            slug: '',
            parentPageGroup: emptyPageGroup,
            selectedPageGroup: emptyPageGroup,
            resetSelectedRow: false,
            tableLoading: false,
            buttonLoading: false,
            asPage: false
        };

        this.pageGroupClient = new PageGroupService(this.props.userCookie)
    }

    componentDidMount(): void {
        this.getPageGroups();
        this.getParentPageGroups();
    }

    componentDidUpdate(prevProps: Readonly<PageProps>, prevState: Readonly<PageState>, snapshot?: any): void {
        //resetting the selected row when opening the create modal
        if (this.state.modalOpen !== prevState.modalOpen && this.state.modalOpen && this.state.modalType === modalTypes.CREATE) {
            this.setState({resetSelectedRow: false})
        }
    }

    //returns list of all page group objects
    getPageGroups = () => {
        this.setState({tableLoading: true});
        getPageGroups(this.props.userCookie)
            .then((response: PageGroup[]) => {
                const pageGroups = response.map(pageGroup => {
                    let getPageGroupName = (pageGroup: PageGroup) => {
                        if (pageGroup.pageGroup === undefined || pageGroup.pageGroup === null)
                            return '';
                        else
                            return pageGroup.pageGroup.name
                    };

                    let getAsPage = (pageGroup: any) => {
                        if (pageGroup === undefined || pageGroup === null)
                            return '';
                        else
                            return pageGroup.toString()
                    };

                    return {
                        id: pageGroup.id,
                        name: pageGroup.name,
                        slug: pageGroup.slug,
                        pageGroupName: getPageGroupName(pageGroup),
                        pageGroup: pageGroup.pageGroup,
                        sortOrder: pageGroup.sortOrder,
                        summary: pageGroup.summary,
                        asPage: getAsPage(pageGroup.asPage),
                        depth: pageGroup.depth
                    }
                });
                this.setState({pageGroups: pageGroups, tableLoading: false})
            })
    };

    //fetches all possible page group and sets the parent page group dropdown
    getParentPageGroups = () => {
        getPageGroups(this.props.userCookie)
            .then((response: PageGroup[]) => {
                this.setState({parentPageGroups: response});
            } )
    };
    
    hasValidDepth = (pageGroup: PageGroup, asPage: boolean) => {
        let maxMenuDepth = this.props.portalProperties.maxMenuDepth
        
        if ( (asPage && pageGroup.depth < maxMenuDepth) || (!asPage && pageGroup.depth < maxMenuDepth - 1) )
            return true
        
        return false
    }
    
    getValidParentPageGroups = (): DropDownOption[] => {
        let getSlug = (slug?: string) => {
            if (slug === null || slug === '' || slug === undefined)
                return 'none';
            else
                return slug};
        
        let validParents = this.state.parentPageGroups.filter( pageGroup => { return this.hasValidDepth(pageGroup, this.state.asPage); } );
        
        let parentOptions = validParents.map(pageGroup => {
            return {key: pageGroup.id.toString(), text: pageGroup.name + ' - '+getSlug(pageGroup.slug), value: pageGroup.id}
        });
        parentOptions.unshift({key: '0', text: 'Select Parent Group...', value: 0});
        
        return parentOptions;
    }

    //opens create page modal and sets modal type. also resets controlled input values
    createRowOnClick = () => {
        //resetting the row and clearing all input states when opening the create modal
        this.setState({
            resetSelectedRow: true,
            name: '',
            slug: '',
            modalType: modalTypes.CREATE,
            parentPageGroup: emptyPageGroup,
            asPage: false,
            summary: '',
            modalOpen: true,})
    };

    //opens update page
    updateRowOnClick = () => {
        this.props.history.push(info.pageGroups.path+'/'+this.state.selectedPageGroup.id)
    };

    //opens delete page modal and sets modal type
    deleteRowOnClick = () => {
        this.setState({modalOpen: true, modalType: modalTypes.DELETE})
    };

    //handles create or update axios call method based on modal type
    handleFormSubmit = (event: any) => {
        this.setState({buttonLoading: true, resetSelectedRow: true});

        let getParentPageGroup = (pageGroup: PageGroup) => {
            if (pageGroup === emptyPageGroup)
                return undefined;
            else
                return pageGroup
        };



        let newPageGroup: PageGroup = {
            id: 0,
            name: this.state.name,
            slug: this.state.slug,
            pageGroup: getParentPageGroup(this.state.parentPageGroup),
            asPage: false,
            summary: this.state.summary,
            sortOrder: null,
            depth: 0
        };

        this.pageGroupClient.createPageGroup(newPageGroup).then(response => {
            this.setState({buttonLoading: false, resetSelectedRow: false});
            if (response.ok && response.item !== undefined)
                {
                    this.props.history.push(info.pageGroups.path+'/'+response.item.id)
                }
                else
                    toast.error(response.message);
            })
    };

    //makes delete page axios call and returns action response. closes modal if successful
    handleDeleteSubmit = () => {
        this.setState({resetSelectedRow: true, buttonLoading: true});
        this.pageGroupClient.deletePageGroup(this.state.selectedPageGroup.id).then(response => {
            this.setState({resetSelectedRow: false, buttonLoading: false});
            if (response.ok)
            {
                this.getPageGroups();
                this.getParentPageGroups();
                this.closeModal();
                toast.success('Page Group Deleted Successfully');
            }
            else
                toast.error(response.message);
        })
    };

    //retrieves the row object from paginated table when a row is selected then sets it as a local state to this component
    setSelectedPageGroup = (row: any) => {

        let getParentPageGroup = (pageGroup: PageGroup) => {
            if (pageGroup === null || pageGroup === undefined)
                return emptyPageGroup;
            else
                return pageGroup
        };

        if (row !== null)
            this.setState({
                selectedPageGroup: row,
                summary: row.summary,
                name: row.name,
                slug: row.slug,
                parentPageGroup: getParentPageGroup(row.pageGroup),
                asPage: (row.asPage === 'true')});
        else
            this.setState({selectedPageGroup: emptyPageGroup});
    };

    //creates and returns the modalContent object for the SPP Modal component
    getModalContent = (): ModalContent => {
        return(
            {
                iconName: this.getModalIcon(),
                modalTitle: this.getModalTitle(),
                onClose: this.closeModal,
                modalBody: this.getModalBody()
            }
        )
    };

    //gets the modal icon based on modal type
    getModalIcon = () => {
        switch (this.state.modalType) {
            case modalTypes.CREATE:
                return 'plus';
            case modalTypes.DELETE:
                return 'trash alternate';
            default:
                return '';
        }

    };

    //gets the modal title based on modal type
    getModalTitle = () => {
        switch (this.state.modalType) {
            case modalTypes.CREATE:
                return 'Create Page Group';
            case modalTypes.DELETE:
                return 'Delete Page Group';
            default:
                return '';
        }
    };

    //closes modal and resets relevant states
    closeModal = () => {
        let selectedPageGroup = cloneDeep(this.state.selectedPageGroup);
        let getPageGroup = (pageGroup: any) => {
            if (pageGroup === '' || pageGroup === null || pageGroup === undefined)
                return emptyPageGroup;
            else return pageGroup
        };

        this.setState({
            modalOpen: false,
            modalType: '',
            summary: selectedPageGroup.summary,
            name: selectedPageGroup.name,
            slug: selectedPageGroup.slug,
            parentPageGroup: getPageGroup(selectedPageGroup.pageGroup)
        })
    };

    //sets value states based on dropdown selection
    handleSelectChange = (e: any, {name, value}: DropdownProps) => {

        if (value === 0)
            this.setState({parentPageGroup: emptyPageGroup});

        let selectedParentPageGroup = this.state.pageGroups.find(pg => pg.id === value);

        if (selectedParentPageGroup !== undefined)
            this.setState({parentPageGroup: selectedParentPageGroup})
    };

    handleCheckBoxChange = (event: any, {checked}: FormCheckboxProps) => {
        if (checked !== undefined)
        {
            let stateUpdate = {asPage: checked};
            if (!this.hasValidDepth(this.state.parentPageGroup, checked))
                stateUpdate["parentPageGroup"] = emptyPageGroup;
        
            this.setState(stateUpdate);
        }
    };

    //sets the summary value state from the ckEditor rich text box
    handleSummaryOnBlur = (event: any, editor: any) => {
        this.setState({summary: editor.getData()})
    };

    //sets the states of the input based on value
    handleInputChange = (event: any, {name, value}: FormInputProps) => {
        if (name === 'name')
            this.setState({name: value, slug: (value.replace(/ /g, '-')).toLowerCase()});
        if (name === 'slug')
            this.setState({slug: (value.replace(/ /g, '-')).toLowerCase()});
    };

    //returns modal body for modal content object based on modal type
    getModalBody = () => {
        if (this.state.modalType === modalTypes.DELETE)
            return this.getDeleteModalBody();
        else
            return this.getFormModalBody()
    };

    //returns delete modal body content
    getDeleteModalBody = () => {
        return (
            <Grid id={'conf-info-content'}>
                <Grid.Row>
                    <Grid.Column>
                        Are you sure you want to delete Page Group: {this.state.selectedPageGroup.name}?
                    </Grid.Column>
                </Grid.Row>
                <Grid.Row>
                    <Grid.Column textAlign={'right'}>
                        <Button className={'modal-action-button'}
                                onClick={this.closeModal}
                                color={'red'}
                                loading={this.state.buttonLoading}
                                id={'cm-negative-button'}
                                content={'NO'}/>
                        <Button onClick={this.handleDeleteSubmit}
                                color={'green'}
                                id={'cm-positive-button'}
                                loading={this.state.buttonLoading}
                                className={'modal-action-button'}
                                content={'YES'}/>
                    </Grid.Column>
                </Grid.Row>
            </Grid>
        )

    };

    //returns modal body content for create modal, sets default value based on modal type
    getFormModalBody = () => {

        return (
            <Form onSubmit={this.handleFormSubmit}>
                <Form.Input label={'Name'}
                            value={this.state.name}
                            onChange={this.handleInputChange}
                            placeholder={'Page Group Name'}
                            name={'name'}
                            required={true}/>

                <Form.Input label={'Slug'}
                            value={this.state.slug}
                            placeholder={'Page Group Slug'}
                            onChange={this.handleInputChange}
                            required={true}
                            name={'slug'}/>

                <Form.Select options={this.getValidParentPageGroups()}
                             value={this.state.parentPageGroup.id}
                             onChange={this.handleSelectChange}
                             search={true}
                             placeholder={'Select a Parent Group'}
                             label={'Parent Group'}/>

                {this.getSummaryInput()}
                {this.getFormButtons()}
            </Form>
        )
    };

    getSummaryInput = () => {
        return (
            <Form.Field>
                <label>Summary</label>
                <CKEditor
                    editor={ClassicEditor}
                    data={this.state.summary}
                    onBlur={this.handleSummaryOnBlur}/>
            </Form.Field>
        );
    };

    //returns form buttons based on modal type. button action handled on form object through onSubmit
    getFormButtons = () => {
        return(
            <Form.Button floated={"right"}
                         style={{marginBottom: '1rem'}}
                         loading={this.state.buttonLoading}
                         content={"CREATE PAGE GROUP"}
                         positive={true}/>
        );
    };

    render() {
        return (
            <Segment id={'page-group-table'}>
                <SppReactTable
                    tableButtonGroupVisible={true}
                    refreshTableButton={true}
                    loading={this.state.tableLoading}
                    handleTableRefresh={this.getPageGroups}
                    createRowButton={true}
                    handleCreateRow={this.createRowOnClick}
                    selectable={true}
                    editSelectedRow={true}
                    handleRowEdit={this.updateRowOnClick}
                    sendSelectedToParent={this.setSelectedPageGroup}
                    deleteSelectedRow={true}
                    handleRowDelete={this.deleteRowOnClick}
                    selectButtonWording={'Edit Page Group'}
                    tableData={this.state.pageGroups}
                    headers={getPageGroupHeaders()}
                    resetSelect={this.state.resetSelectedRow}
                    />
                <SppModal
                    open={this.state.modalOpen}
                    modalContent={this.getModalContent()}/>
            </Segment>
        )
    }
}

export default PageGroups;