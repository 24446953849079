import {MenuOption} from "../models/models";
import axios from "axios";


export const getMenu = (xsrfToken: string): Promise<MenuOption[]> => {
    const url = `/api/menu/`;
    let axiosRequestConfig = {headers: {
            'X-SPP-UI-Token': xsrfToken,
            'Accept': 'application/json',
            'Content-Type': 'application/json'}};
    return new Promise<MenuOption[]>(((resolve) => {
        axios.get(url, axiosRequestConfig)
            .then(({data}) => {resolve(data)})
            .catch(() => {resolve([])})
    }));
};

export const getMenuOptionBySlug = (groupSlug: string, xsrfToken: string): Promise<MenuOption> => {
    const url = `/api/menu/${groupSlug}`;
    let axiosRequestConfig = {headers: {
            'X-SPP-UI-Token': xsrfToken,
            'Accept': 'application/json',
            'Content-Type': 'application/json'}};
    return new Promise<MenuOption>(((resolve) => {
        axios.get(url, axiosRequestConfig)
            .then(({data}) => {resolve(data)})
            .catch(() => {resolve({name: '', slug: '', asPage: false, type: 'PAGE_GROUP', childList: []})})
    }));
};