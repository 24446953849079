import AxiosService from "./axios-service";
import {ActionResponse, PageConfig} from "../models/models";
import {genericError} from "../util/constants";

export default class PageConfigService {
    private client: AxiosService;

    constructor(csrfToken: string) {
        this.client = new AxiosService(csrfToken);
    }

    getPageConfigs = (): Promise<PageConfig[]> => {
        const url = `/api/pageConfig/`;
        return new Promise<PageConfig[]>(((resolve) => {
            this.client.getAxiosInstance().get(url)
                .then(({data}) => {resolve(data)})
                .catch(() => {resolve([])})
        }));
    };

    getPageConfigByPage = (pageSlug: string) => {
        const url = `/api/pageConfig/by-slug/${pageSlug}`;
        return new Promise<PageConfig[]>(((resolve) => {
            this.client.getAxiosInstance().get(url)
                .then(({data}) => {resolve(data)})
                .catch(() => {resolve([])})
        }));

    }

    getPageConfigByChartNames = (chartNames: string[]) => {
        const url = `/api/pageConfig/chart-pages`;
        let params = new URLSearchParams();
        chartNames.forEach(chart=>{
            params.append('configs', chart)})

        return new Promise<PageConfig[]>(((resolve) => {
            this.client.getAxiosInstance().get(url, {params})
                .then(({data}: any) => {resolve(data)})
                .catch(() => {resolve([])})
        }));

    }

    deletePageConfigs = (pcId: number): Promise<ActionResponse<PageConfig>> => {
        const url = `/api/pageConfig/${pcId}`;
        return new Promise<ActionResponse<PageConfig>>(((resolve) => {
            this.client.getAxiosInstance().delete(url)
                .then(({data}) => {resolve(data)})
                .catch(() => {resolve(genericError)})
        }));
    };



}