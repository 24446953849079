import * as React from 'react';
import {Button, DropdownProps, Form, FormCheckboxProps, FormInputProps, Grid} from "semantic-ui-react";
import {emptyPageGroup} from "../../../util/constants";
import {CKEditor} from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import {DropDownOption, PageGroup, PortalProperties} from "../../../models/models";
import PageGroupService, {getPageGroups} from "../../../services/page-group-service";
import {info} from "../../../services/route-service";


interface PageProps {
    formType: string
    pageGroup: PageGroup
    buttonLoading: boolean
    handleChange: Function
    handleInputChange: Function
    handleSummaryOnBlur: Function
    pageGroupClient: PageGroupService
    history: any
    successButtonAction: Function
    portalProperties: PortalProperties
    userCookie: any
}

interface PageState {
    parentPageGroups: PageGroup[]
    pageGroups: PageGroup[]
}

export default class PageGroupForm extends React.Component<PageProps, PageState> {
    constructor(props: PageProps, state: PageState) {
        super(props, state);

        this.state = {
            parentPageGroups: [],
            pageGroups: []
        }
    }

    componentDidMount(): void {
        this.getParentPageGroups()
    }

    //fetches all possible page group and sets the parent page group dropdown
    getParentPageGroups = () => {
        getPageGroups(this.props.userCookie)
            .then((response: PageGroup[]) => {
                this.setState({pageGroups: response});
            })
    };

    getParentAsPage = () => {
        return this.props.pageGroup === undefined || this.props.pageGroup.asPage === undefined ? false : this.props.pageGroup.asPage;
    }

    getValidParentPageGroups = (): DropDownOption[] => {

        if (!this.state.pageGroups.length)
            return [];

        let getSlug = (slug?: string) => {
            if (slug === null || slug === '' || slug === undefined)
                return 'none';
            else
                return slug
        };
        
        let maxMenuDepth = this.props.portalProperties.maxMenuDepth;
        let asPage = this.getParentAsPage();

        let validParentGroups = this.state.pageGroups.filter(pageGroup => {

            if (pageGroup.pageGroup) {
                return pageGroup.id !== this.props.pageGroup.id && pageGroup.pageGroup.id !== this.props.pageGroup.id &&
                    ((asPage && pageGroup.depth < maxMenuDepth) || (!asPage && pageGroup.depth < maxMenuDepth - 1));
            } else {
                return pageGroup.id !== this.props.pageGroup.id &&
                    ((asPage && pageGroup.depth < maxMenuDepth) || (!asPage && pageGroup.depth < maxMenuDepth - 1));
            }
        })

        let pageGroupOptions = validParentGroups.map(pageGroup => {
            return {key: pageGroup.id.toString(), text: pageGroup.name + ' - ' + getSlug(pageGroup.slug), value: pageGroup.id}
        });
        
        pageGroupOptions.unshift({key: '0', text: 'Select Parent Group...', value: 0});
        
        return pageGroupOptions;
    }

    getPageGroupAsPage = (asPage: boolean | undefined) => {
        if (asPage === undefined)
            return false;
        else return asPage
    };

    handleChange = (event: any, {name, value, checked}: DropdownProps | FormCheckboxProps) => {
        let selectedPageGroup: any = emptyPageGroup;

        if (name === 'parentPageGroup')
        {
            if (value !== 0)
            {
               selectedPageGroup = this.state.pageGroups.find(pg => pg.id === value);
            }
        }

        this.props.handleChange(event, {name, checked}, selectedPageGroup);
    };

    handleInputChange = (event: any, {name, value}: FormInputProps) => {
        this.props.handleInputChange(event, {name, value});
    }



    handleFormSubmit = (event: any) => {
        event.preventDefault();
        let pageGroup: PageGroup = this.props.pageGroup
        this.props.successButtonAction(pageGroup);
    };

    //sets the summary value state from the ckEditor rich text box
    handleSummaryOnBlur = (event: any, editor: any) => {
        this.props.handleSummaryOnBlur(event, editor)
    };

    getSummaryInput = () => {
        return (
            <Form.Field>
                <label>Summary</label>
                <CKEditor
                    editor={ClassicEditor}
                    data={this.props.pageGroup.summary}
                    onBlur={this.handleSummaryOnBlur}/>
            </Form.Field>)
    };

    getPageGroupParentId = () => {
        if (this.props.pageGroup.pageGroup !== undefined)
        {
            if (this.props.pageGroup.pageGroup === null)
                return 0
            else
                return this.props.pageGroup.pageGroup.id
        }
        else
            return 0
    }

    getFormButtons = () => {
            return (
                <Grid>
                    <Grid.Row>
                        <Grid.Column textAlign={'right'}>
                            <Button className={'form-close-button'}
                                    color={'grey'}
                                    icon={'arrow left'}
                                    loading={this.props.buttonLoading}
                                    type={'button'}
                                    onClick={()=>{this.props.history.push(info.pageGroups.path)}}
                                    content={'RETURN TO PAGE GROUPS'}/>
                            <Button color={'green'}
                                    loading={this.props.buttonLoading}
                                    className={'form-action-button'}
                                    content={'UPDATE PAGE GROUP'}/>
                        </Grid.Column>
                    </Grid.Row>
                </Grid>
            )
    };

    render(){
        return(
            <Form onSubmit={this.handleFormSubmit}>
                <Form.Input label={'Name'}
                            value={this.props.pageGroup.name}
                            placeholder={'Page Group Name'}
                            onChange={this.handleInputChange}
                            name={'name'}
                            required={true}/>

                <Form.Input label={'Slug'}
                            value={this.props.pageGroup.slug}
                            placeholder={'Page Group Slug'}
                            onChange={this.handleInputChange}
                            required={true}
                            name={'slug'}/>

                <Form.Select options={this.getValidParentPageGroups()}
                             value={this.getPageGroupParentId()}
                             onChange={this.handleChange}
                             name={'parentPageGroup'}
                             search={true}
                             placeholder={'Select a Parent Group'}
                             label={'Parent Group'}/>

                {this.getSummaryInput()}
                {this.getFormButtons()}
            </Form>
        )
    }
}