import * as React from 'react';
import "./index.css"
import {useTable, usePagination, useFilters, useSortBy, useResizeColumns, useFlexLayout} from "react-table";
import {Button, Dimmer, Dropdown, DropdownProps, Icon, Input, Loader, Segment, Table} from "semantic-ui-react";
import {paginatedTableSizeOptions} from "../../../../../util/constants";



//utilizes the react-table hooks and semantic ui table to render a paginated table with sorting and filtering
function ReactTable({columns, data, getRowProps = () => ({}), defaultPageLength, loading, darkTheme, className,
                        defaultColumnFilter, filterReset, pageNumberReset, setPageNumberReset}:
    {columns: any[], data: any, getRowProps: Function, defaultPageLength: number,  loading: boolean, darkTheme: boolean, className: string,
        defaultColumnFilter: Function, filterReset: boolean, pageNumberReset: boolean, setPageNumberReset: Function}){


    //handles filter creation on columns
    const defaultColumn: any = React.useMemo(
        () => ({Filter: defaultColumnFilter,}), [defaultColumnFilter]
    );

    //adds the div that displays the no rows found message when table is empty
    let getNoRowsDiv = () => {
        if((data.length === 0 || page.length === 0 )&& !loading)
            return (<div className="rt-noData">No Rows Found</div>);

        return null;
    };

    //changes the table class name is no data rows are present
    let getTableClassName = () => {
        if((data.length === 0 || page.length === 0 )&& !loading)
            return 'no-rows';
        else
            return ''
    };

    //all from react-table
    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        prepareRow,
        setAllFilters,
        page,
        canPreviousPage,
        canNextPage,
        pageCount,
        gotoPage,
        nextPage,
        previousPage,
        setPageSize,
        state: { pageIndex, pageSize, filters},
    }: any = useTable({
        columns,
        data,
        defaultColumn,
        autoResetFilters: false,
        autoResetPage: false,
        initialState: { pageIndex: 0, pageSize: defaultPageLength, filters: [] },
    },
        useFilters,
        useFlexLayout,
        useResizeColumns,
        useSortBy,
        usePagination);


    //uses the table header group props for the empty rows so resizing still works
    let createEmptyRow = () => {
        return(
            headerGroups.map((headerGroup: any) => (
                <Table.Row {...headerGroup.getHeaderGroupProps()}>
                    {headerGroup.headers.map((column: any) => (
                        <Table.Cell {...column.getHeaderProps()}>
                            <div><span>&nbsp;</span></div>
                        </Table.Cell>
                    ))}
                </Table.Row>
            ))
        )
    };

    //creating empty padding cells
    let getEmptyRow = () => {

        let emptyRows = [];
        if(page.length%pageSize !== 0 && !canNextPage)
            for (let i = 0; i < (pageSize - page.length%pageSize); i++)
                emptyRows.push(createEmptyRow());

        if(data.length === 0 || page.length === 0)
            for (let i = 0; i < pageSize; i++)
                emptyRows.push(createEmptyRow());

        return emptyRows
    };

    //resets the filters on the table when the reset button in table button group is called
    if(filterReset && filters.length !== 0)
        setAllFilters([]);

    //resets the page index to 0 when reset button in table button group is called
    if(pageNumberReset && pageIndex !== 0)
    {
        gotoPage(0);
        setPageNumberReset(false);
    }

    //resets the pageNumberReset bool when reset button in table button group is called and table already at 1st page
    if(pageNumberReset && pageIndex === 0)
    {
        setPageNumberReset(false);
    }

    //updates the page state and navigates to the new page
    let handlePageChange = (e: any) => {
            let page = e.target.value ? Number(e.target.value) - 1 : 0;
            gotoPage(page)
    };

    //changes the page size
    let handlePageSizeChange = (e: any, {value}: DropdownProps) => {setPageSize(Number(value))};

    // Render the UI for your table
    return (
        <div className={className} id={'paginated-table-wrapper'}>
            <div id={'table-wrapper'}>
                <Dimmer active={loading} inverted={!darkTheme}>
                    <Loader content={'Loading'}/>
                </Dimmer>
                <Table celled={true}
                       fixed={true}
                       id={'super-table'}
                       singleLine={true}
                       compact={true}
                       className={getTableClassName()}
                       unstackable={true}
                       {...getTableProps()}>
                    <Table.Header >
                        <Table.Row>
                            <Table.HeaderCell colSpan={columns.length} style={{padding: '.3em'}}/>
                        </Table.Row>
                    {headerGroups.map((headerGroup: any) => (
                        <Table.Row {...headerGroup.getHeaderGroupProps()} id={'column-header'}>
                            {headerGroup.headers.map((column: any) => (
                                <Table.HeaderCell {...column.getHeaderProps(column.getSortByToggleProps())}
                                                  className={'table-header-cell column-name'}
                                                  textAlign={'center'}>
                                    {column.render('Header')}
                                    <span>
                                        {column.isSorted ? column.isSortedDesc ?
                                            <Icon name={'sort down'}/> : <Icon name={'sort up'}/> : ''}
                                    </span>
                                    <div
                                        {...column.getResizerProps()}
                                        className={`resizer ${column.isResizing ? 'isResizing' : ''}`}
                                        // tslint:disable-next-line
                                        onClick={(e)=>{e.preventDefault();e.stopPropagation()}}
                                    />
                                </Table.HeaderCell>
                            ))}
                        </Table.Row>
                    ))}
                    </Table.Header>
                    <Table.Body {...getTableBodyProps()} >
                        {headerGroups.map((headerGroup: any) => (
                            <Table.Row {...headerGroup.getHeaderGroupProps()}>
                                {headerGroup.headers.map((column: any) => (
                                    <Table.Cell {...column.getHeaderProps()}>
                                        <div>{column.canFilter ? column.render('Filter') : null}</div>
                                    </Table.Cell>
                                ))}
                            </Table.Row>
                        ))}
                    {page.map((row: any, i: number) => {
                        prepareRow(row);
                        return (
                            <Table.Row {...row.getRowProps(getRowProps(row))} key={i}>
                                {row.cells.map((cell: any, index: number) => {
                                    return <Table.Cell {...cell.getCellProps()} key={index}>
                                        {cell.render('Cell')} </Table.Cell>
                                })}
                            </Table.Row>)})}
                        {getEmptyRow()}
                    </Table.Body>
                </Table>
                {getNoRowsDiv()}
            </div>
            <div id={'table-footer'}>
                <Segment attached={'bottom'} id={'pagination-row'}  >
                    <div className={'previous'}>
                        <Button content={"Previous"}
                                fluid={true}
                                className={'pagination-button'}
                                onClick={previousPage}
                                disabled={!canPreviousPage}/>
                    </div>
                    <div className={'center'}>
                        <span id={'page-number-span'}>
                             <span>Page </span>
                             <Input size={"mini"}
                               type={"number"}
                               id={'page-input'}
                               onChange={handlePageChange}
                               value={pageIndex+1}/>
                               <span> of {pageCount===0 ? 1 : pageCount}</span>
                        </span>
                        <Dropdown defaultValue={pageSize}
                                  compact={true}
                                  selection={true}
                                  defaultUpward={false}
                                  onChange={handlePageSizeChange}
                                  options={paginatedTableSizeOptions} />
                    </div>
                    <div className={'next'}>
                        <Button content={"Next"}
                                fluid={true}
                                className={'pagination-button'}
                                onClick={nextPage}
                                disabled={!canNextPage}/>
                    </div>
                </Segment>
            </div>
        </div>
    )
}
export default ReactTable