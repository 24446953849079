import * as React from 'react';
import {MenuOption, Principal} from "../../../models/models";
import {createFavorite, deleteFavorite} from "../../../services/favorite-service";
import {Button} from "semantic-ui-react";
import toast from "../../../util/toast";
import {pageTypeKey} from "../../../util/constants";
import {getMenu} from "../../../services/menu-service";
import './index.css'

interface PageProps {
    pageType: string
    userCookie: any
    userPrincipal: Principal
    favorite: boolean
    pageId: number
    pageGroupChild: boolean
    darkTheme: boolean
    loading: boolean
    setMenuOptions: (menuOptions: MenuOption[]) => void
}

interface PageState {
    favorite: boolean
    hasBeenClicked: boolean
    callInProgress: boolean
}

export default class Favorite extends React.Component<PageProps, PageState> {
    constructor(props: PageProps, state: PageState) {
        super(props, state);

        this.state = {
            favorite: false,
            hasBeenClicked: false,
            callInProgress: false
        }
    }

    componentDidMount() {
        if (!this.props.userPrincipal.unauthenticatedUser)
            this.setState({favorite: this.props.favorite ?? false})
    }

    componentDidUpdate(prevProps: Readonly<PageProps>) {
        // This is needed due to render timing
        if (this.props !== prevProps && !this.state.hasBeenClicked)
            this.setState({favorite: this.props.favorite ?? false})
    }

    starClicked = () => {
        if (this.state.callInProgress)
            return;

        //Ensures that multiple calls are not going at the same time
        this.setState({callInProgress: true});

        if (this.state.favorite)
            deleteFavorite(this.props.userCookie, this.props.pageType === 'PAGE_GROUP' ? 'true' : 'false',
                String(this.props.pageId)).then(result => {
                if (result)
                {
                    this.setState({favorite: !result, hasBeenClicked: true});
                    getMenu(this.props.userCookie).then( response => {
                        this.refreshMenu(response);
                    })
                }
                else
                    toast.error('Failed to remove this page from favorites. Please try again.');
            }).finally( () => { this.setState({callInProgress: false}); });
        else
            createFavorite(this.props.userCookie, this.props.pageType === 'PAGE_GROUP' ? 'true' : 'false',
                String(this.props.pageId)).then(result => {
                if (result)
                {
                    this.setState({favorite: result, hasBeenClicked: true});
                    getMenu(this.props.userCookie).then(response => {
                        this.refreshMenu(response);
                    })
                }
                else
                    toast.error('Failed to favorite this page. Please try again.')
            }).finally( () => { this.setState({callInProgress: false}); });
    }

    refreshMenu = (menuList: MenuOption[]) => {
        menuList = menuList.filter((x: MenuOption) => x.name !== 'Welcome');
        this.props.setMenuOptions(menuList);
    }

    getTooltipText = () => {
        if (this.state.favorite)
            return 'Remove from favorites';

        return 'Add to favorites';
    }

    getClassName = () => {
        if (this.props.pageGroupChild)
            return this.props.darkTheme ? 'fav-page-group-child-dark' : 'fav-page-group-child';

        let pageType = this.props.pageType;
        let className = '';

        switch (pageType)
        {
            case pageTypeKey.STATIC:
            case pageTypeKey.FILE_BROWSER:
            case pageTypeKey.FB_DESCRIPTION:
                className = 'fav-static-fb';
                break;
            case pageTypeKey.EMBEDDED:
            case pageTypeKey.EMBEDDED_TITLE:
                className = 'fav-embedded-with-title';
                break;
            case 'PAGE_GROUP':
                className = 'fav-page-group';
                break;
            case 'embedded no title':
                className = 'fav-embedded-no-title'
                break;
        }

       return this.props.darkTheme && pageType !== 'embedded no title' ? className + '-dark' : className;
    }

    getStarSize = () => {
        if (this.props.pageType === 'embedded no title')
            return "mini";

        return "medium";
    }

    render() {
        if (this.props.userPrincipal.unauthenticatedUser || this.props.loading)
            return false
        else
            return (
                    <Button onClick={this.starClicked}
                            title={this.getTooltipText()}
                            icon={'star' + (this.state.favorite ? '' : ' outline')}
                            size={this.getStarSize()}
                            className={this.getClassName()}/>
            )
    }

}

