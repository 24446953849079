import AxiosService from "./axios-service";
import {ActionResponse, Announcement, SessionAnnouncement} from "../models/models";
import {genericError} from "../util/constants";
import axios from "axios";

export default class AnnouncementService {
    private client: AxiosService;

    constructor(csrfToken: string) {
        this.client = new AxiosService(csrfToken);
    }

    getAnnouncements = (): Promise<Announcement[]> => {
        const url = `/api/announcement/`;
        return new Promise<Announcement[]>(((resolve) => {
            this.client.getAxiosInstance().get(url)
                .then(({data}) => {resolve(data)})
                .catch(() => {resolve([])})
        }));
    };

    createAnnouncement = (announcement: Announcement): Promise<ActionResponse<Announcement>> => {
        const url = `/api/announcement/`;
        return new Promise<any>((resolve => {
            this.client.getAxiosInstance().post(url, announcement)
                .then(({data}) => {resolve(data)})
                .catch(() => {resolve(genericError)})
        }))

    };

    updateAnnouncement = (announcement: Announcement): Promise<ActionResponse<Announcement>> => {
        const url = `/api/announcement/`;
        return new Promise<any>((resolve => {
            this.client.getAxiosInstance().put(url, announcement)
                .then(({data}) => {resolve(data)})
                .catch(() => {resolve(genericError)})
        }))

    };

    deleteAnnouncement = (announcementId: number): Promise<ActionResponse<null>> => {
        const url = `/api/announcement/${announcementId}`;
        return new Promise<any>((resolve => {
            this.client.getAxiosInstance().delete(url)
                .then(({data}) => {resolve(data)})
                .catch(() => {resolve(genericError)})
        }))

    };

}

export const dismissAnnouncement = (id: number, xsrfToken: string): Promise<ActionResponse<SessionAnnouncement[]>> => {
    const url = `/api/announcement/dismiss/${id}`;
    let axiosRequestConfig = {headers: {
            'X-SPP-UI-Token': xsrfToken,
            'Accept': 'application/json',
            'Content-Type': 'application/json',}};
    return new Promise<ActionResponse<SessionAnnouncement[]>>(((resolve) => {
        axios.get(url, axiosRequestConfig)
            .then(({data}) => {resolve(data)})
            .catch(() => {resolve(genericError)})
    }));
};