import {FileBrowsing} from "../models/models";
import axios from "axios";

export const downloadFile = (fsName: string, path: string, xsrfToken: string) => {
    let params = new URLSearchParams();
    params.append('path', path);

    const url = `/file-browser-api/download/${fsName}`;

    return new Promise<any>(((resolve) => {
        axios.get(url, {params, headers: {'Accept': '*/*', 'X-SPP-UI-Token': xsrfToken, 'Content-Type': 'application/json'}, responseType: 'arraybuffer'})
            .then(({data}: {data: any}) => {resolve(data)})
            .catch(() => {resolve([])})
    }));
}

export const browseFiles = (fileBrowser: FileBrowsing, xsrfToken: string) => {
    let params = new URLSearchParams();
    params.append('fsName', fileBrowser.fsName);
    params.append('path', fileBrowser.path);
    params.append('type', fileBrowser.type);

    const url = `/file-browser-api/`;
    return new Promise<any[]>(((resolve) => {
        let axiosRequestConfig = {
            params,
            headers: {
                'X-SPP-UI-Token': xsrfToken,
                'Accept': 'application/json',
                'Content-Type': 'application/json'}};
        axios.get(url, axiosRequestConfig)
            .then(({data}: {data: any}) => {resolve(data)})
            .catch(() => {resolve([])})
    }));

}