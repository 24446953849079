import * as React from 'react';
import {Button, ButtonProps, Segment} from "semantic-ui-react";
import {Announcement} from "../../../../models/models";
import {dismissAnnouncement} from "../../../../services/announcement-service";
import {useState} from "react";


interface PageProps {
    announcement: Announcement
    getSessionAnnouncements: (cookie: string) => void
    userCookie: any
    index: number
}

const SessionBasedAnnouncement: React.FC<PageProps> = (props: PageProps) => {
    const [loading, setLoading] = useState<boolean>(false);

    let handleDismissAnnouncement = (event: any, {name}:ButtonProps) => {
        setLoading(true);
        dismissAnnouncement(name.id, props.userCookie).then(response => {
            setLoading(false);
            if (response.ok)
                props.getSessionAnnouncements(props.userCookie)
        })
    }

    let getColor = (display: string) => {
        if (display === "green")
            return "#009E0F"
        else return "#FBAB18"
    }

    return(
        <Segment
            loading={loading}
            key={props.announcement.id}
            className={'sa-segment'}
            style={{
                marginTop: props.index > 1 ? '.75rem' : 0,
                backgroundColor: getColor(props.announcement.displayType)}}>
            {props.announcement.content}
            <Button
                name={props.announcement}
                className={'sa-dismiss-btn'}
                onClick={handleDismissAnnouncement}
                size={'small'}
                icon={'x'}/>
        </Segment>
    )
}

export default SessionBasedAnnouncement;

