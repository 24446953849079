import * as React from 'react';
import './index.css'
import MenuRail from "../../Common/MenuRail";
import {Button, Grid, GridColumn, Icon, Segment, Dimmer, Loader} from "semantic-ui-react";
import {info} from "../../../services/route-service";
import {MenuOption, PageConfig, PortalProperties, Principal} from "../../../models/models";
import ChartService from "../../../services/chart-service";
import moment from "moment/moment";
import PageConfigService from "../../../services/page-config-service";
import {chartName, emptyPage} from "../../../util/constants";
import {getChartComponent} from "../../../services/static-service";
import {getBackgroundImage} from "../../../util/functions";
import {NavLink} from "react-router-dom";
import ReactGA from "react-ga4";

interface PageProps {
    selectedTopMenuOption: string
    setSelectedTopMenuOption: (selectedTopMenuOption: string) => void;
    railDisplay: string
    setRailDisplay: (railDisplay: string) => void
    portalProperties: PortalProperties;
    history: any
    userCookie: any
    menuOptions: MenuOption[]
    userPrincipal: Principal
    darkTheme: boolean
}

interface PageState {
    aceLoading: boolean
    genMixLoading: boolean
    forecastLoading: boolean
    lmpLoading: boolean
    lastModified: Date
    chartPageConfigs: PageConfig[]
}

export default class IMGraphs extends React.Component<PageProps, PageState> {
    private readonly chartService: ChartService;
    private readonly pageConfigService: PageConfigService;
    constructor(props: PageProps, state: PageState) {
        super(props, state);

        this.chartService = new ChartService(this.props.userCookie)
        this.pageConfigService = new PageConfigService(this.props.userCookie)

        this.state = {
            aceLoading:  true,
            genMixLoading:  true,
            forecastLoading:  true,
            lmpLoading:  true,
            lastModified: new Date(0),
            chartPageConfigs: []
        }
    }

    componentDidMount() {
        ReactGA.event({category: "user_page_view", action: "View IM Graph Page"});
        let chartNameList = [chartName.IM_GEN_MIX, chartName.IM_ACE, chartName.IM_LOAD_FORECAST, chartName.IM_LMP_TREND]
        this.pageConfigService.getPageConfigByChartNames(chartNameList)
            .then(results => this.setState({chartPageConfigs: results}))
    }

    getPageByChartName = (chartName: string) => {
        let pc = this.state.chartPageConfigs.find(chart => chart.pcValue === chartName);
        if (pc !== undefined && pc.page !== undefined)
            return pc.page
        else return emptyPage
    }

    updateLoadingStatus = (loading: boolean, name: string) => {
        switch (name) {
            case chartName.IM_LOAD_FORECAST:
                this.setState({forecastLoading: loading})
                break;
            case chartName.IM_ACE:
                this.setState({aceLoading: loading})
                break;
            case chartName.IM_GEN_MIX:
                this.setState({genMixLoading: loading})
                break;
            case chartName.IM_LMP_TREND:
                this.setState({lmpLoading: loading})
                break;
        }
    }

    getLoadingState = (name: string) => {
        switch (name) {
            case chartName.IM_LOAD_FORECAST:
                return this.state.forecastLoading
            case chartName.IM_ACE:
                return this.state.aceLoading
            case chartName.IM_GEN_MIX:
                return this.state.genMixLoading
            case chartName.IM_LMP_TREND:
                return this.state.lmpLoading
            default:
                return false
        }
    }

    updateLastModified = (lastModified: Date) => {
        if (lastModified > this.state.lastModified)
            this.setState({lastModified: lastModified})
    }

    getGridChartColumn = (name: string) => {
        return (
            <Grid.Column className={this.props.darkTheme? 'chart-column dark' : 'chart-column'}>
                <Segment.Group className={'chart-sg'}>
                    <Segment
                        content={this.getPageByChartName(name).title}
                        className={'chart-title-sg'}/>
                    <Segment className={'chart-segment'}>
                        <Dimmer active={this.getLoadingState(name)} inverted={!this.props.darkTheme}>
                            <Loader/>
                        </Dimmer>
                        {getChartComponent(
                            name,
                            this.updateLastModified,
                            this.chartService,
                            this.getLoadingState(name),
                            this.props.darkTheme,
                            this.updateLoadingStatus,
                            false)}
                    </Segment>
                    <NavLink to={'pages/'+this.getPageByChartName(name).slug}>
                        <Button
                            className={'narrow-btn graph-btn'}
                            attached='bottom'
                            color={'grey'}>
                            SEE MORE
                            <Icon className={'see-more-arrow'} name={'arrow right'} />
                        </Button>
                    </NavLink>
                </Segment.Group>
            </Grid.Column>
        )
    }

    render(){
        return(
            <div
                className={'im-background-image-div'}
                style={{backgroundImage: this.props.userPrincipal.unauthenticatedUser?
                        getBackgroundImage(true) : getBackgroundImage(this.props.darkTheme)}}>
                <MenuRail
                    selectedTopMenuItem={this.props.selectedTopMenuOption}
                    railDisplay={this.props.railDisplay}
                    userPrincipal={this.props.userPrincipal}
                    portalProperties={this.props.portalProperties}
                    setRailDisplay={this.props.setRailDisplay}
                    menuOptions={this.props.menuOptions}
                    history={this.props.history}
                    setSelectedTopMenuOption={this.props.setSelectedTopMenuOption}/>
                <Grid id={'im-graph-grid'}>
                    <Grid.Row
                        id={'im-char-logo-row'}
                        columns={1}
                        textAlign={'right'}>
                        <Grid.Column>
                            <img
                                style={{width: '12rem'}}
                                src={"/images/im-logo.png"}
                                alt={'west Logo'}/>
                        </Grid.Column>
                    </Grid.Row>
                    <Grid.Row className={'im-chart-row'} columns={1}>
                        {this.getGridChartColumn(chartName.IM_LOAD_FORECAST)}
                    </Grid.Row>
                    <Grid.Row className={'im-chart-row'} columns={3}>
                        {this.getGridChartColumn(chartName.IM_LMP_TREND)}
                        {this.getGridChartColumn(chartName.IM_GEN_MIX)}
                        {this.getGridChartColumn(chartName.IM_ACE)}
                    </Grid.Row>
                    <Grid.Row columns={2} style={{paddingTop: 0}} >
                        <Grid.Column verticalAlign={'middle'}>
                            <NavLink to={info.home.path}>
                                <Button
                                    className={'narrow-btn'}
                                    color={'grey'}>
                                    <Icon name={'arrow left'}/>
                                    BACK
                                </Button>
                            </NavLink>
                        </Grid.Column>
                        <GridColumn
                            verticalAlign={'middle'}
                            textAlign={'right'}
                            id={'dark-last-modified-column'}>
                            LAST UPDATED {moment(this.state.lastModified).format('YYYY-MM-DD HH:mm:ss')} CT
                        </GridColumn>
                    </Grid.Row>
                </Grid>
            </div>
        )
    }
}
