import React from "react";
import {Feature} from "../../../models/models";
import {activationStrategies, activationStrategyDropDownOptions, emptyFeature} from "../../../util/constants";
import {Button, Checkbox, DropdownProps, Form, Grid, Icon, InputOnChangeData, Segment} from "semantic-ui-react";
import {info} from "../../../services/route-service";
import toast from "../../../util/toast";
import './index.css';
import {cloneDeep} from "lodash";
import moment from "moment";
import {getFeatureByName, updateFeature} from "../../../services/feature-service";

interface PageProps {
    userCookie: any
    history: any
    match: any
}

interface PageState {
    feature: Feature
    toggled: boolean
    selectedStrategy: string
    currentStrategyParam1: string
    currentStrategyParam2: string
    newStrategyParam1: string
    newStrategyParam2: string
}

export default class EditFeature extends React.Component<PageProps, PageState> {
    constructor(props: PageProps, state: PageState) {
        super(props, state);

        this.state = {
            feature: emptyFeature,
            toggled: false,
            selectedStrategy: '',
            currentStrategyParam1: '',
            currentStrategyParam2: '',
            newStrategyParam1: '',
            newStrategyParam2: ''
        };
    }

    componentDidMount(): void {
        this.getFeature();
    }

    getFeature = () => {
        let featureName = this.props.match.params.featureName;

        getFeatureByName(this.props.userCookie, featureName)
            .then( (response: Feature) => {
                this.setState({feature: response,
                               selectedStrategy: response.strategyId === null ? 'None' : response.strategyId,
                               toggled: response.featureEnabled as boolean,
                               currentStrategyParam1: response.strategyParams ? this.processStrategyParams(response, 1) : '',
                               currentStrategyParam2: response.strategyParams && response.strategyParams.includes('\n')  ? this.processStrategyParams(response, 2) : ''
                              }
                             );
            })
    }

    updateFeature = () => {
        let invalidParam1 = this.validateInput(this.state.selectedStrategy === this.state.feature.strategyId ? this.state.currentStrategyParam1: this.state.newStrategyParam1, 1);

        let invalidParam2 = this.validateInput(this.state.selectedStrategy === this.state.feature.strategyId ? this.state.currentStrategyParam2: this.state.newStrategyParam2, 2)

        if (invalidParam1)
            toast.error(invalidParam1);
        else if (invalidParam2)
            toast.error(invalidParam2)
        else
            updateFeature(this.props.userCookie, this.state.feature.featureName, this.state.toggled, this.state.selectedStrategy,
                    this.state.selectedStrategy === this.state.feature.strategyId ? this.state.currentStrategyParam1 : this.state.newStrategyParam1,
                    this.state.selectedStrategy === this.state.feature.strategyId ? this.state.currentStrategyParam2 : this.state.newStrategyParam2)
                .then((success: Boolean) => {
                    if (success)
                    {
                        let newFeature = cloneDeep(this.state.feature);
                        newFeature.strategyId = this.state.selectedStrategy;

                        let newState = {feature: newFeature};

                        if (this.state.selectedStrategy !== this.state.feature.strategyId)
                           newState = { ...newState, ...{currentStrategyParam1: this.state.newStrategyParam1, currentStrategyParam2: this.state.newStrategyParam2, newStrategyParam1: '', newStrategyParam2: ''}};

                        this.setState(newState);

                        toast.success('Feature was updated');
                    }
                    else
                        toast.error('Failed to update feature');
                })

    }

    toggleSliderClicked = () => {
        this.setState({toggled: !this.state.toggled});
    }

    strategyTypeChanged = (event: any, {name, value}: DropdownProps) => {
        if (name === 'selectedStrategy')
            this.setState({selectedStrategy: value as string, newStrategyParam1: '', newStrategyParam2: ''})
    }

    strategyValueChanged = (event: any, {name, value} : InputOnChangeData) => {
        if (this.state.selectedStrategy === this.state.feature.strategyId)
        {
            if (name === 'parameter1')
                this.setState({currentStrategyParam1: value})
            else if (name === 'parameter2')
                this.setState({currentStrategyParam2: value})
        }
        else {
            if (name === 'parameter1')
                this.setState({newStrategyParam1: value})
            else if (name === 'parameter2')
                this.setState({newStrategyParam2: value})
        }
    }

    getParameterValue = (firstOrSecondParam : number) => {
        if (firstOrSecondParam === 1)
        {
            if (this.state.selectedStrategy === this.state.feature.strategyId)
                return this.state.currentStrategyParam1;

            return this.state.newStrategyParam1;
        }

        if (this.state.selectedStrategy === this.state.feature.strategyId)
            return this.state.currentStrategyParam2;

        return this.state.newStrategyParam2;
    }

    getInputField = (label: string, required: boolean, paramNum: number) => {
        return <Grid.Row>
                    <Grid.Column>
                        <Form.Input
                            className={'feature-strategy-params'}
                            label={label}
                            required={required}
                            name={'parameter' + paramNum}
                            value={this.getParameterValue(paramNum)}
                            onChange={this.strategyValueChanged}
                        />
                        <div className={'feature-param-explanation'}> {this.getExplanationText(paramNum)}</div>
                    </Grid.Column>
               </Grid.Row>;
    }

    getFormBySelectedOption = () => {
        switch(this.state.selectedStrategy){
            case activationStrategies.GradualRollout:
                return this.getInputField('Percentage', true, 1);
            case activationStrategies.ReleaseDate:
                return <>
                    {this.getInputField('Date', true, 1)}
                    {this.getInputField('Time', false, 2)}
                </>;
            case activationStrategies.UserByUsername:
                return this.getInputField('Usernames', true, 1);
            default:
                return null;
        }
    }

    processStrategyParams = (feature: Feature, paramNum: 1 | 2) => {
        let param = feature.strategyParams.split('\n')[paramNum - 1];
        let value = param.substring(param.indexOf('=') + 1);

        if (feature.strategyId === activationStrategies.ReleaseDate)
            return value.replace(/\\/g, '');

        return value;
    }

    getExplanationText = (paramNum: number) => {
        if (paramNum === 1)
            switch (this.state.selectedStrategy) {
                case activationStrategies.GradualRollout:
                    return "Percentage of users for which the feature should be active (i.e. '25' for every fourth user). Whole numbers only.";
                case activationStrategies.ReleaseDate:
                    return 'Release date of the feature. Format: 2012-12-31';
                case activationStrategies.UserByUsername:
                    return 'A comma-delimited list of users for which the feature is active.';
            }
        else if (this.state.selectedStrategy === activationStrategies.ReleaseDate)
            return 'Optional time for the release day. The default value is midnight. Format: 14:45:00 or 08:00:00';

        return '';
    }

    validateInput = (strategyParam: string, paramNum: number) => {
        if (!strategyParam)
            return '';

        if (strategyParam.length > 1950)
            return 'Input needs to be 1950 characters or less.'

        const commaDelimitedListPattern = /^\s*[a-zA-Z0-9]+\s*(,\s*[a-zA-Z0-9]+\s*)*$/;
        const commaDelimitedRegex = new RegExp(commaDelimitedListPattern);

        if (paramNum === 1)
            switch (this.state.selectedStrategy) {
                case activationStrategies.GradualRollout:
                    let wholeNumberBetween0And100 = /^(100|[1-9]?\d)$/;

                    if (!wholeNumberBetween0And100.test(strategyParam))
                        return 'Percentage must be a whole number between 0 and 100 inclusive.';

                    return '';
                case activationStrategies.ReleaseDate:
                    if (!moment(strategyParam, 'YYYY-MM-DD', true).isValid())
                        return 'Invalid date format. Must be yyyy-mm-dd.';

                    return '';
                case activationStrategies.UserByUsername:
                    if (!commaDelimitedRegex.test(strategyParam))
                        return 'Must be a comma-delimited list of alphanumerical usernames';

                    return '';
                default:
                    return '';
            }
        else if (this.state.selectedStrategy === activationStrategies.ReleaseDate)
            if (!moment(strategyParam, 'HH:mm:ss', true).isValid())
                return 'Invalid time format. Use 24 hour time for HH:mm:ss';

        return '';
    }

    render() {
        return (
            <Grid>
                <Grid.Row columns={1}>
                    <Grid.Column>
                        <Segment.Group id={'cp-segment-group'}>
                            <Segment id={'cp-segment-header'}>
                                <Grid>
                                    <Grid.Row columns={2}>
                                        <Grid.Column
                                            textAlign={'left'}
                                            width={6}
                                            verticalAlign={'middle'}>
                                            <Icon
                                                name={'edit outline'}
                                                size={'large'}/>
                                            Edit Feature: {this.state.feature.featureName}
                                        </Grid.Column>
                                        <Grid.Column textAlign={'right'} width={10}>
                                            <Checkbox
                                                toggle={true}
                                                checked={this.state.toggled}
                                                onClick={this.toggleSliderClicked}
                                            />
                                        </Grid.Column>
                                    </Grid.Row>
                                </Grid>
                            </Segment>
                            <Segment>
                                <Form onSubmit={this.updateFeature}>
                                    <Grid>
                                        <Grid.Row>
                                            <Grid.Column>
                                                <Form.Select
                                                    id={'edit-feature-menu-options'}
                                                    options={activationStrategyDropDownOptions}
                                                    value={this.state.selectedStrategy}
                                                    onChange={this.strategyTypeChanged}
                                                    name={'selectedStrategy'}
                                                    label={'Activation Strategy'}/>
                                            </Grid.Column>
                                        </Grid.Row>
                                        {this.getFormBySelectedOption()}
                                        <Grid.Row>
                                            <Grid.Column textAlign={'right'}>
                                                <Button className={'form-close-button'}
                                                        color={'grey'}
                                                        icon={'arrow left'}
                                                        type={'button'}
                                                        onClick={()=>{this.props.history.push(info.features.path)}}
                                                        content={'RETURN TO FEATURES'}/>
                                                <Button color={'green'}
                                                        className={'form-action-button'}
                                                        content={'UPDATE FEATURE'}
                                                        type={'submit'}/>
                                            </Grid.Column>
                                        </Grid.Row>
                                    </Grid>
                                </Form>
                            </Segment>
                        </Segment.Group>
                    </Grid.Column>
                </Grid.Row>
            </Grid>
        )
    }
}