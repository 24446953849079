import * as React from 'react';
import {DropdownProps, FormCheckboxProps, FormInputProps, Tab} from "semantic-ui-react";
import {emptyPageGroup, modalTypes} from "../../../../util/constants";
import PageGroupForm from "../../../Common/PageGroupForm";
import {ActionResponse, PageGroup, PortalProperties} from "../../../../models/models";
import PageGroupService from "../../../../services/page-group-service";
import toast from "../../../../util/toast";

interface PageProps {
    pageGroupClient: PageGroupService
    pageGroupId: any
    history: any
    userCookie: any
    portalProperties: PortalProperties
}

interface PageState {
    summary: any
    asPage: boolean
    pageGroup: PageGroup
    parentPageGroup: PageGroup
    name: string
    slug: string
}

export default class DetailTab extends React.Component<PageProps, PageState> {


    constructor(props: PageProps, state: PageState) {
        super(props, state);

        this.state = {
            summary: '',
            asPage: false,
            name: '',
            slug: '',
            pageGroup: emptyPageGroup,
            parentPageGroup: emptyPageGroup
        }

    }

    componentDidMount() {
        this.getPageGroup()
    }

    getPageGroup = () => {
        this.props.pageGroupClient.getPageGroupById(this.props.pageGroupId)
            .then((response: PageGroup) => {
                this.setState({
                    pageGroup: response,
                    asPage: response.asPage || false,
                    summary: response.summary || '',
                    name: response.name || '',
                    slug: response.slug || '',
                    parentPageGroup: response.pageGroup || emptyPageGroup })
            })
    }

    hasValidDepth = (pageGroup: PageGroup, asPage: boolean) => {
        let maxMenuDepth = this.props.portalProperties.maxMenuDepth
        return (asPage && pageGroup.depth < maxMenuDepth) || (!asPage && pageGroup.depth < maxMenuDepth - 1);
    }

    handleChange = (event: any, {name, checked}: DropdownProps | FormCheckboxProps , selectedPageGroup: PageGroup) => {

        if (name === 'asPage')
        {
            if (checked !== undefined)
            {
                let stateUpdate = {asPage: checked};
                if (!this.hasValidDepth(this.state.parentPageGroup, checked))
                    stateUpdate["parentPageGroup"] = emptyPageGroup;
                
                this.setState(stateUpdate)
            }
            
            if (checked !== undefined && !checked)
                this.setState({summary: ''})
        }

        if (name === 'parentPageGroup')
            this.setState({parentPageGroup: selectedPageGroup});

    };

    //sets the states of the input based on value
    handleInputChange = (event: any, {name, value}: FormInputProps) => {
        if (name === 'name')
        {
            let pageGroup = this.state.pageGroup
            pageGroup.name = value;
            pageGroup.slug = (value.replace(/ /g, '-')).toLowerCase();
            this.setState({pageGroup})
        }

        if (name === 'slug')
        {
            let pageGroup = this.state.pageGroup
            pageGroup.slug = (value.replace(/ /g, '-')).toLowerCase();
            this.setState({pageGroup})
        }
    };

    componentDidUpdate(prevProps: Readonly<PageProps>, prevState: Readonly<PageState>, snapshot?: any): void {

        if (this.state.summary !== prevState.summary ||
            this.state.asPage !== prevState.asPage ||
            this.state.parentPageGroup !== prevState.parentPageGroup )
            this.updatePageGroupState();
    }

    updatePageGroupState = () => {

        let newPageGroup: any = this.state.pageGroup;

        newPageGroup.summary = this.state.summary;
        newPageGroup.asPage = this.state.asPage;
        newPageGroup.pageGroup = this.state.parentPageGroup;
        newPageGroup.name = this.state.name;
        newPageGroup.slug = this.state.slug;

        this.setState({pageGroup: newPageGroup})
    };

    //sets the summary value state from the ckEditor rich text box
    handleSummaryOnBlur = (event: any, editor: any) => {
        this.setState({summary: editor.getData()})
    };

    updatePageGroup = (pageGroup: PageGroup) => {
        if ( pageGroup.pageGroup === null || (pageGroup.pageGroup !== undefined && pageGroup.pageGroup.id === ""))
            pageGroup.pageGroup = undefined;

        this.props.pageGroupClient.updatePageGroup(pageGroup)
            .then((response: ActionResponse<PageGroup>) => {
                if (response.ok)
                    toast.success(response.message)
                else
                    toast.error(response.message)
        })
    }

    render(){
        return(
            <Tab.Pane>
                <PageGroupForm
                    formType={modalTypes.UPDATE}
                    history={this.props.history}
                    pageGroup={this.state.pageGroup}
                    buttonLoading={false}
                    userCookie={this.props.userCookie}
                    handleChange={this.handleChange}
                    handleInputChange={this.handleInputChange}
                    pageGroupClient={this.props.pageGroupClient}
                    successButtonAction={this.updatePageGroup}
                    handleSummaryOnBlur={this.handleSummaryOnBlur}
                    portalProperties={this.props.portalProperties}/>
            </Tab.Pane>
        )
    }
}