import * as React from 'react';
import {Route, Switch} from "react-router";
import {BrowserRouter} from "react-router-dom";
import withAdmin from "./components/Common/HOC/Admin";
import withPublic from "./components/Common/HOC/Public";
import Announcements from "./components/Admin/Announcements";
import {info} from "./services/route-service";
import Pages from "./components/Admin/Pages";
import PageGroups from "./components/Admin/PageGroups";
import ErrorNotFound from "./components/Common/ErrorNotFound";
import CreatePage from "./components/Admin/CreatePage";
import EditPage from "./components/Admin/EditPage";
import FeaturesPage from "./components/Admin/Features";
import EditPageGroup from "./components/Admin/EditPageGroup";
import Home from "./components/Public/HomePage"
import WestGraphs from "./components/Public/WestGraphs";
import IMGraphs from "./components/Public/IMGraphs";
import PublicPages from "./components/Public/Pages";
import TermsOfUse from "./components/Public/TermsOfUse";
import CertInfo from "./components/Public/CertInfo"
import PageGroupAsPage from "./components/Public/PageGroupAsPage";
import RouteRender from "./util/RouteRender";
import DashboardSettings from "./components/Public/DashboardSettings";
import Sitemap from "./components/Public/Sitemap";
import ReliabilityGraphs from "./components/Public/ReliabilityGraphs";
import EditFeature from "./components/Admin/EditFeature";


const routes = () => {
    return (
        <BrowserRouter>
            <Switch>
                <Route path={info.announcements.path}
                       exact={true}
                       render={(props: any) => (
                           <RouteRender {...props}
                                        component={withAdmin(Announcements, info.announcements.title, info.announcements.description)}
                                        title={"Portal Administration"}/>)}/>
                <Route path={info.managePages.path}
                       exact={true}
                       render={(props: any) => (
                           <RouteRender {...props}
                                        component={withAdmin(Pages, info.managePages.title, info.managePages.description)}
                                        title={"Portal Administration"}/>)}/>
                <Route exact={true}
                       path={info.createPage.path}
                       render={(props: any) => (
                           <RouteRender {...props}
                                        component={withAdmin(CreatePage)}
                                        title={"Portal Administration"}/>)}/>
                <Route exact={true}
                       path={info.editPage.path}
                       render={(props: any) => (
                           <RouteRender {...props}
                                        component={withAdmin(EditPage)}
                                        title={"Portal Administration"}/>)}/>
                <Route path={info.pageGroups.path}
                       exact={true}
                       render={(props: any) => (
                           <RouteRender {...props}
                                        component={withAdmin(PageGroups, info.pageGroups.title, info.pageGroups.description)}
                                        title={"Portal Administration"}/>)}/>
                <Route exact={true}
                       path={info.editPageGroup.path}
                       render={(props: any) => (
                           <RouteRender {...props}
                                        component={withAdmin(EditPageGroup)}
                                        title={"Portal Administration"}/>)}/>
                <Route exact={true}
                       path={info.features.path}
                       render={(props: any) => (
                           <RouteRender {...props}
                                        component={withAdmin(FeaturesPage, info.features.title, info.features.description)}
                                        title={"Portal Administration"}/>)}/>
                <Route exact={true}
                       path={info.editFeature.path}
                       render={(props: any) => (
                           <RouteRender {...props}
                                        component={withAdmin(EditFeature)}
                                        title={"Portal Administration"}/>)}/>
                <Route exact={true}
                       path={info.home.path}
                       render={(props: any) => (
                           <RouteRender {...props}
                                        component={withPublic(Home)}
                                        title={"SPP Portal"}/>)}/>
                <Route exact={true}
                       path={info.westGraphs.path}
                       render={(props: any) => (
                           <RouteRender {...props}
                                        component={withPublic(WestGraphs)}
                                        title={"SPP Portal"}/>)}/>
                <Route exact={true}
                       path={info.imGraphs.path}
                       render={(props: any) => (
                           <RouteRender {...props}
                                        component={withPublic(IMGraphs)}
                                        title={"SPP Portal"}/>)}/>
                <Route exact={true}
                       path={info.certInfo.path}
                       render={(props: any) => (
                           <RouteRender {...props}
                                        component={withPublic(CertInfo)}
                                        title={"SPP Portal"}/>)}/>

                <Route exact={true}
                       path={info.reliabilityDashboard.path}
                       render={(props: any) => (
                           <RouteRender {...props}
                                        component={withPublic(ReliabilityGraphs)}
                                        title={"SPP Portal"}/>)}/>
                <Route exact={true}
                       path={info.pages.path}
                       render={(props: any) => (
                           <RouteRender {...props}
                                        component={withPublic(PublicPages)}
                                        title={"SPP Portal"}/>)}/>
                <Route exact={true}
                       path={info.pageGroupAsPage.path}
                       render={(props: any) => (
                           <RouteRender {...props}
                                        component={withPublic(PageGroupAsPage)}
                                        title={"SPP Portal"}/>)}/>
                <Route exact={true}
                       path={info.termsOfUse.path}
                       render={(props: any) => (
                           <RouteRender {...props}
                                        component={withPublic(TermsOfUse)}
                                        title={"Terms of Use"}/>)}/>
                <Route exact={true}
                       path={info.sitemap.path}
                       render={(props: any) => (
                           <RouteRender {...props}
                                        component={withPublic(Sitemap)}
                                        title={"Sitemap"}/>)}/>
                <Route exact={true}
                       path={info.dashboardSettings.path}
                       render={(props: any) => (
                           <RouteRender {...props}
                                        component={withPublic(DashboardSettings)}
                                        title={"SPP Portal"}/>)}/>

                <Route path="*" component={withPublic(ErrorNotFound)} />
            </Switch>
        </BrowserRouter>
    )
};

export default routes
