import AxiosService from "./axios-service";
import {ActionResponse, Page, PageRequest} from "../models/models";
import {emptyPage, genericError} from "../util/constants";
//import {genericError} from "../util/constants";

export default class PageService {
    private client: AxiosService;

    constructor(csrfToken: string) {
        this.client = new AxiosService(csrfToken);
    }

    getPagesByPageGroup = (pageGroupId: number): Promise<Page[]> => {
        const url = `/api/page/byPageGroup/${pageGroupId}`;
        return new Promise<Page[]>(((resolve) => {
            this.client.getAxiosInstance().get(url)
                .then(({data}) => {resolve(data)})
                .catch(() => {resolve([])})
        }));
    };

    getPages = (): Promise<Page[]> => {
        const url = `/api/page/`;
        return new Promise<Page[]>(((resolve) => {
            this.client.getAxiosInstance().get(url)
                .then(({data}) => {resolve(data)})
                .catch(() => {resolve([])})
        }));
    };

    createPage = (pageRequest: PageRequest) => {
        const url = `/api/page/full?enforceDepth=true`;
        return new Promise<ActionResponse<Page>>(((resolve) => {
            this.client.getAxiosInstance().post(url, pageRequest)
                .then(({data}: {data: any}): any => {
                    resolve(data)})
                .catch(() => {resolve(genericError)})
        }));

    };

    deletePage = (page: Page) => {
        const url = `/api/page/full`;
        return new Promise<ActionResponse<Page>>(((resolve) => {
            this.client.getAxiosInstance().delete(url, {data:page})
                .then(({data}: {data: any}): any => {
                    resolve(data)})
                .catch(() => {resolve(genericError)})
        }));

    };

    updateFullPage = (pageRequest: PageRequest) => {
        const url = `/api/page/full?enforceDepth=true`;
        return new Promise<ActionResponse<Page>>(((resolve) => {
            this.client.getAxiosInstance().put(url, pageRequest)
                .then(({data}: {data: any}): any => {
                    resolve(data)})
                .catch(() => {resolve(genericError)})
        }));
    };

    updatePage = (page: Page) => {
        const url = `/api/page/?enforceDepth=true`;
        return new Promise<ActionResponse<Page>>(((resolve) => {
            this.client.getAxiosInstance().put(url, page)
                .then(({data}: {data: any}): any => {
                    resolve(data)})
                .catch(() => {resolve(genericError)})
        }));
    };

    getPage = (id: number) => {
        const url = `/api/page/${id}`;
        return new Promise<Page>(((resolve) => {
            this.client.getAxiosInstance().get(url)
                .then(({data}) => {resolve(data)})
                .catch(() => {resolve(emptyPage)})
        }));

    }


}