import AxiosService from "./axios-service";
import {ActionResponse, PageGroup} from "../models/models";
import {emptyPageGroup, genericError} from "../util/constants";
import axios from "axios";

export default class PageGroupService {
    private client: AxiosService;

    constructor(csrfToken: string) {
        this.client = new AxiosService(csrfToken);
    }

    getPageGroupById = (id: number): Promise<PageGroup> => {
        const url = `/api/pageGroup/${id}`;
        return new Promise<PageGroup>(((resolve) => {
            this.client.getAxiosInstance().get(url)
                .then(({data}) => {resolve(data)})
                .catch(() => {resolve(emptyPageGroup)})
        }));
    };

    getPageGroupsByParentGroup = (parentId: number): Promise<PageGroup[]> => {
        const url = `/api/pageGroup/byParentGroup/${parentId}`;
        return new Promise<PageGroup[]>(((resolve) => {
            this.client.getAxiosInstance().get(url)
                .then(({data}) => {resolve(data)})
                .catch(() => {resolve([])})
        }));
    };

    createPageGroup = (pageGroup: PageGroup): Promise<ActionResponse<PageGroup>> => {
        const url = `/api/pageGroup/?enforceDepth=true`;
        return new Promise<ActionResponse<PageGroup>>(((resolve) => {
            this.client.getAxiosInstance().post(url, pageGroup)
                .then(({data}: {data: any}): any => {
                    resolve(data)})
                .catch(() => {resolve(genericError)})
        }));
    };

    updatePageGroup = (pageGroup: PageGroup): Promise<ActionResponse<PageGroup>> => {
        const url = `/api/pageGroup/?enforceDepth=true`;
        return new Promise<ActionResponse<PageGroup>>(((resolve) => {
            this.client.getAxiosInstance().put(url, pageGroup)
                .then(({data}: {data: any}): any => {
                    resolve(data)})
                .catch(() => {resolve(genericError)})
        }));
    };

    deletePageGroup = (pcId: number): Promise<ActionResponse<PageGroup>> => {
        const url = `/api/pageGroup/${pcId}`;
        return new Promise<ActionResponse<PageGroup>>(((resolve) => {
            this.client.getAxiosInstance().delete(url)
                .then(({data}) => {resolve(data)})
                .catch(() => {resolve(genericError)})
        }));
    };

    getPageGroupBySlug = (slug: string): Promise<PageGroup> => {
        const url = `/api/pageGroup/BySlug/${slug}`;
        return new Promise<PageGroup>(((resolve) => {
            this.client.getAxiosInstance().get(url)
                .then(({data}) => {resolve(data)})
                .catch(() => {resolve(emptyPageGroup)})
        }));
    };
}

export const getPageGroups = (xsrfToken: string): Promise<PageGroup[]> => {
    const url = `/api/pageGroup/`;
    let axiosRequestConfig = {headers: {
            'X-SPP-UI-Token': xsrfToken,
            'Accept': 'application/json',
            'Content-Type': 'application/json'}};
    return new Promise<PageGroup[]>(((resolve) => {
        axios.get(url, axiosRequestConfig)
            .then(({data}) => {resolve(data)})
            .catch(() => {resolve([])})
    }));
};