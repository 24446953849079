import * as React from 'react';
import "./index.css"
import {Icon, Modal} from "semantic-ui-react";
import {ModalContent} from "../../../models/models";

interface PageProps {
    open: boolean
    modalContent: ModalContent
}

interface PageState {}

class SppModal extends React.Component<PageProps, PageState> {
    constructor(props: PageProps, state: PageState){
        super(props, state);

        this.state = {}
    }


    render(){
        return(
            <Modal open={this.props.open}
                   className={"spp-modal"}
                   id={'common-modal'}
                   onClose={this.props.modalContent.onClose}
                   closeOnDimmerClick={false}
                   closeIcon={true}>
                <Modal.Header id={'common-modal-header'}>
                    <Icon name={this.props.modalContent.iconName}/>
                    {this.props.modalContent.modalTitle}
                </Modal.Header>
                <Modal.Content >
                    <Modal.Description>
                        {this.props.modalContent.modalBody}
                    </Modal.Description>
                </Modal.Content>
            </Modal>
        )
    }
}

export default SppModal;