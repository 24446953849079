import {Certificate} from "../models/models";
import axios from "axios";

export const getCertificateInfo = (xsrfToken: string) => {
    const url = `/api/cert-info`;
    let axiosRequestConfig = {headers: {
            'X-SPP-UI-Token': xsrfToken,
            'Accept': 'application/json',
            'Content-Type': 'application/json',}};
    return new Promise<Certificate>(((resolve) => {
        axios.get(url, axiosRequestConfig)
            .then(({data}) => {
                resolve(data)
            })
            .catch(() => {
                resolve({
                    certSerial: '',
                    certIssuer: '',
                    certSubject: '',
                    certExpiration: '',
                    certExpiresInDays: 0,
                    formattedExpirationText: ''
                });
            })
    }));
};