import * as React from 'react';
import './index.css'
import MenuRail from "../../Common/MenuRail";
import {Button, ButtonProps, Dimmer, Divider, DropdownProps, Form, FormCheckboxProps, Grid, Icon, Loader, Menu, MenuItem,
    Segment, SegmentGroup, Table} from "semantic-ui-react";
import {chart, MenuOption, PortalProperties, Preference, Principal} from "../../../models/models";
import {charts, getChartByValue} from "../../../services/static-service";
import {cloneDeep} from "lodash";
import toast from "../../../util/toast";
import toastMessage from "../../../util/toast";
import {getBackgroundImage} from "../../../util/functions";
import {
    getPreferenceOptions,
    getPreferences,
    updatePreference,
    updatePreferences
} from "../../../services/preference-service";
import {ToastComponent} from "../../Common/ToastComponent";
import {info} from "../../../services/route-service";
import DarkModeToggle from "react-dark-mode-toggle";
import {NavLink} from "react-router-dom";
import ReactGA from "react-ga4";

interface PageProps {
    selectedTopMenuOption: string
    setSelectedTopMenuOption: (selectedTopMenuOption: string) => void;
    railDisplay: string
    setRailDisplay: (railDisplay: string) => void
    portalProperties: PortalProperties;
    menuOptions: MenuOption[]
    userPrincipal: Principal
    history: any
    userCookie: any
    darkTheme: boolean
    getUserPrincipal: (xsrfToken: string) => void;
}

interface PageState {
    category: string
    chartName: string
    chartPreferences: Preference[]
    tableLoading: boolean
    deletedPreferences: Preference[]
    darkTheme: boolean
    themePreference: Preference,
    preferenceOptions: chart[],
    optionsLoading: boolean
}

export default class DashboardSettings extends React.Component<PageProps, PageState> {
    constructor(props: PageProps, state: PageState) {
        super(props, state);

        this.state = {
            category: '',
            chartName: '',
            chartPreferences: [],
            tableLoading: false,
            deletedPreferences: [],
            darkTheme: false,
            preferenceOptions: charts(),
            optionsLoading: true,
            themePreference: {id: 0, userId: '', key: 'theme', value: 'light', position: 0}
        }
    }

    componentDidMount() {
        if (this.props.userPrincipal.unauthenticatedUser)
            this.props.history.push(info.home.path)
        else
        {
            this.getPreferenceOptions()
            this.getChartPreferences()
            this.setState({darkTheme: cloneDeep(this.props.darkTheme)})
            ReactGA.event({category: "user_page_view", action: "View Dashboard Settings"});
        }
    }

    componentDidUpdate(prevProps: Readonly<PageProps>, prevState: Readonly<PageState>, snapshot?: any) {
        if (this.props.userPrincipal !== prevProps.userPrincipal && this.props.userPrincipal.unauthenticatedUser)
            this.props.history.push(info.home.path)

        if (this.props.darkTheme !== prevProps.darkTheme)
            this.setState({darkTheme: cloneDeep(this.props.darkTheme)})
    }

    getChartPreferences = () => {
        this.setState({tableLoading: true})
        getPreferences(this.props.userCookie).then(response => {
            let theme = response.find(pref => pref.key === 'theme')
            let charts = response.filter(pref => pref.key !== 'theme')

            if (theme)
                this.setState({themePreference: theme, tableLoading: false,
                    chartPreferences: charts.sort((a, b) => a.position - b.position)})
            else
                this.setState({tableLoading: false,
                    chartPreferences: charts.sort((a, b) => a.position - b.position)})
        })
    }

    getPreferenceOptions = () => {
        getPreferenceOptions(this.props.userCookie).then(response => {
            this.setState({preferenceOptions: charts().concat(response), optionsLoading: false})
        })
    }

    getChartPreferencesFromPreferences = () => {
        return this.state.chartPreferences.map((preference: Preference, index: number) => {
            let chart = getChartByValue(this.state.preferenceOptions, preference.value)
            if (chart) {
                return {
                    chartName: chart.name,
                    category: chart.category,
                    position: preference.position,
                    remove: <Button icon={'remove'} negative={true} name={preference} size={'mini'}
                                    onClick={this.removeChartOnClick}/>,
                    object: preference
                }
            } else return null
        })
    }

    getTableBody = () => {
        let rowArray: any = [];
        let chartPreferences = this.getChartPreferencesFromPreferences();
        for (let i = 0; i < 5; i++) {
            if (chartPreferences.length === 0 || chartPreferences.length <= i)
                rowArray.push(
                    <Table.Row key={i}>
                        <Table.Cell style={{height: '40px'}} />
                        <Table.Cell />
                        <Table.Cell />
                        <Table.Cell/>
                        <Table.Cell />
                    </Table.Row>
                )
            else
                rowArray.push(
                    <Table.Row key={i}>
                        <Table.Cell collapsing>
                                     <Button
                                         disabled={chartPreferences[i]?.position === 1}
                                         color={"grey"}
                                         style={{padding: '.5rem'}}
                                         circular={false}
                                         onClick={() => this.positionChangeOnClick(i, true)}
                                         icon={'caret up'} />
                                     <Button
                                         disabled={chartPreferences[i]?.position === chartPreferences.length}
                                         color={"grey"}
                                         style={{padding: '.5rem'}}
                                         onClick={() => this.positionChangeOnClick(i, false)}
                                         circular={false}
                                         icon={'caret down'} />
                        </Table.Cell>
                        <Table.Cell>{chartPreferences[i]?.chartName}</Table.Cell>
                        <Table.Cell>{chartPreferences[i]?.category}</Table.Cell>
                        <Table.Cell>{chartPreferences[i]?.position}</Table.Cell>
                        <Table.Cell>{chartPreferences[i]?.remove}</Table.Cell>
                    </Table.Row>
                )
        }

        return(
            <Table.Body>
                {rowArray}
            </Table.Body>
        )
    }

    positionChangeOnClick = (index: number, up: boolean) => {
        let chartPref = cloneDeep(this.state.chartPreferences);
        let deleteArray = cloneDeep(this.state.deletedPreferences);

        if (up) {
            let preferenceToDelete1 = cloneDeep(chartPref[index])
            if (preferenceToDelete1.id !== 0)
                deleteArray.push(preferenceToDelete1)

            let preferenceToDelete2 = cloneDeep(chartPref[index-1])
            if (preferenceToDelete2.id !== 0)
                deleteArray.push(preferenceToDelete2)

            chartPref[index].position = index
            chartPref[index-1].position = index+1
            chartPref[index].id = 0
            chartPref[index-1].id = 0
        }
        else {
            let preferenceToDelete1 = cloneDeep(chartPref[index])
            if (preferenceToDelete1.id !== 0)
                deleteArray.push(preferenceToDelete1)

            let preferenceToDelete2 = cloneDeep(chartPref[index+1])
            if (preferenceToDelete2.id !== 0)
                deleteArray.push(preferenceToDelete2)

            chartPref[index].position = index+2
            chartPref[index+1].position = index+1
            chartPref[index].id = 0
            chartPref[index+1].id = 0
        }

        this.setState({
            deletedPreferences: deleteArray,
            chartPreferences: chartPref.sort((a, b) => a.position - b.position)})
    }

    getCategoryOptions = () => {
        let onlyUnique = (value: any, index: any, array: string | any[]) => {return array.indexOf(value) === index};
        let categoryStrings = this.state.preferenceOptions.map(a => a.category).filter(onlyUnique)

        return categoryStrings.map(chart => {
            return {key: chart, text: chart, value: chart}
        })
    }

    removeChartOnClick = (event: any, {name}: ButtonProps) => {
        let chartPref = cloneDeep(this.state.chartPreferences);
        chartPref = chartPref.filter(pref => {return pref.value !== name.value })
        chartPref.forEach((pref, index) => {
            pref.position = index+1
        })
        this.setState({chartPreferences: chartPref})

        if (name.id !== 0) {
            let deleteArray = cloneDeep(this.state.deletedPreferences);
            deleteArray.push(name)
            this.setState({deletedPreferences: deleteArray})
        }
    }

    getChartOptions = () => {
        let filteredCharts = this.state.preferenceOptions.filter(c => {return c.category === this.state.category})
        return filteredCharts.map(chart => {
            return {key: chart.value, text: chart.name, value: chart.value}
        })
    }

    handleSelectChange = (event: any,  {name, value, checked}: DropdownProps | FormCheckboxProps) => {
        switch (name) {
            case 'category':
                this.setState({category: value ? value.toString() : '', chartName: ''})
                break;
            case 'chartName':
                this.setState({chartName: value ? value.toString() : ''})
                break;
            case 'dark-theme':
                this.setState({darkTheme: checked})
        }
    }

    addChartOnClick = (event: any) => {
        if (this.state.chartName === '' ) {
            toast.info("Please Select a Chart Name from the Dropdown")
            return
        }

        if (this.state.chartPreferences.find(preference => preference.value === this.state.chartName)) {
            toast.info("That Chart has already been added")
            return
        }

        let updatedChartPref = cloneDeep(this.state.chartPreferences);

        let newPreference: Preference = {
            id: 0,
            userId: this.props.userPrincipal.username,
            key: 'chart-name',
            value: this.state.chartName,
            position: this.state.chartPreferences.length + 1
        }

        updatedChartPref.push(newPreference);
       this.setState({chartPreferences: updatedChartPref, chartName: '', category: ''})
    }

    handleOnSave = () => {
        this.setState({tableLoading: true})
        let preferenceRecord: Record<string, Preference[]> = {}
        preferenceRecord['update'] = this.state.chartPreferences
        preferenceRecord['delete'] = this.state.deletedPreferences

        updatePreferences(this.props.userCookie, preferenceRecord).then(response => {
           if (response.ok)
           {
               toastMessage.success(response.message)
               this.getChartPreferences()
           }
           else
           {
               toastMessage.error(response.message)
               this.setState({tableLoading: false})
           }
        })

        let themePreference = cloneDeep(this.state.themePreference);
        themePreference.userId = this.props.userPrincipal.username;

        if (this.state.darkTheme)
            themePreference.value = 'dark';
        else themePreference.value = 'light'

        updatePreference(this.props.userCookie, themePreference).then(response => {
            if (response.ok)
            {
                toastMessage.success(response.message)
                if (response.item !== undefined)
                {
                    this.setState({themePreference: response.item, darkTheme: response.item.value === 'dark'})
                    this.props.getUserPrincipal(this.props.userCookie);
                }
            }
            else
                toastMessage.error(response.message)
        })
    }

    setDarkMode = (value: boolean) => {
        this.setState({darkTheme: value})
    }

    render(){
        return (
            <div
                className={'background-image-tou'}
                style={{backgroundImage: getBackgroundImage(this.state.darkTheme)}}>
                <ToastComponent/>
                <MenuRail
                    selectedTopMenuItem={this.props.selectedTopMenuOption}
                    railDisplay={this.props.railDisplay}
                    userPrincipal={this.props.userPrincipal}
                    portalProperties={this.props.portalProperties}
                    setRailDisplay={this.props.setRailDisplay}
                    menuOptions={this.props.menuOptions}
                    history={this.props.history}
                    setSelectedTopMenuOption={this.props.setSelectedTopMenuOption}/>
                <Grid >
                    <Grid.Row>
                        <Grid.Column >
                            <SegmentGroup id={'db-segment-group'} >
                                <Segment id={'cp-segment-header'} >
                                    <Icon name={'edit'}/>
                                    Personalized Homepage Dashboard for {this.props.userPrincipal.displayName}
                                </Segment>
                                <Segment className={this.state.darkTheme? 'dark' : ''} id={'db-settings-body'}>
                                    <div id={"db-body-wrapper"} >
                                        <Form>
                                            <Form.Group >
                                                <Form.Select
                                                    width={6}
                                                    label='Category'
                                                    options={this.getCategoryOptions()}
                                                    value={this.state.category}
                                                    name={'category'}
                                                    loading={this.state.optionsLoading}
                                                    onChange={this.handleSelectChange}
                                                    placeholder='Chart Category'/>
                                                <Form.Select
                                                    width={10}
                                                    label='Chart Name'
                                                    name={'chartName'}
                                                    onChange={this.handleSelectChange}
                                                    value={this.state.chartName}
                                                    options={this.getChartOptions()}
                                                    placeholder='Select Category for Options'/>
                                            </Form.Group>
                                            <Button
                                                color={'blue'}
                                                disabled={this.state.chartPreferences.length > 4 || this.state.tableLoading}
                                                onClick={this.addChartOnClick}
                                                className={'db-settings-button'}>
                                                <Icon name={'add'} />
                                                ADD CHART
                                            </Button>
                                        </Form>
                                        <Divider  />
                                        <Menu secondary={true} id={'db-settings-theme-toggle-menu'} >
                                            <Menu.Menu position={'right'}>
                                                <MenuItem id={'db-settings-theme-toggle'} >
                                                    Theme
                                                </MenuItem>
                                                <MenuItem id={'toggle-switch-item'}>
                                                    <DarkModeToggle
                                                        className={'dark-theme-toggle'}
                                                        onChange={this.setDarkMode}
                                                        checked={this.state.darkTheme}
                                                        size={50}/>
                                                </MenuItem>
                                            </Menu.Menu>
                                        </Menu>
                                        <div style={{position: 'relative'}}>
                                            <Dimmer active={this.state.optionsLoading || this.state.tableLoading} inverted={!this.state.darkTheme}>
                                                <Loader/>
                                            </Dimmer>
                                            {this.state.chartPreferences.length > 0 ? null :
                                                <div className={'rt-noData db-settings-table'}>No Rows Found</div>}
                                            <Table compact={true} celled={true} definition={true} id={'chart-preference-table'}>
                                                <Table.Header  fullWidth={true}>
                                                    <Table.Row>
                                                        <Table.HeaderCell />
                                                        <Table.HeaderCell width={6}>Chart Name</Table.HeaderCell>
                                                        <Table.HeaderCell width={6}>Category</Table.HeaderCell>
                                                        <Table.HeaderCell width={2}>Position</Table.HeaderCell>
                                                        <Table.HeaderCell width={2}>Remove</Table.HeaderCell>
                                                    </Table.Row>
                                                </Table.Header>
                                                {this.getTableBody()}
                                            </Table>
                                        </div>
                                        <div id={'db-setting-button-wrapper'} >
                                            <NavLink to={info.home.path}>
                                                <Button id={'db-setting-return-btn'}>
                                                    RETURN TO DASHBOARD
                                                </Button>
                                            </NavLink>
                                            <Button
                                                positive={true}
                                                onClick={this.handleOnSave}
                                                loading={this.state.tableLoading}
                                                disabled={this.state.tableLoading}
                                                id={'db-setting-save-btn'}
                                                className={'db-settings-button'}>
                                                SAVE SETTINGS
                                            </Button>
                                        </div>
                                    </div>
                                </Segment>
                            </SegmentGroup>
                        </Grid.Column>
                    </Grid.Row>
                </Grid>
            </div>
        )
    }
}
